import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Button, Spinner } from 'reactstrap';
import { QRCodeCanvas } from 'qrcode.react';

const Verifications = () => {
  const [qrCode, setQrCode] = useState(null);  // Store the generated QR code
  const [userId, setUserId] = useState('');    // Store User ID
  const [userEmail, setUserEmail] = useState(''); // Store User email
  const [status, setStatus] = useState('');    // Status message
  const [loading, setLoading] = useState(false);  // Loading state for the process
  const [userName, setUserName]= useState('')

  const getUserInfo = async () => {
    setLoading(true);
    try {
      const userData = JSON.parse(localStorage.getItem('userData'))?.user;
      if (!userData?.user_id) {
        setStatus('User ID is missing in localStorage.');
        setLoading(false);
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/get_personal_info?user_id=${userData.user_id}`
      );

      if (response?.data?.success) {
        const personalInfo = response.data.data;
        setUserId(personalInfo.user_id);
        setUserEmail(personalInfo.user_email);
        const decryptedUserName = `${personalInfo.user_fname || ""} ${personalInfo.user_lname || ""}`.trim();
        setUserName(decryptedUserName)
        const token = personalInfo.tokenCode;
        const qrData = `token:${token}, userId:${personalInfo.user_id}, user_key:${personalInfo.user_key}`;

        setQrCode(qrData); 
        await sendQRCodeEmail(qrData, personalInfo.user_email);

        setStatus('User information fetched and QR Code generated successfully.');
      } else {
        setStatus('Failed to fetch user information.');
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
      setStatus('Error fetching user information. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const sendQRCodeEmail = async (qrCodeData, userData) => {
    try {
      const template = `
        <h2>Hello! ${userData?.user_fname || ""} ${userData?.user_lname || ""},</h2>
        <p>Below is your QR Code for Secure Our Families login authentication:</p>
        <br/>
        <img src="cid:qrCode" alt="QR Code" style="width:200px; height:200px;" />
        <br/>
        <p>Please use this QR Code to login. The QR Code will expire in 5 minutes.</p>
        <br>
        <h2>Regards,</h2>
        <p>Secure Our Families</p>
      `;
  
      const attachments = [
        {
          filename: `${userData.user_fname + userData.user_lname + "_QrCode.png"}`,
          content: qrCodeData.split('base64,')[1], 
          encoding: 'base64',
          cid: 'qrCode',
        },
      ];
      await sendQRMail(userData?.user_email, 'QR Code for Login', template, attachments);
  
      console.log('QR code email sent successfully!');
    } catch (error) {
      console.error('Error sending QR code email:', error);
    }
  };

  // const downloadQRCode = () => {
  //   const canvas = document.getElementById('qr-code-canvas');
  //   const imageUrl = canvas.toDataURL('image/png');
  //   const link = document.createElement('a');
  //   link.href = imageUrl;
  //   link.download = `${userName + "_QrCode.png"}`
  //   link.click();
  // };

  const downloadQRCode = async () => {
    try {
      // Get the QR code canvas
      const canvas = document.getElementById('qr-code-canvas');
      if (!canvas) {
        throw new Error("QR Code canvas not found");
      }
  
      // Convert canvas to a Blob (image file)
      canvas.toBlob(async (blob) => {
        if (!blob) {
          throw new Error("Failed to create blob from canvas");
        }
        console.log(userName)
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('qr_codefile', blob, `${userName + "_QrCode.png"}`); 
  
        const response = await fetch('http://localhost:4000/api/v1/customer/services/add_qrcode', {
          method: 'POST',
          body: formData,
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`API Error: ${errorData.message}`);
        }
  
        const responseData = await response.json();
        console.log('QR Code uploaded successfully:', responseData);
  
        const imageUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = `${userName + "_QrCode.png"}`;
        link.click();
      }, 'image/png');
    } catch (error) {
      console.error("Error in downloadQRCode:", error.message);
    }
  };
  

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">VERIFICATIONS</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Verifications</a>
              </li>
            </ol>
          </div>

          <div className="col-lg-10">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Profile Verifications</h4>

                {/* Verification Table */}
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr className="table-light">
                        <td>Email</td>
                        <td></td>
                        <td className="hidden-480">
                          <span className="label label-warning">Verified</span>
                        </td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Mobile</td>
                        <td></td>
                        <td className="hidden-480">
                          <span className="label label-warning">Not Verified</span>
                        </td>
                        <td>
                          <a href="sms-verify.php" className="btn btn-sm btn-primary">
                            Verify
                          </a>
                        </td>
                      </tr>
                      <tr className="table-light">
                        <td>Aadhaar</td>
                        <td></td>
                        <td className="hidden-480">
                          <span className="label label-warning">Not Verified</span>
                        </td>
                        <td>
                          <a href="aadhar-verify.php" className="btn btn-sm btn-primary">
                            Verify
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>SSN</td>
                        <td></td>
                        <td className="hidden-480">
                          <span className="label label-warning">Not Verified</span>
                        </td>
                        <td>
                          <a href="ssn-verify.php" className="btn btn-sm btn-primary">
                            Verify
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* QR Code Generation */}
                {/* <div className="text-center mt-3">
                  <Button color="primary" onClick={getUserInfo} disabled={loading}>
                    {loading ? <Spinner size="sm" color="light" /> : 'Generate QR Code'}
                  </Button>
                </div> */}

                {/* Display QR Code */}
                {/* {qrCode && (
                  <div className="mt-4 text-center">
                    <QRCodeCanvas id="qr-code-canvas" value={qrCode} size={256} />
                  </div>
                )} */}

                {/* Download QR Code */}
                {/* {qrCode && (
                  <div className="mt-3 text-center">
                    <Button color="success" onClick={downloadQRCode}>
                      Download QR Code
                    </Button>
                  </div>
                )} */}

                {/* Display Status Messages */}
                {status && (
                  <div className="text-center mt-3">
                    <p className="text-danger">{status}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Verifications;
