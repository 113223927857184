import { userId } from "helpers/userId"
import React, { useEffect, useState } from "react"
import ReactFamilyTree from "react-family-tree"
import { Container } from "reactstrap"
import FamilyNode from "./FamilyNode"

const initialFormDetails = {
  user_name: "",
  user_fname: "",
  user_mname: "",
  user_lname: "",
  gender: "",
  profile_pic: "",
  relation: "",
}

const FamilyTree = () => {
  const [familyInformation, setFamilyInformation] = useState({ data: [] })
  const [nodes, setNodes] = useState([])
  const [form, setForm] = useState(initialFormDetails)
  const [rootId, setRootId] = useState(null)
  const [error, setError] = useState(null)
  const WIDTH = 260
  const HEIGHT = 280

  useEffect(() => {
    const fetchSelfData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/get_personal_info?user_id=${userId}`,
        )
        if (!response.ok) {
          throw new Error(
            `Failed to fetch personal info. Status: ${response.status}`,
          )
        }
        const data = await response.json()
        if (data?.success) {
          const personalInfo = data.data
          const decryptedUserName =
            `${personalInfo.user_fname || ""} ${personalInfo.user_lname || ""}`.trim()

          setForm(prevForm => ({
            ...prevForm,
            user_id: personalInfo.user_id,
            user_key: personalInfo.user_key,
            user_name: decryptedUserName,
            user_fname: personalInfo.user_fname,
            user_mname: personalInfo.user_mname,
            user_lname: personalInfo.user_lname,
            gender: personalInfo.gender,
            profile_pic: personalInfo.profile_pic,
            relation: "Self",
            associated: "Yes",
          }))

          setRootId(personalInfo.user_id.toString())
        }
      } catch (error) {
        setError("Error fetching personal information.")
        console.error("Error fetching personal information:", error)
      }
    }

    const fetchFamilyData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/family_information?user_id=${userId}`,
        )
        if (!response.ok) {
          throw new Error(
            `Failed to fetch family information. Status: ${response.status}`,
          )
        }
        const data = await response.json()
        setFamilyInformation({ ...familyInformation, data: data?.data || [] })
      } catch (error) {
        setError("Error fetching family information.")
        console.error("Error fetching family information:", error)
      }
    }

    fetchSelfData()
    fetchFamilyData()
  }, [])

  // const mapFamilyTree = () => {
  //   const nodeMap = {}
  //   if (form.user_id === userId) {
  //     const selfNode = {
  //       id: form.user_id.toString(),
  //       gender: form.gender,
  //       profilePic: form.profile_pic || "",
  //       displayName: form.user_name,
  //       relation: form.relation,
  //       associated: form.associated,
  //       position: "left",
  //       siblings: [],
  //       spouses: [],
  //       children: [],
  //     }

  //     nodeMap[selfNode.id] = selfNode
  //   }

  //   if (familyInformation?.data) {
  //     familyInformation.data.forEach(person => {
  //       if (["Spouse", "Son", "Daughter"].includes(person.ac_reliation)) {
  //         const gender =
  //           person.ac_reliation === "Son" || person.ac_reliation === "Spouse"
  //             ? "male"
  //             : "female"

  //         const familyNode = {
  //           id: person.ac_id.toString(),
  //           gender: gender,
  //           profilePic: person.ac_image || "",
  //           displayName: `${person.ac_fname} ${person.ac_lname}`,
  //           relation: person.ac_reliation,
  //           associated: person.Associated,
  //           position: person.ac_reliation === "Spouse" ? "right" : "below",
  //           parents: [],
  //           siblings: [],
  //           spouses: [],
  //           children: [],
  //         }

  //         nodeMap[familyNode.id] = familyNode
  //       }
  //     })

  //     const selfNode = nodeMap[form.user_id]
  //     if (selfNode) {
  //       const spouseData = familyInformation.data.find(
  //         p => p.ac_reliation === "Spouse",
  //       )
  //       if (spouseData) {
  //         const spouseNode = nodeMap[spouseData.ac_id.toString()]
  //         if (spouseNode) {
  //           selfNode.spouses.push(spouseNode)
  //           spouseNode.spouses.push(selfNode)
  //         }
  //       }

  //       const childrenData = familyInformation.data.filter(p =>
  //         ["Son", "Daughter"].includes(p.ac_reliation),
  //       )
  //       childrenData.forEach(child => {
  //         const childNode = nodeMap[child.ac_id.toString()]
  //         if (childNode) {
  //           selfNode.children.push(childNode)
  //           childNode.parents.push(selfNode)

  //           if (spouseData) {
  //             const spouseNode = nodeMap[spouseData.ac_id.toString()]
  //             if (spouseNode) {
  //               spouseNode.children.push(childNode)
  //               childNode.parents.push(spouseNode)
  //             }
  //           }
  //         }
  //       })

  //       selfNode.parents = []
  //     }
  //   }

  //   const filteredNodes = Object.values(nodeMap).filter(
  //     node =>
  //       node.relation === "Self" ||
  //       node.relation === "Spouse" ||
  //       ["Son", "Daughter"].includes(node.relation),
  //   )

  //   setNodes(filteredNodes)
  // }

  const mapFamilyTree = () => {
    const nodeMap = {};
  
    // Create a self node if the current user matches
    if (form.user_id === userId) {
      const selfNode = {
        id: form.user_id.toString(),
        gender: form.gender,
        profilePic: form.profile_pic || "",
        displayName: form.user_name,
        relation: form.relation,
        associated: form.associated,
        position: "left",
        siblings: [],
        spouses: [],
        children: [],
        parents: [], // No self-node as a parent
      };
  
      nodeMap[selfNode.id] = selfNode;
    }
  
    // Process family data
    if (familyInformation?.data) {
      familyInformation.data.forEach(person => {
        if (["Spouse", "Son", "Daughter"].includes(person.ac_reliation)) {
          const gender = person.ac_reliation === "Son" || person.ac_reliation === "Spouse" ? "male" : "female";
  
          const familyNode = {
            id: person.ac_id.toString(),
            gender: gender,
            profilePic: person.ac_image || "",
            displayName: `${person.ac_fname} ${person.ac_lname}`,
            relation: person.ac_reliation,
            associated: person.Associated,
            position: person.ac_reliation === "Spouse" ? "right" : "below",
            parents: [],
            siblings: [],
            spouses: [],
            children: [],
          };
  
          nodeMap[familyNode.id] = familyNode;
        }
      });
  
      const selfNode = nodeMap[form.user_id];
      if (selfNode) {
        // Link spouse
        const spouseData = familyInformation.data.find(p => p.ac_reliation === "Spouse");
        if (spouseData) {
          const spouseNode = nodeMap[spouseData.ac_id.toString()];
          if (spouseNode) {
            selfNode.spouses.push(spouseNode);
            spouseNode.spouses.push(selfNode);
          }
        }
  
        // Link children
        const childrenData = familyInformation.data.filter(p =>
          ["Son", "Daughter"].includes(p.ac_reliation),
        );
        childrenData.forEach(child => {
          const childNode = nodeMap[child.ac_id.toString()];
          if (childNode) {
            selfNode.children.push(childNode);
            childNode.parents.push(selfNode);
  
            // Add spouse as a parent if applicable
            if (spouseData) {
              const spouseNode = nodeMap[spouseData.ac_id.toString()];
              if (spouseNode) {
                spouseNode.children.push(childNode);
                childNode.parents.push(spouseNode);
              }
            }
          }
        });
  
        // Ensure no self-node as a parent
        selfNode.parents = [];
      }
    }
  
    // Filter nodes: only include Self, Spouse, Son, and Daughter
    const filteredNodes = Object.values(nodeMap).filter(
      node =>
        node.relation === "Self" ||
        node.relation === "Spouse" ||
        ["Son", "Daughter"].includes(node.relation),
    );
  
    setNodes(filteredNodes);
  };
  

  useEffect(() => {
    if (userId && form.user_id) {
      mapFamilyTree()
    }
  }, [form, familyInformation])

  const sanitizeNodes = nodes =>
    nodes.map(node => ({
      ...node,
      parents: node.relation === "Self" ? [] : node.parents.filter(parentId => parentId !== node.id),
      children: node.children.filter(childId => childId !== node.id),
    }));
  
  const sanitizedNodes = sanitizeNodes(nodes);
  const validRootId = nodes.find(node => node.id === rootId) ? rootId : nodes[0]?.id;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="page-title-box">
            <h4 className="font-size-18">FAMILY TREE</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Family Tree</a>
              </li>
            </ol>
          </div>

          <div className="card">
            <div className="card-body">
              <h4 className="font-size-18">Family Tree</h4>
              <a className="btn btn-danger my-2" href="extended-family">
                Extended Family Tree
              </a>


              {nodes.length === 0 && (
              <div className="alert alert-info">No family data available.</div>
              )}

              <button className="btn btn-primary my-2" onClick={mapFamilyTree}>
                Refresh Family Tree
              </button>
              {rootId && nodes.length > 0 && (
                <div className="d-flex justify-content-center">
                  <ReactFamilyTree
                    nodes={nodes}
                    rootId={rootId}
                    width={WIDTH}
                    height={HEIGHT}
                    renderNode={node => (
                      <FamilyNode
                        key={node.id}
                        node={node}
                        isRoot={node.id === validRootId}
                        style={{
                          width: WIDTH,
                          height: HEIGHT,
                         transform: `translate(${node.left * (WIDTH / 2)}px, ${node.top * (HEIGHT / 2)}px)`,
                        }}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FamilyTree
