import React, { useEffect, useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const DocumentSearchTable = ({ data: searchResults }) => {
  const [documentsearch, setDocumentSearch] = useState({ data: [] })

  useEffect(() => {
    if (searchResults && Array.isArray(searchResults)) {
      setDocumentSearch({ ...documentsearch, data: searchResults })
    }
  }, [searchResults])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_document_vault`
      const data = { doc_id: id }
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options)
      const removeData = await response.json()

      if (removeData?.success === true) {
        setDocumentSearch({
          ...documentsearch,
          data: documentsearch?.data?.filter(each => each.dl_id !== id),
        })
        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage("Error removing document.", "error", false)
    }
  }

  const downloadFile = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl, { mode: "cors" }); // Fetch the file
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName || "downloaded-file";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
      setErrorMessage("Error downloading document. Please try again.");
      showMessage("Error downloading document.", "error", false);
    }
  };

  const getFileExtension = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
      return '.png'; // Default to PNG for image files
    }
    if (['pdf', 'docx', 'doc', 'txt', 'xls', 'xlsx'].includes(extension)) {
      return '.' + extension; // Return correct extension for docs and other file types
    }
    return ''; // Default no extension if unknown type
  };

  const data = {
    columns: [
      { label: "S. No.", field: "sno", sort: "asc", width: 50 },
      // { label: "Edit", field: "edit", sort: "asc" },
      { label: "Document Name", field: "docName", sort: "asc", width: 150 },
      { label: "Document Type", field: "docType", sort: "asc", width: 150 },
      {
        label: "Document Subtype",
        field: "docSubtype",
        sort: "asc",
        width: 150,
      },
      { label: "Country", field: "country", sort: "asc", width: 100 },
      { label: "Region", field: "region", sort: "asc", width: 100 },
      { label: "Tags", field: "tags", sort: "asc", width: 150 },
      { label: "Upload Date", field: "uploadDate", sort: "asc", width: 150 },
      { label: "Version", field: "version", sort: "asc", width: 100 },
      { label: "Download", field: "download", sort: "asc", width: 150 },
    ],
    rows: documentsearch?.data?.map((each, index) => ({
      sno: <div>{index + 1}</div>,
      // edit: (
      //   <Link
      //     to={`/make_document?id=${each.dl_id}&type=0`}
      //     className="btn btn-outline-primary btn-sm"
      //     title="Edit"
      //   >
      //     <i className="fas fa-pencil-alt"></i>
      //   </Link>
      // ),
      docName: each.dl_docName || "N/A",
      docType: each.dl_docType || "N/A",
      docSubtype: each.dl_docSubtype || "N/A",
      country: each.dl_country || "N/A",
      region: each.dl_region || "N/A",
      tags: each.dl_tags || "N/A",
      uploadDate: each.dl_upload_date || "N/A",
      version: each.dl_version || "N/A",
      download: (
        <button
        className="btn btn-outline-primary btn-sm"
        onClick={() => downloadFile(each?.dl_file, `${each?.dl_docName}${getFileExtension(each?.dl_file)}`)}
        aria-label={`Download ${each?.dl_docName}`}
      >
        Download
      </button>
      ),
    })),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DocumentSearchTable
