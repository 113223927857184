import React, { useEffect, useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { loginUserData, userId } from "helpers/userId"

const EmailListTable = () => {
  const [documentvault, setDocumentVault] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_email_temp_list`,
      )
      const data = await response.json()
      setDocumentVault({ ...documentvault, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_email_temp`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        sd_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setDocumentVault({
          ...documentvault,
          data: documentvault?.data?.filter(each => each.sd_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  // const handleArchiveRole = async id => {
  //   try {
  //     const fetchSingleData = async id => {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/single_email_temp?sd_id=${id}`
  //       )
  //       const data = await response.json()
  //       return data?.data ? data.data[0] : null
  //     }

  //     const userData = JSON.parse(localStorage.getItem("userData"))

  //     const record = await fetchSingleData(id)

  //     if (!record) {
  //       showMessage("Record not found", "error", false)
  //       return
  //     }

  //     const alreadyArchived = documentvault?.data?.find(
  //       each => each.sd_id === id && each.sd_flog === 1
  //     )

  //     if (alreadyArchived) {
  //       showMessage("This is already archived", "info")
  //       return
  //     }

  //     const data = {
  //       sd_id: id,
  //       sd_flog: 1, // Archiving the record
  //       user_id: userData.user.user_id,
  //       sd_tem_name: record.sd_tem_name,
  //       sd_role_id: record.sd_role_id,
  //       sd_cc_email: record.sd_cc_email,
  //       sd_from_email: record.sd_from_email,
  //       sd_subject: record.sd_subject,
  //       sd_message: record.sd_message,
  //       sd_banner: record.sd_banner,
  //       sd_image: record.sd_image,
  //       sd_button_name: record.sd_button_name,
  //       sd_button: record.sd_button,
  //     }

  //     const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_email_temp`
  //     const options = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     }

  //     const response = await fetch(url, options)
  //     const archiveData = await response.json()

  //     if (archiveData?.success === true) {
  //       setDocumentVault({
  //         ...documentvault,
  //         data: documentvault?.data?.map(each =>
  //           each.sd_id === id ? { ...each, sd_flog: 1 } : each
  //         ),
  //       })

  //       showMessage(archiveData.message, "success")
  //     } else {
  //       showMessage(archiveData.message, "error", false)
  //     }
  //   } catch (error) {
  //     showMessage("An error occurred while archiving the item", "error", false)
  //   }
  // }

  const handleArchiveRole = async (e, id) => {
    e.preventDefault()
    try {
      const alreadyArchived = documentvault?.data?.find(
        each => each.sd_id === id && each.sd_flog === 1,
      )

      if (alreadyArchived) {
        showMessage("This item is already archived", "info")
        return
      }

      const fetchSingleData = async id => {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/single_email_temp?sd_id=${id}`,
        )
        const data = await response.json()
        return data?.data ? data.data[0] : null
      }

      const userData = JSON.parse(localStorage.getItem("userData"))

      const record = await fetchSingleData(id)

      if (!record) {
        showMessage("Record not found", "error", false)
        return
      }

      const data = {
        sd_id: id,
        sd_flog: 1, // Archiving the record
        user_id: userData.user.user_id,
        sd_tem_name: record.sd_tem_name,
        sd_role_id: record.sd_role_id,
        sd_cc_email: record.sd_cc_email,
        sd_from_email: record.sd_from_email,
        sd_subject: record.sd_subject,
        sd_message: record.sd_message,
        sd_banner: record.sd_banner,
        sd_image: record.sd_image,
        sd_button_name: record.sd_button_name,
        sd_button: record.sd_button,
      }

      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_email_temp`
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options)
      const archiveData = await response.json()

      if (archiveData?.success === true) {
        setDocumentVault({
          ...documentvault,
          data: documentvault?.data?.map(each =>
            each.sd_id === id ? { ...each, sd_flog: 1 } : each,
          ),
        })

        showMessage(archiveData.message, "success")
      } else {
        showMessage(archiveData.message, "error", false)
      }
    } catch (error) {
      showMessage("An error occurred while archiving the item", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "To",
        field: "to",
        sort: "asc",
        width: 200,
      },
      {
        label: "Subject",
        field: "subject",
        sort: "asc",
        width: 100,
      },
      {
        label: "Sent By",
        field: "sentby",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: documentvault?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          each.sd_flog === 1 ? (
            <button
              className="btn btn-outline-primary btn-sm edit"
              disabled
              title="Edit (Archived)"
            >
              <i className="fas fa-pencil-alt"></i>
            </button>
          ) : (
            <Link
              to={`/add_email?id=${each.sd_id}&temp=${each.sd_tem_name}`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ),
        date: each?.sd_created_date || "",
        to:
          each?.sd_role_id === "0"
            ? "Customer"
            : each?.sd_role_id === "1"
              ? "Admin"
              : each?.sd_role_id === "2"
                ? "Operation"
                : each?.sd_role_id === "3"
                  ? "Business Partner"
                  : "",

        subject: each?.sd_subject || "",
        sentby:  `${each?.user_fname} ${each?.user_lname}` || "", 
        action:
          (
            <div style={{ display: "flex", gap: "15px" }}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.sd_id &&
                  window.confirm(
                    `Are you sure you want to delete ${each.sd_tem_name}?`,
                  ) &&
                  handleRemoveRole(each.sd_id)
                }
              ></i>
              <i
                className="ion ion-md-archive"
                title="Archive"
                type="button"
                onClick={e =>
                  each.sd_id &&
                  window.confirm(
                    `Are you sure you want to archive ${each.sd_tem_name}?`,
                  ) &&
                  handleArchiveRole(e, each.sd_id)
                }
              ></i>
            </div>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Email List</CardTitle>
              <div className="col-12 d-flex">
                <div>
                  <Link
                    to="/email_template"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add Email
                  </Link>
                </div>
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default EmailListTable
