import React, { useEffect, useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { loginUserData, userId } from "helpers/userId"
// FileViewer component to handle file viewing in a new tab
const FileViewer = ({ dv_file }) => {
  const getFileExtension = fileName => {
    return fileName?.split(".").pop().toLowerCase()
  }

  const fileExtension = getFileExtension(dv_file)

  if (!dv_file) return <p>No file available</p>

  return (
    <>
      {fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "png" ? (
        // Button to open the image in a new tab
        <a href={dv_file} target="_blank" rel="noopener noreferrer">
          View Image
        </a>
      ) : fileExtension === "doc" || fileExtension === "docx" ? (
        // Button to open DOC/DOCX via Google Docs viewer in a new tab
        <a
          href={`https://docs.google.com/gview?url=${dv_file}&embedded=true`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Document
        </a>
      ) : fileExtension === "pdf" ? (
        // Button to open PDF in a new tab
        <a
          href={`https://docs.google.com/gview?url=${dv_file}&embedded=true`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View PDF
        </a>
      ) : (
        // Fallback for unsupported formats
        <p>Unsupported file format</p>
      )}
    </>
  )
}

const DocumentsListTables = () => {
  const [documents, setDocumentVault] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_documents`,
      )
      const data = await response.json()
      setDocumentVault({ ...documents, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_documents`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        dl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setDocumentVault({
          ...documents,
          data: documents?.data?.filter(each => each.dl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const handleArchiveRole = async (e, id) => {
    e.preventDefault()
    try {
      const alreadyArchived = documents?.data?.find(
        each => each.dl_id === id && each.dl_flag === 1,
      )

      if (alreadyArchived) {
        showMessage("This item is already archived", "info")
        return
      }

      const fetchSingleData = async id => {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/single_documents?dl_id=${id}`,
        )
        const data = await response.json()
        return data?.data ? data.data[0] : null
      }

      const userData = JSON.parse(localStorage.getItem("userData"))

      const record = await fetchSingleData(id)

      if (!record) {
        showMessage("Record not found", "error", false)
        return
      }

      const data = {
        dl_id: id,
        dl_flag: 1,
        dl_docName: record.dl_docName,
        dl_docType: record.dl_docType,
        dl_docSubtype: record.dl_docSubtype,
        dl_country: record.dl_country,
        dl_region: record.dl_region,
        dl_tags: record.dl_tags,
        dl_upload_date: record.dl_upload_date,
        dl_version: record.dl_version,
        dl_file: record.dl_file,
        dl_active: record.dl_active,
        dl_docOwner: userId,
      }

      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/update_documents`
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options)
      const archiveData = await response.json()

      if (archiveData?.success === true) {
        // setDocumentVault({
        //   ...documents,
        //   data: documents?.data?.map(each =>
        //     each.dl_id === id ? { ...each, dl_flag: 1 } : each
        //   ),
        // })

        showMessage(archiveData.message, "success")
      } else {
        showMessage(archiveData.message, "error", false)
      }
    } catch (error) {
      showMessage("An error occurred while archiving the item", "error", false)
    }
  }

  const handleCheck=() =>{
    showMessage("This is Archive Document", "success")
  } 

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Document Name",
        field: "DocumentName",
        sort: "asc",
        width: 270,
      },
      {
        label: "Doc Type",
        field: "type",
        sort: "asc",
        width: 200,
      },
      {
        label: "Sub Type",
        field: "subtype",
        sort: "asc",
        width: 100,
      },
      {
        label: "Tags",
        field: "tags",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date Uploaded",
        field: "udate",
        sort: "asc",
        width: 100,
      },
      {
        label: "Document Owner",
        field: "owner",
        sort: "asc",
        width: 100,
      },
      {
        label: "Version",
        field: "version",
        sort: "asc",
        width: 100,
      },
      {
        label: "Doc Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: documents?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <>
              {each.dl_flag == 1 ? (
                <Link
                 
                  className="btn btn-outline-primary btn-sm edit"
                  title="Edit"
                  to={"#"}
                >
                  <i className="fas fa-pencil-alt"></i>
                </Link>
              ) : (
                <Link
                  to={`/add_documents?id=${each.dl_id}`}
                  className="btn btn-outline-primary btn-sm edit"
                  title="Edit"
                >
                  <i className="fas fa-pencil-alt"></i>
                </Link>
              )}
            </>
          ) || "",
        DocumentName: each?.dl_docName || "",
        type: each?.dl_docType || "",
        subtype: each?.dl_docSubtype || "",
        tags: each?.dl_tags || "",
        udate: each?.dl_upload_date || "",
        owner: `${each?.user_fname} ${each?.user_lname}` || "", 
        version: each?.dl_version || "",
        status: each?.dl_active || "",
        action:
          (
            <div style={{ display: "flex", gap: "15px" }}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.dl_id &&
                  window.confirm(
                    `Are you sure you want to delete ${each.dl_docName}?`,
                  ) &&
                  handleRemoveRole(each.dl_id)
                }
              ></i>
              <i
                className="ion ion-md-archive"
                title="Archive"
                type="button"
                onClick={e =>
                  each.dl_id &&
                  window.confirm(
                    `Are you sure you want to archive ${each.dl_docName}?`,
                  ) &&
                  handleArchiveRole(e, each.dl_id)
                }
              ></i>
            </div>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Document List</CardTitle>
              <div className="col-12 d-flex">
                <div>
                  <Link
                    to="/add_documents"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add Documents
                  </Link>
                </div>
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DocumentsListTables
