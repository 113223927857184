import React, { useEffect, useState } from "react";
const { MDBDataTable } = require("mdbreact");
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap");
import "../../styles/datatables.scss";
import { Link } from "react-router-dom";
import { showMessage } from "components/alertmessages/Alertmessages";
import { userId } from "helpers/userId";

const BankSyncTable = () => {
  const [bankSyncData, setBankSyncData] = useState({ data: [] });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchBankSyncData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/getAllBankSync?user_id=${userId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch bank sync data");
        }

        const data = await response.json();
        console.log(data)
        setBankSyncData({ data: data?.data || [] });
      } catch (error) {
        showMessage(error.message || "Failed to load data", "error", false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBankSyncData();
  }, []);

  // Handle delete action
  const handleDelete = async (id) => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_investment`;
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        bs_uid: userData?.user?.user_id,
        bs_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error("Failed to delete the bank record");
      }

      const result = await response.json();

      if (result?.success) {
        setBankSyncData((prevState) => ({
          ...prevState,
          data: prevState.data.filter((item) => item.bs_id !== id),
        }));

        showMessage(result.message, "success");
      } else {
        throw new Error(result.message || "Failed to delete the record");
      }
    } catch (error) {
      console.error("Error deleting bank record:", error);
      showMessage(error.message || "Something went wrong", "error", false);
    }
  };

  // Prepare data for the table
  const data = {
    columns: [
      { label: "S. No.", field: "sno", sort: "asc" },
      { label: "Edit", field: "edit", sort: "asc" },
      { label: "Account Number", field: "account_number", sort: "asc" },
      {
        label: "Financial Institution Name",
        field: "financial_institution_name",
        sort: "asc",
      },
      { label: "Account Type", field: "account_type", sort: "asc" },
      { label: "Amount", field: "amount", sort: "asc" },
      { label: "Action", field: "action", sort: "asc" },
    ],
    rows: bankSyncData?.data?.map((item, index) => ({
      sno: index + 1,
      edit: (
        <Link
          to={`/add-investment?id=${item.bs_id}`}
          className="btn btn-outline-primary btn-sm edit"
          title="Edit"
        >
          <i className="fas fa-pencil-alt"></i>
        </Link>
      ),
      account_number: item.bs_account_number || "N/A",
      financial_institution_name: item.bs_financial_institution_name|| "N/A",
      account_type: item.bs_account_type || "N/A",
      amount: item.bs_amount || "N/A",
      action: (
        <Col xl={3} lg={4} sm={6}>
          <i
            className="ion ion-md-trash"
            title="Delete"
            type="button"
            onClick={() =>
              item.bs_id &&
              window.confirm(
                `Are you sure you want to delete this record: ${item.bs_account_number}?`
              ) &&
              handleDelete(item.bs_id)
            }
          ></i>
        </Col>
      ),
    })),
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Bank Sync List</CardTitle>
              <div className="col-12 d-flex">
                <div>
                  <Link
                    to="/add-investment"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add Bank Sync
                  </Link>
                </div>
              </div>
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <MDBDataTable
                  responsive
                  bordered
                  data={data}
                  noBottomColumns={true}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BankSyncTable;
