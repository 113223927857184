import React, { useEffect, useState, useRef } from "react"
import axios from "axios"

const TenantsInCommonAgreement = React.forwardRef((props, ref) => {
  console.log(ref)
  const [propertyAddresse, setPropertyAddresse] = useState([])
  const [partnersInfo, setPartnersInfo] = useState([])
  const [error, setError] = useState(null)
  const [mergedData, setMergedData] = useState([])
  const [filteredPartners, setFilteredPartners] = useState([])
  const [filteredPropertyAdd, setfilteredPropertyAdd] = useState([])
  const [textBoxFiled, setTextBoxFiled] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await axios.get(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/getAllDocSearchTcgProAddresses`
        )
        setPropertyAddresse(response1.data)
        const response2 = await axios.get(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get-all-doc-tcg`
        )
        setPartnersInfo(response2.data)
        const response3 = await axios.get(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/getAllDocSearchTcgTextBox`
        )
        setTextBoxFiled(response3.data)
      } catch (err) {
        setError(err.message)
        console.error("Error fetching data:", err)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (partnersInfo && propertyAddresse) {
      const validPartnersArray = Array.isArray(partnersInfo)
        ? partnersInfo.filter(item => typeof item === "object" && item !== null)
        : Object.values(partnersInfo).filter(
            item => typeof item === "object" && item !== null
          )

      const propertyAddressesArray = Array.isArray(propertyAddresse)
        ? propertyAddresse
        : Object.values(propertyAddresse)

      const filteredPartners = validPartnersArray.filter(partner =>
        propertyAddressesArray.some(
          pro =>
            pro.user_id === partner.user_id && pro.doc_id === partner.doc_id
        )
      )
      setFilteredPartners(filteredPartners)
    }
  }, [partnersInfo, propertyAddresse])

  useEffect(() => {
    if (filteredPartners) {
      const validPartnersArray = Array.isArray(filteredPartners)
        ? filteredPartners.filter(
            item => typeof item === "object" && item !== null
          )
        : Object.values(filteredPartners).filter(
            item => typeof item === "object" && item !== null
          )

      const propertyAddressesArray = Array.isArray(propertyAddresse)
        ? propertyAddresse
        : Object.values(propertyAddresse)

      const cleanedPropertyAddressesArray =
        propertyAddressesArray.length === 2 &&
        Array.isArray(propertyAddressesArray[1])
          ? propertyAddressesArray[1]
          : propertyAddressesArray

      const filteredProperty = filteredPartners
        .map(partnerGroup => {
          return partnerGroup.map(partner => {
            const matchingProperty = cleanedPropertyAddressesArray.find(
              pro => pro.tcg_id === partner.tcg_id
            )

            return matchingProperty
              ? {
                  pro_address: matchingProperty.pro_address,
                }
              : null
          })
        })
        .flat()
        .filter(item => item !== null)

      setfilteredPropertyAdd(filteredProperty)
    }
  }, [filteredPartners])

  function getCurrentDate() {
    const today = new Date()
    const day = String(today.getDate()).padStart(2, "0")
    const month = String(today.getMonth() + 1).padStart(2, "0")
    const year = today.getFullYear()

    return `${day}/${month}/${year}`
  }

  return (
    <>
    
      {/* {ref.current === "1" && ( */}
        <div>
          {" "}
          <table>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontStyle: "italic",
                  width: "800px",
                }}
              >
                <h2>Tenants in Common Agreement</h2>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td>
                <p>
                  THIS AGREEMENT, made on this {getCurrentDate()}, by and among
                </p>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tbody>
              {filteredPartners.length > 0 &&
              Array.isArray(filteredPartners[0]) ? (
                filteredPartners[0].map((item, index) => (
                  <tr key={index}>
                    <p key={index}>
                      <strong>Partner {index + 1} is </strong>{" "}
                      <b>{item.tcg_fullname}</b>, located at{" "}
                      <b>{item.tcg_address}</b>, with a percentage of{" "}
                      <strong>{item.tcg_persant}%</strong>.
                    </p>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No matching data found.</td>
                </tr>
              )}
            </tbody>
          </table>
          <table>
            <tr>
              <td>
                <p>
                  hereinafter referred to singularly as Tenant and collectively
                  referred to as the 'Tenants in Common'.
                </p>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  width: "800px",
                }}
              >
                <h3>Recitals</h3>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td>
                <p>
                  The Tenants in Common have jointly acquired the fee title to
                  the property described below (the 'Property').
                </p>
              </td>
            </tr>
          </table>
          <br />
          <b>The Property is legally described as follows: </b>
          <br />
          <tbody>
            {filteredPropertyAdd.length ? (
              filteredPropertyAdd.map((item, index) => (
                <tr key={index}>
                  <td>
                    <b>{item.pro_address}</b>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No matching data found.</td>
              </tr>
            )}
          </tbody>
          <br />
          <table>
            <tr>
              <td>
                <p>
                  Each of the Tenants in Common has an equal and undivided
                  interest in the Property as follows:{" "}
                </p>
              </td>
            </tr>
          </table>
          {filteredPartners.length ? (
            filteredPartners.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.tcg_fullname}</td>
                <td>{item.tcg_persant}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No matching data found.</td>
            </tr>
          )}
          <table>
            <tr>
              <td>
                <p>
                  The parties have agreed to limitations on the right and power
                  to transfer their undivided interests.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Definitions of terms</b>
                <p>
                  The following terms shall have the meanings and definitions as
                  set forth below:
                </p>
                <p>
                  (a) 'Related individual' or 'related individuals' shall mean
                  brothers, sisters, spouses, lineal descendants, spouses of
                  lineal descendants, trustees for the benefit of any such
                  persons, and corporations owned or controlled by any of the
                  foregoing.
                </p>
                <p>
                  (b) 'Proportionate share' shall mean that portion of the
                  undivided interest in the Property offered for sale which the
                  undivided interests owned by a Tenant in Common and his
                  'related individuals' bears to all of the undivided interests
                  in the Property, other than that offered for sale.
                </p>
                <p>
                  (c) 'Computed value' shall mean the value of the undivided
                  interests in the Property as assessed by a comparative market
                  analysis performed by a licensed realtor selected and agreed
                  to by the Tenants in Common. Such computation shall be binding
                  and conclusive upon the parties. The computation shall be made
                  in accordance with generally accepted practices consistently
                  applied in the area.
                </p>
                <p>Now, therefore, the parties agree as follows:</p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Transfer Restriction</b>
                <p>
                  Each of the Tenants in Common will not dispose, transfer,
                  devise, convey, lease, mortgage, or otherwise encumber his
                  undivided interest in the Property, without the written
                  consent of the other Tenants in Common, whose consent shall
                  not be unreasonably withheld.
                </p>
                <p>
                  (a) Upon the death of a Tenant in Common, his or her undivided
                  interest may be transferred to a 'related individual' or to
                  'related individuals' upon such terms as may be agreed upon
                  with the executor or administrator of the deceased Tenant's
                  estate or as may be provided in his will or in a separate
                  agreement duly signed by the Tenant. Notwithstanding any such
                  transfer to a related individual, the transferee shall not
                  have any rights in and to the Property except through the
                  transferor Tenant.
                </p>
                <p>
                  (b) In the event that no transfer is made as provided in
                  subparagraph (a) after the appointment of an executor or
                  administrator of the deceased Tenant's estate, then the
                  executor or administrator shall offer the undivided interest
                  for sale to the other Tenants in Common at the 'computed
                  value.' Each Tenant in Common shall have the right to purchase
                  a 'proportionate share' of the undivided interest. Payment for
                  the undivided interest shall be made within 30 days after the
                  determination of the 'computed value' unless arrangements for
                  deferred payment are agreed upon. In the event that the
                  undivided interest of the seller is not purchased by the other
                  Tenants in Common, then the undivided interest shall be free
                  of all restrictions under this agreement.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Transfer to Related Individual</b>
                <p>
                  Each of the Tenants in Common shall have the right to transfer
                  to a 'related individual' or to 'related individuals' a share
                  or all of his/her undivided interest in the Property, in which
                  event the party making the transfer shall deliver to the other
                  parties to this agreement within 30 days of the making of the
                  transfer, a copy of each and every instrument providing for
                  the transfer. In the event of such transfer, the other parties
                  to this agreement need not consult with or seek the approval
                  of the transferee, and in all matters the consent of the
                  transferor Tenant shall be binding and conclusive upon all
                  such transferees.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Partition</b>
                <p>
                  Each of the Tenants in Common irrevocably waives any and all
                  right that he may have to maintain any action for partition
                  with respect to his undivided interest in the Property or to
                  compel any sale thereof under any applicable laws now existing
                  or subsequently enacted.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Improvement of Property</b>
                <p>
                  Any improvement, sale, or mortgaging of the Property may be
                  made only of the entire Property and then only upon the
                  written consent of all Tenants in Common. However, if two (2)
                  of the Tenants in Common vote to improve, sell or mortgage the
                  Property, said two (2) may acquire the interest of the
                  remaining Tenant in Common by paying him the computed value.
                  Each of the Tenants in Common shall from time to time advance
                  or cause to be advanced, on a pro rata basis, moneys to a
                  common fund as such moneys shall from time to time be required
                  to pay for the maintenance and operation of the Property,
                  including interest and principal on mortgage liens, and for
                  the cost of any improvement. Should any Tenant in Common for
                  any reason fail or refuse to advance or cause to be advanced
                  his pro rata share of moneys required, then and in that event,
                  the other Tenants or Tenant in Common, as the case may be,
                  shall have the right to acquire the interest of the delinquent
                  Tenant in Common at computed value. In the computation for
                  this purpose the value of any real estate and interests in
                  real estate, including mortgages and leasehold improvements,
                  shall be the book value as shown on the regular books of
                  account without any adjustment for any agreed or appraised
                  value.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Profit and loss</b>
                <p>
                  The profits and losses from the operation of the Property
                  shall be shared by the Tenants in Common in accordance with
                  their ownership interest.
                </p>
              </td>
            </tr>
          </table>
          <table>
            {filteredPartners.length > 0 &&
            Array.isArray(filteredPartners[0]) ? (
              filteredPartners[0].map((item, index) => (
                <tr key={index}>
                  <p key={index}>
                    <strong>Partner {index + 1} is </strong>{" "}
                    <b>{item.tcg_fullname}</b>,<br></br>
                    <strong>Date: {getCurrentDate()}</strong>.
                  </p>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No matching data found.</td>
              </tr>
            )}
          </table>
        
        </div>
       {/* )} */}
      {/* {ref.current === "2" && (
        <div>
          {" "}
          <table>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontStyle: "italic",
                  width: "800px",
                }}
              >
                <h2>Document Agreement</h2>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td>
                <p>
                  THIS AGREEMENT, made on this {getCurrentDate()}, by and among
                </p>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tbody>
              {filteredPartners.length > 0 &&
              Array.isArray(filteredPartners[0]) ? (
                filteredPartners[0].map((item, index) => (
                  <tr key={index}>
                    <p key={index}>
                      <strong>Partner {index + 1} is </strong>{" "}
                      <b>{item.tcg_fullname}</b>, located at{" "}
                      <b>{item.tcg_address}</b>, with a percentage of{" "}
                      <strong>{item.tcg_persant}%</strong>.
                    </p>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No matching data found.</td>
                </tr>
              )}
            </tbody>
          </table>
          <table>
            <tr>
              <td>
                <p>
                  hereinafter referred to singularly as Tenant and collectively
                  referred to as the 'Tenants in Common'.
                </p>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  width: "800px",
                }}
              >
                <h3>Recitals</h3>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td>
                <p>
                  The Tenants in Common have jointly acquired the fee title to
                  the property described below (the 'Property').
                </p>
              </td>
            </tr>
          </table>
          <br />
          <b>The Property is legally described as follows: </b>
          <br />
          <tbody>
            {filteredPropertyAdd.length ? (
              filteredPropertyAdd.map((item, index) => (
                <tr key={index}>
                  <td>
                    <b>{item.pro_address}</b>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No matching data found.</td>
              </tr>
            )}
          </tbody>
          <br />
          <table>
            <tr>
              <td>
                <p>
                  Each of the Tenants in Common has an equal and undivided
                  interest in the Property as follows:{" "}
                </p>
              </td>
            </tr>
          </table>
          {filteredPartners.length ? (
            filteredPartners.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.tcg_fullname}</td>
                <td>{item.tcg_persant}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No matching data found.</td>
            </tr>
          )}
          <table>
            <tr>
              <td>
                <p>
                  The parties have agreed to limitations on the right and power
                  to transfer their undivided interests.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Definitions of terms</b>
                <p>
                  The following terms shall have the meanings and definitions as
                  set forth below:
                </p>
                <p>
                  (a) 'Related individual' or 'related individuals' shall mean
                  brothers, sisters, spouses, lineal descendants, spouses of
                  lineal descendants, trustees for the benefit of any such
                  persons, and corporations owned or controlled by any of the
                  foregoing.
                </p>
                <p>
                  (b) 'Proportionate share' shall mean that portion of the
                  undivided interest in the Property offered for sale which the
                  undivided interests owned by a Tenant in Common and his
                  'related individuals' bears to all of the undivided interests
                  in the Property, other than that offered for sale.
                </p>
                <p>
                  (c) 'Computed value' shall mean the value of the undivided
                  interests in the Property as assessed by a comparative market
                  analysis performed by a licensed realtor selected and agreed
                  to by the Tenants in Common. Such computation shall be binding
                  and conclusive upon the parties. The computation shall be made
                  in accordance with generally accepted practices consistently
                  applied in the area.
                </p>
                <p>Now, therefore, the parties agree as follows:</p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Transfer Restriction</b>
                <p>
                  Each of the Tenants in Common will not dispose, transfer,
                  devise, convey, lease, mortgage, or otherwise encumber his
                  undivided interest in the Property, without the written
                  consent of the other Tenants in Common, whose consent shall
                  not be unreasonably withheld.
                </p>
                <p>
                  (a) Upon the death of a Tenant in Common, his or her undivided
                  interest may be transferred to a 'related individual' or to
                  'related individuals' upon such terms as may be agreed upon
                  with the executor or administrator of the deceased Tenant's
                  estate or as may be provided in his will or in a separate
                  agreement duly signed by the Tenant. Notwithstanding any such
                  transfer to a related individual, the transferee shall not
                  have any rights in and to the Property except through the
                  transferor Tenant.
                </p>
                <p>
                  (b) In the event that no transfer is made as provided in
                  subparagraph (a) after the appointment of an executor or
                  administrator of the deceased Tenant's estate, then the
                  executor or administrator shall offer the undivided interest
                  for sale to the other Tenants in Common at the 'computed
                  value.' Each Tenant in Common shall have the right to purchase
                  a 'proportionate share' of the undivided interest. Payment for
                  the undivided interest shall be made within 30 days after the
                  determination of the 'computed value' unless arrangements for
                  deferred payment are agreed upon. In the event that the
                  undivided interest of the seller is not purchased by the other
                  Tenants in Common, then the undivided interest shall be free
                  of all restrictions under this agreement.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Transfer to Related Individual</b>
                <p>
                  Each of the Tenants in Common shall have the right to transfer
                  to a 'related individual' or to 'related individuals' a share
                  or all of his/her undivided interest in the Property, in which
                  event the party making the transfer shall deliver to the other
                  parties to this agreement within 30 days of the making of the
                  transfer, a copy of each and every instrument providing for
                  the transfer. In the event of such transfer, the other parties
                  to this agreement need not consult with or seek the approval
                  of the transferee, and in all matters the consent of the
                  transferor Tenant shall be binding and conclusive upon all
                  such transferees.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Partition</b>
                <p>
                  Each of the Tenants in Common irrevocably waives any and all
                  right that he may have to maintain any action for partition
                  with respect to his undivided interest in the Property or to
                  compel any sale thereof under any applicable laws now existing
                  or subsequently enacted.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Improvement of Property</b>
                <p>
                  Any improvement, sale, or mortgaging of the Property may be
                  made only of the entire Property and then only upon the
                  written consent of all Tenants in Common. However, if two (2)
                  of the Tenants in Common vote to improve, sell or mortgage the
                  Property, said two (2) may acquire the interest of the
                  remaining Tenant in Common by paying him the computed value.
                  Each of the Tenants in Common shall from time to time advance
                  or cause to be advanced, on a pro rata basis, moneys to a
                  common fund as such moneys shall from time to time be required
                  to pay for the maintenance and operation of the Property,
                  including interest and principal on mortgage liens, and for
                  the cost of any improvement. Should any Tenant in Common for
                  any reason fail or refuse to advance or cause to be advanced
                  his pro rata share of moneys required, then and in that event,
                  the other Tenants or Tenant in Common, as the case may be,
                  shall have the right to acquire the interest of the delinquent
                  Tenant in Common at computed value. In the computation for
                  this purpose the value of any real estate and interests in
                  real estate, including mortgages and leasehold improvements,
                  shall be the book value as shown on the regular books of
                  account without any adjustment for any agreed or appraised
                  value.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Profit and loss</b>
                <p>
                  The profits and losses from the operation of the Property
                  shall be shared by the Tenants in Common in accordance with
                  their ownership interest.
                </p>
              </td>
            </tr>
          </table>
          <table>
            {filteredPartners.length > 0 &&
            Array.isArray(filteredPartners[0]) ? (
              filteredPartners[0].map((item, index) => (
                <tr key={index}>
                  <p key={index}>
                    <strong>Partner {index + 1} is </strong>{" "}
                    <b>{item.tcg_fullname}</b>,<br></br>
                    <strong>Date: {getCurrentDate()}</strong>.
                  </p>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No matching data found.</td>
              </tr>
            )}
          </table>
        </div>
      )}
      {ref.current === "3" && (
        <div>
          {" "}
          <table>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontStyle: "italic",
                  width: "800px",
                }}
              >
                <h2>Document 3 Common Agreement</h2>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td>
                <p>
                  THIS AGREEMENT, made on this {getCurrentDate()}, by and among
                </p>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tbody>
              {filteredPartners.length > 0 &&
              Array.isArray(filteredPartners[0]) ? (
                filteredPartners[0].map((item, index) => (
                  <tr key={index}>
                    <p key={index}>
                      <strong>Partner {index + 1} is </strong>{" "}
                      <b>{item.tcg_fullname}</b>, located at{" "}
                      <b>{item.tcg_address}</b>, with a percentage of{" "}
                      <strong>{item.tcg_persant}%</strong>.
                    </p>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No matching data found.</td>
                </tr>
              )}
            </tbody>
          </table>
          <table>
            <tr>
              <td>
                <p>
                  hereinafter referred to singularly as Tenant and collectively
                  referred to as the 'Tenants in Common'.
                </p>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  width: "800px",
                }}
              >
                <h3>Recitals</h3>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td>
                <p>
                  The Tenants in Common have jointly acquired the fee title to
                  the property described below (the 'Property').
                </p>
              </td>
            </tr>
          </table>
          <br />
          <b>The Property is legally described as follows: </b>
          <br />
          <tbody>
            {filteredPropertyAdd.length ? (
              filteredPropertyAdd.map((item, index) => (
                <tr key={index}>
                  <td>
                    <b>{item.pro_address}</b>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No matching data found.</td>
              </tr>
            )}
          </tbody>
          <br />
          <table>
            <tr>
              <td>
                <p>
                  Each of the Tenants in Common has an equal and undivided
                  interest in the Property as follows:{" "}
                </p>
              </td>
            </tr>
          </table>
          {filteredPartners.length ? (
            filteredPartners.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.tcg_fullname}</td>
                <td>{item.tcg_persant}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No matching data found.</td>
            </tr>
          )}
          <table>
            <tr>
              <td>
                <p>
                  The parties have agreed to limitations on the right and power
                  to transfer their undivided interests.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Definitions of terms</b>
                <p>
                  The following terms shall have the meanings and definitions as
                  set forth below:
                </p>
                <p>
                  (a) 'Related individual' or 'related individuals' shall mean
                  brothers, sisters, spouses, lineal descendants, spouses of
                  lineal descendants, trustees for the benefit of any such
                  persons, and corporations owned or controlled by any of the
                  foregoing.
                </p>
                <p>
                  (b) 'Proportionate share' shall mean that portion of the
                  undivided interest in the Property offered for sale which the
                  undivided interests owned by a Tenant in Common and his
                  'related individuals' bears to all of the undivided interests
                  in the Property, other than that offered for sale.
                </p>
                <p>
                  (c) 'Computed value' shall mean the value of the undivided
                  interests in the Property as assessed by a comparative market
                  analysis performed by a licensed realtor selected and agreed
                  to by the Tenants in Common. Such computation shall be binding
                  and conclusive upon the parties. The computation shall be made
                  in accordance with generally accepted practices consistently
                  applied in the area.
                </p>
                <p>Now, therefore, the parties agree as follows:</p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Transfer Restriction</b>
                <p>
                  Each of the Tenants in Common will not dispose, transfer,
                  devise, convey, lease, mortgage, or otherwise encumber his
                  undivided interest in the Property, without the written
                  consent of the other Tenants in Common, whose consent shall
                  not be unreasonably withheld.
                </p>
                <p>
                  (a) Upon the death of a Tenant in Common, his or her undivided
                  interest may be transferred to a 'related individual' or to
                  'related individuals' upon such terms as may be agreed upon
                  with the executor or administrator of the deceased Tenant's
                  estate or as may be provided in his will or in a separate
                  agreement duly signed by the Tenant. Notwithstanding any such
                  transfer to a related individual, the transferee shall not
                  have any rights in and to the Property except through the
                  transferor Tenant.
                </p>
                <p>
                  (b) In the event that no transfer is made as provided in
                  subparagraph (a) after the appointment of an executor or
                  administrator of the deceased Tenant's estate, then the
                  executor or administrator shall offer the undivided interest
                  for sale to the other Tenants in Common at the 'computed
                  value.' Each Tenant in Common shall have the right to purchase
                  a 'proportionate share' of the undivided interest. Payment for
                  the undivided interest shall be made within 30 days after the
                  determination of the 'computed value' unless arrangements for
                  deferred payment are agreed upon. In the event that the
                  undivided interest of the seller is not purchased by the other
                  Tenants in Common, then the undivided interest shall be free
                  of all restrictions under this agreement.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Transfer to Related Individual</b>
                <p>
                  Each of the Tenants in Common shall have the right to transfer
                  to a 'related individual' or to 'related individuals' a share
                  or all of his/her undivided interest in the Property, in which
                  event the party making the transfer shall deliver to the other
                  parties to this agreement within 30 days of the making of the
                  transfer, a copy of each and every instrument providing for
                  the transfer. In the event of such transfer, the other parties
                  to this agreement need not consult with or seek the approval
                  of the transferee, and in all matters the consent of the
                  transferor Tenant shall be binding and conclusive upon all
                  such transferees.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Partition</b>
                <p>
                  Each of the Tenants in Common irrevocably waives any and all
                  right that he may have to maintain any action for partition
                  with respect to his undivided interest in the Property or to
                  compel any sale thereof under any applicable laws now existing
                  or subsequently enacted.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Improvement of Property</b>
                <p>
                  Any improvement, sale, or mortgaging of the Property may be
                  made only of the entire Property and then only upon the
                  written consent of all Tenants in Common. However, if two (2)
                  of the Tenants in Common vote to improve, sell or mortgage the
                  Property, said two (2) may acquire the interest of the
                  remaining Tenant in Common by paying him the computed value.
                  Each of the Tenants in Common shall from time to time advance
                  or cause to be advanced, on a pro rata basis, moneys to a
                  common fund as such moneys shall from time to time be required
                  to pay for the maintenance and operation of the Property,
                  including interest and principal on mortgage liens, and for
                  the cost of any improvement. Should any Tenant in Common for
                  any reason fail or refuse to advance or cause to be advanced
                  his pro rata share of moneys required, then and in that event,
                  the other Tenants or Tenant in Common, as the case may be,
                  shall have the right to acquire the interest of the delinquent
                  Tenant in Common at computed value. In the computation for
                  this purpose the value of any real estate and interests in
                  real estate, including mortgages and leasehold improvements,
                  shall be the book value as shown on the regular books of
                  account without any adjustment for any agreed or appraised
                  value.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Profit and loss</b>
                <p>
                  The profits and losses from the operation of the Property
                  shall be shared by the Tenants in Common in accordance with
                  their ownership interest.
                </p>
              </td>
            </tr>
          </table>
          <table>
            {filteredPartners.length > 0 &&
            Array.isArray(filteredPartners[0]) ? (
              filteredPartners[0].map((item, index) => (
                <tr key={index}>
                  <p key={index}>
                    <strong>Partner {index + 1} is </strong>{" "}
                    <b>{item.tcg_fullname}</b>,<br></br>
                    <strong>Date: {getCurrentDate()}</strong>.
                  </p>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No matching data found.</td>
              </tr>
            )}
          </table>
        </div>
      )}
      {ref.current === "4" && (
        <div>
          {" "}
          <table>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontStyle: "italic",
                  width: "800px",
                }}
              >
                <h2>Document 4 Agreement</h2>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td>
                <p>
                  THIS AGREEMENT, made on this {getCurrentDate()}, by and among
                </p>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tbody>
              {filteredPartners.length > 0 &&
              Array.isArray(filteredPartners[0]) ? (
                filteredPartners[0].map((item, index) => (
                  <tr key={index}>
                    <p key={index}>
                      <strong>Partner {index + 1} is </strong>{" "}
                      <b>{item.tcg_fullname}</b>, located at{" "}
                      <b>{item.tcg_address}</b>, with a percentage of{" "}
                      <strong>{item.tcg_persant}%</strong>.
                    </p>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No matching data found.</td>
                </tr>
              )}
            </tbody>
          </table>
          <table>
            <tr>
              <td>
                <p>
                  hereinafter referred to singularly as Tenant and collectively
                  referred to as the 'Tenants in Common'.
                </p>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  width: "800px",
                }}
              >
                <h3>Recitals</h3>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td>
                <p>
                  The Tenants in Common have jointly acquired the fee title to
                  the property described below (the 'Property').
                </p>
              </td>
            </tr>
          </table>
          <br />
          <b>The Property is legally described as follows: </b>
          <br />
          <tbody>
            {filteredPropertyAdd.length ? (
              filteredPropertyAdd.map((item, index) => (
                <tr key={index}>
                  <td>
                    <b>{item.pro_address}</b>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No matching data found.</td>
              </tr>
            )}
          </tbody>
          <br />
          <table>
            <tr>
              <td>
                <p>
                  Each of the Tenants in Common has an equal and undivided
                  interest in the Property as follows:{" "}
                </p>
              </td>
            </tr>
          </table>
          {filteredPartners.length ? (
            filteredPartners.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.tcg_fullname}</td>
                <td>{item.tcg_persant}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No matching data found.</td>
            </tr>
          )}
          <table>
            <tr>
              <td>
                <p>
                  The parties have agreed to limitations on the right and power
                  to transfer their undivided interests.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Definitions of terms</b>
                <p>
                  The following terms shall have the meanings and definitions as
                  set forth below:
                </p>
                <p>
                  (a) 'Related individual' or 'related individuals' shall mean
                  brothers, sisters, spouses, lineal descendants, spouses of
                  lineal descendants, trustees for the benefit of any such
                  persons, and corporations owned or controlled by any of the
                  foregoing.
                </p>
                <p>
                  (b) 'Proportionate share' shall mean that portion of the
                  undivided interest in the Property offered for sale which the
                  undivided interests owned by a Tenant in Common and his
                  'related individuals' bears to all of the undivided interests
                  in the Property, other than that offered for sale.
                </p>
                <p>
                  (c) 'Computed value' shall mean the value of the undivided
                  interests in the Property as assessed by a comparative market
                  analysis performed by a licensed realtor selected and agreed
                  to by the Tenants in Common. Such computation shall be binding
                  and conclusive upon the parties. The computation shall be made
                  in accordance with generally accepted practices consistently
                  applied in the area.
                </p>
                <p>Now, therefore, the parties agree as follows:</p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Transfer Restriction</b>
                <p>
                  Each of the Tenants in Common will not dispose, transfer,
                  devise, convey, lease, mortgage, or otherwise encumber his
                  undivided interest in the Property, without the written
                  consent of the other Tenants in Common, whose consent shall
                  not be unreasonably withheld.
                </p>
                <p>
                  (a) Upon the death of a Tenant in Common, his or her undivided
                  interest may be transferred to a 'related individual' or to
                  'related individuals' upon such terms as may be agreed upon
                  with the executor or administrator of the deceased Tenant's
                  estate or as may be provided in his will or in a separate
                  agreement duly signed by the Tenant. Notwithstanding any such
                  transfer to a related individual, the transferee shall not
                  have any rights in and to the Property except through the
                  transferor Tenant.
                </p>
                <p>
                  (b) In the event that no transfer is made as provided in
                  subparagraph (a) after the appointment of an executor or
                  administrator of the deceased Tenant's estate, then the
                  executor or administrator shall offer the undivided interest
                  for sale to the other Tenants in Common at the 'computed
                  value.' Each Tenant in Common shall have the right to purchase
                  a 'proportionate share' of the undivided interest. Payment for
                  the undivided interest shall be made within 30 days after the
                  determination of the 'computed value' unless arrangements for
                  deferred payment are agreed upon. In the event that the
                  undivided interest of the seller is not purchased by the other
                  Tenants in Common, then the undivided interest shall be free
                  of all restrictions under this agreement.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Transfer to Related Individual</b>
                <p>
                  Each of the Tenants in Common shall have the right to transfer
                  to a 'related individual' or to 'related individuals' a share
                  or all of his/her undivided interest in the Property, in which
                  event the party making the transfer shall deliver to the other
                  parties to this agreement within 30 days of the making of the
                  transfer, a copy of each and every instrument providing for
                  the transfer. In the event of such transfer, the other parties
                  to this agreement need not consult with or seek the approval
                  of the transferee, and in all matters the consent of the
                  transferor Tenant shall be binding and conclusive upon all
                  such transferees.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Partition</b>
                <p>
                  Each of the Tenants in Common irrevocably waives any and all
                  right that he may have to maintain any action for partition
                  with respect to his undivided interest in the Property or to
                  compel any sale thereof under any applicable laws now existing
                  or subsequently enacted.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Improvement of Property</b>
                <p>
                  Any improvement, sale, or mortgaging of the Property may be
                  made only of the entire Property and then only upon the
                  written consent of all Tenants in Common. However, if two (2)
                  of the Tenants in Common vote to improve, sell or mortgage the
                  Property, said two (2) may acquire the interest of the
                  remaining Tenant in Common by paying him the computed value.
                  Each of the Tenants in Common shall from time to time advance
                  or cause to be advanced, on a pro rata basis, moneys to a
                  common fund as such moneys shall from time to time be required
                  to pay for the maintenance and operation of the Property,
                  including interest and principal on mortgage liens, and for
                  the cost of any improvement. Should any Tenant in Common for
                  any reason fail or refuse to advance or cause to be advanced
                  his pro rata share of moneys required, then and in that event,
                  the other Tenants or Tenant in Common, as the case may be,
                  shall have the right to acquire the interest of the delinquent
                  Tenant in Common at computed value. In the computation for
                  this purpose the value of any real estate and interests in
                  real estate, including mortgages and leasehold improvements,
                  shall be the book value as shown on the regular books of
                  account without any adjustment for any agreed or appraised
                  value.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Profit and loss</b>
                <p>
                  The profits and losses from the operation of the Property
                  shall be shared by the Tenants in Common in accordance with
                  their ownership interest.
                </p>
              </td>
            </tr>
          </table>
          <table>
            {filteredPartners.length > 0 &&
            Array.isArray(filteredPartners[0]) ? (
              filteredPartners[0].map((item, index) => (
                <tr key={index}>
                  <p key={index}>
                    <strong>Partner {index + 1} is </strong>{" "}
                    <b>{item.tcg_fullname}</b>,<br></br>
                    <strong>Date: {getCurrentDate()}</strong>.
                  </p>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No matching data found.</td>
              </tr>
            )}
          </table>
        </div>
      )} */}

    </>
  )
})

export default TenantsInCommonAgreement
