import React from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Container,
  Form,
  FormFeedback,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import DoctypeTable from "components/DataTables/DoctypeTable"

const DocTypeList = () => {
  // Form validation
  const Region = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      city: "City",
      state: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter Your First Name"),
      lastname: Yup.string().required("Please Enter Your Last Name"),
      city: Yup.string().required("Please Enter Your City"),
      state: Yup.string().required("Please Enter Your State"),
      zip: Yup.string().required("Please Enter Your Zip"),
    }),
    onSubmit: values => {
      console.log("values", values)
    },
  })

  document.title = "DOCUMENT TYPE LIST | SOF"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <DoctypeTable />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DocTypeList

