import React from 'react';
import classNames from 'clsx';
import styles from './FamilyNode.module.css';

export default function FamilyNode({ node, isRoot, style }) {
  const effectiveRoot = isRoot || !node.parentId;

  return node.holucost !== 0 ? (
    <div
      className={`${styles.root}`}
      style={style}
    >
      <div
        className={classNames(
          styles[node.DeathType],
          styles.inner,
          styles[node.gender],
          effectiveRoot && styles.isRoot
        )}
      >
        <div className={styles.content}>
          <h4 className={styles.name}>{node.relation}</h4>
          {node.profilePic ? (
            <div className={styles.imageContainer}>
              <img
                src={node.profilePic}
                alt={node.displayName || 'Profile Picture'}
                className={styles.circularImage}
              />
            </div>
          ) : (
            <div className={styles.imageContainer}>
              <img
                src="/default-profile.png"
                className={styles.circularImage}
              />
            </div>
          )}
          <div className={styles.dates}>
            <div>Name: {node.displayName}</div>
            <div>Associated: {node.associated}</div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
