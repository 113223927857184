import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  Container,
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import "../../../../src//styles/errorfield.scss"
import { FileUpload } from "helpers/file_uploads/upload_handler"
import { loginUserData, userId } from "helpers/userId"

const initialFormDetails = {
  dl_docName: "",
  dl_docType: "",
  dl_docSubtype: "",
  dl_file: "",
  regionsList: [],
  DoctypeList: [],
}

const AddDocuments = () => {
  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  const [errors, setErrors] = useState({})

  // -- Fetch relationships list start -- //
  async function fetchDoctypeList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/doctype_list`,
      )
      const data = await response.json()
      setForm({ ...form, DoctypeList: data?.data || [] })

      return data?.data
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchDoctypeList()
  }, [])

  async function fetchRegionsList(countryName) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`,
      )

      const data = await response.json()
      setForm({
        ...form,
        regionsList: data?.data || [],
        dl_region: data.data[0]?.dl_region || "",
        dl_country: countryName,
      })

      return data?.data
    } catch (error) {
      console.log(error)
    }
  }

  // console.log("Documenrts FOrm", form)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  const [isUserValidated, setIsUserValidated] = useState(false)
  const [userKayVal, setUserKayVal] = useState("")
  const [displayImg, setDisplayImg] = useState({})
  const [docTypeCount, setDocTypeCount] = useState({})
  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }
  const tog_small = () => {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const tog_xlarge = () => {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }
  const handleUserKay = () => {
    if (userKayVal && userKayVal === loginUserData.user_key) {
      setIsUserValidated(false)
      tog_xlarge()
      tog_small()
    } else {
      setIsUserValidated(true)
    }
    setUserKayVal("")
  }

  const displayModel = (title, url) => {
    const fileType = /[^.]+$/.exec(url);
    const isImage = ["jpg", "jpeg", "png"].includes(fileType[0]);
  
    let path =
      fileType[0] === "doc" || fileType[0] === "docx"
        ? `https://docs.google.com/gview?url=${url}&embedded=true`
        : url;
  
    setDisplayImg({
      path: path,
      title: title,
      isImage: isImage,
      show: true,
      fileType: fileType,
    });
  
    setmodal_xlarge(true); // Directly open the document modal
  };
  

  // history for navigation
  const history = useNavigate()

  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const type = searchParams.get("type")

  // -- Fetch Document Vault list start -- //
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/single_documents?dl_id=${id}`,
      )
      const data = await response.json()

      if (data?.success) {
        const acData = data?.data[0]

        const DoctypeList = await fetchDoctypeList()
        const regionData = await fetchRegionsList(acData.dl_country)

        const selectedDocType = DoctypeList.find(
          doc => doc.dt_docName === acData.dl_docType,
        )

        const selectedRegion = regionData.find(
          i => i.region === acData.dl_region,
        )

        setForm({
          ...form,
          dl_docName: acData.dl_docName,
          dl_docType: selectedDocType ? selectedDocType.dt_docName : "",
          dl_docId: acData.dl_docId,
          dl_docSubtype: acData.dl_docSubtype,
          dl_country: acData.dl_country,
          dl_region: selectedRegion ? selectedRegion.region : "",
          dl_tags: acData.dl_tags,
          dl_upload_date: acData.dl_upload_date,
          dl_docOwner: acData.dl_docOwner,
          dl_version: acData.dl_version,
          dl_file: acData.dl_file,
          dl_active: acData.dl_active,
          DoctypeList,
          regionsList: regionData.length ? regionData : [],
          // dl_docType: DoctypeList.length ? DoctypeList : [],
          // dl_region: regionData.length ? regionData : [],
        })
      }
    } catch (err) {
      history("/documents_list")
    }
  }
  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [])

  // -- Fetch Document Vault list end -- //

  const handleForm = e => {
    let { name, value } = e.target

    if (name === "dl_country") {
      fetchRegionsList(value)
    } else {
      setForm({ ...form, [name]: value })
    }

    if (name === "dl_docType") {
      // Handle the doc type change logic here
      handleDocTypeChange(value)
    }
  }

  // const handleDocTypeChange = async (docType) => {
  //   if (docType) {

  //     const getAllDoc = await fetch(
  //       `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_documents`,
  //     )
  //     const getAllDocData = await getAllDoc.json()

  //     console.log(getAllDocData)

  //     const existingDoc = getAllDocData.map((doc)=>{
  //       //doc.dl_docType && docType.slice(0, 3).toUpperCase() === docTypePrefix;
  //       alert()
  //     })

  //     const docTypePrefix = docType.slice(0, 3).toUpperCase();

  //     setDocTypeCount((prevCount) => {
  //       const newCount = prevCount[docTypePrefix] ? prevCount[docTypePrefix] + 1 : 1;
  //       const newDocId = `${docTypePrefix}${String(newCount).padStart(3, "0")}`;
  //       setForm((prevState) => ({
  //         ...prevState,
  //         dl_docId: newDocId,
  //       }));
  //       return {
  //         ...prevCount,
  //         [docTypePrefix]: newCount,
  //       };
  //     });
  //   }
  // };

  const handleDocTypeChange = async docType => {
    if (docType) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_documents`,
        )

        const getAllDocData = await response.json()

        const docTypePrefix = docType.slice(0, 3).toUpperCase()
        const existingDocs = getAllDocData.data.filter(
          doc => doc.dl_docType?.slice(0, 3).toUpperCase() === docTypePrefix,
        )

        const newCount = existingDocs.length + 1

        const newDocId = `${docTypePrefix}${String(newCount).padStart(3, "0")}`
        setForm(prevState => ({
          ...prevState,
          dl_docId: newDocId,
        }))

        // Update the doc type count
        setDocTypeCount(prevCount => ({
          ...prevCount,
          [docTypePrefix]: newCount,
        }))
      } catch (error) {
        console.error("Error fetching documents:", error)
      }
    }
  }

  const handleImage = async e => {
    const { name, files } = e.target
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ]

    if (files && files.length > 0) {
      const file = files[0]

      if (!allowedTypes.includes(file.type)) {
        showMessage(
          "Only images (PNG, JPEG), PDFs, and Word documents are allowed.",
          "error",
        )
        return
      }

      try {
        const result = await FileUpload(files)
        setForm({ ...form, [name]: result.files?.[0]?.url })
      } catch (error) {
        showMessage("File upload failed:", "error")
      }
    }
  }

  const validateForm = () => {
    var isValid = true
    const newErrors = {}

    // Validation logic for each input
    if (!form.dl_docName.trim()) {
      newErrors.dl_docName = "Docunent Name is required"
      isValid = false
    }

    setErrors(newErrors)
    return isValid
  }

  const handleFormSubmit = async () => {
    validateForm()
    if (!!form.dl_docName) {
      try {
        setApiStatus({ ...apiStatus, inProgress: true })
        const url = id
          ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/update_documents`
          : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/add_documents`

        const loginUser =
          loginUserData.user_fname + " " + loginUserData.user_lname

        const document_id = form.dl_docId

        const data = {
          dl_id: id || 0,
          dl_docName: form.dl_docName,
          dl_docId: document_id,
          dl_docType: form.dl_docType,
          dl_docSubtype: form.dl_docSubtype,
          dl_country: form.dl_country,
          dl_region: form.dl_region,
          dl_tags: form.dl_tags,
          dl_upload_date: form.dl_upload_date,
          dl_version: form.dl_version,
          dl_file: form.dl_file,
          dl_active: form.dl_active,
          dl_docOwner: userId,
        }
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})

        const addOrUpdateDocuments = await response.json()

        if (addOrUpdateDocuments?.success === true) {
          showMessage(addOrUpdateDocuments.message, "success")
          setTimeout(() => {
            type == 1 ? history("/documents_list") : history("/documents_list")
          }, 3000)
        } else {
          showMessage(addOrUpdateDocuments?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (e) {
        showMessage(e, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    }
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">ADD DOCUMENTS</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/documents_list">Operation</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/documents_list">Documents</Link>
              </li>
            </ol>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="dl_docName" className="form-label">
                      Document Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="dl_docName"
                      placeholder="Document Name"
                      required
                      onChange={handleForm}
                      value={form.dl_docName}
                    />
                    {errors.dl_docName && (
                      <div className="invalid-input">{errors.dl_docName}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="dl_docType" className="form-label">
                      Document Type
                    </label>
                    <select
                      className="form-select"
                      name="dl_docType"
                      id="dl_docType"
                      value={form.dl_docType || ""}
                      onChange={handleForm}
                    >
                      {form.DoctypeList.length === 0 ? (
                        <option value="">Select Doc Type</option>
                      ) : (
                        <>
                          <option value="" defaultValue>
                            Select Doc Type
                          </option>
                          {form.DoctypeList.map(each => (
                            <option
                              value={each.dt_docName}
                              key={each.dt_docName}
                            >
                              {each.dt_docName}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="dl_docSubtype" className="form-label">
                      Document Sub Type
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="dl_docSubtype"
                      placeholder="Document Sub Type"
                      onChange={handleForm}
                      value={form.dl_docSubtype}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <select
                      className="form-select"
                      name="dl_country"
                      id="country"
                      value={form.dl_country}
                      onChange={handleForm}
                    >
                      <option value="" defaultValue>
                        Select Country
                      </option>
                      <option value="USA">USA</option>
                      <option value="INDIA">INDIA</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="dl_region" className="form-label">
                      Region
                    </label>
                    <select
                      className="form-select"
                      name="dl_region"
                      id="dl_region"
                      value={form.dl_region || ""}
                      onChange={handleForm}
                    >
                      {form.regionsList.length === 0 ? (
                        <option value="">Select Region</option>
                      ) : (
                        <>
                          <option value="" defaultValue>
                            Select Region
                          </option>

                          {form.regionsList.map(each => (
                            <option value={each.region} key={each.region}>
                              {each.region}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="dl_tags" className="form-label">
                      Document Tags
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="dl_tags"
                      placeholder="Document Tags"
                      onChange={handleForm}
                      value={form.dl_tags}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="dl_upload_date" className="form-label">
                      Date Uploaded
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="dl_upload_date"
                      placeholder="Date Uploaded"
                      required
                      onChange={handleForm}
                      value={form.dl_upload_date}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="dl_version" className="form-label">
                      Version
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="dl_version"
                      placeholder="Version"
                      onChange={handleForm}
                      value={form.dl_version}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="dl_active" className="form-label">
                      Document Status
                    </label>
                    <select
                      className="form-select"
                      name="dl_active"
                      value={form.dl_active}
                      onChange={handleForm}
                    >
                      <option value="" defaultValue>
                        Select Status
                      </option>
                      <option value="Active">Active</option>
                      <option value="InActive">InActive</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label
                      htmlFor="validationTooltipUsername"
                      className="form-label"
                    >
                      Document
                    </label>
                    <div className="input-group">
                      <input
                        type="file"
                        name="dl_file"
                        accept="image/*,.pdf,.doc,.docx"
                        className="upload"
                        id="dl_file"
                        style={{ display: "none" }}
                        onChange={handleImage}
                      />
                      <label htmlFor="dl_file">
                        <p className="btn btn-info">Upload Document </p>
                      </label>
                    </div>
                  </div>
                </div>
                {id ? (
                  <div className="col-md-4">
                    <div className="mb-3 position-relative">
                      <label htmlFor="dl_docId" className="form-label">
                        Document ID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="dl_docId"
                        placeholder="Version"
                        onChange={handleForm}
                        value={form.dl_docId}
                        readOnly
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              {/* {id ? (
                <Button
                  color="primary"
                  onClick={() => displayModel(form.dl_docName, form.dl_file)}
                >
                  View document
                </Button>
              ) : null}
              <Modal isOpen={modal_small} toggle={tog_small} size="sm">
                <ModalHeader className="mt-0" toggle={tog_small}>
                  Please enter "User Key" to open file
                </ModalHeader>
                <ModalBody>
                  <p>
                    Note: Please find your "User Key" in personal information
                    section
                  </p>
                  <p>
                    <input
                      type="userKay"
                      className="form-control"
                      name="userKay"
                      id="userKay"
                      value={userKayVal}
                      onChange={e => setUserKayVal(e.target.value)}
                      onBlur={e => setIsUserValidated(false)}
                    />
                  </p>
                  <p>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleUserKay}
                    >
                      Verify User Key
                    </button>
                  </p>
                  {isUserValidated && (
                    <p className="alert alert-danger ">
                      Please enter valid "User Key" value.
                    </p>
                  )}
                </ModalBody>
              </Modal>
              {displayImg.show && (
                <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="xl">
                  <ModalHeader className="mt-0" toggle={tog_xlarge}>
                    {displayImg.title}
                  </ModalHeader>
                  <ModalBody>
                    {displayImg.isImage ? (
                      <img src={displayImg.path} width="100%"></img>
                    ) : (
                      <iFrame
                        src={displayImg.path}
                        width="100%"
                        height="650px"
                      ></iFrame>
                    )}
                  </ModalBody>
                </Modal>
              )} */}

              {id ? (
                <Button
                  color="primary"
                  onClick={() => displayModel(form.dl_docName, form.dl_file)}
                >
                  View document
                </Button>
              ) : null}

              {/* Direct Modal to display document */}
              {displayImg.show && (
                <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="xl">
                  <ModalHeader className="mt-0" toggle={tog_xlarge}>
                    {displayImg.title}
                  </ModalHeader>
                  <ModalBody>
                    {displayImg.isImage ? (
                      <img src={displayImg.path} width="100%"></img>
                    ) : (
                      <iFrame
                        src={displayImg.path}
                        width="100%"
                        height="650px"
                      ></iFrame>
                    )}
                  </ModalBody>
                </Modal>
              )}




            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {apiStatus.inProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      disabled={true}
                    >
                      <Spinner color="light" size="sm">
                        Loading ...
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      onClick={handleFormSubmit}
                    >
                      {id ? "Save" : "Submit"}
                    </button>
                  )}
                  &nbsp; &nbsp;
                  <Link
                    to={type == 1 ? "/documents_list" : "/documents_list"}
                    className="btn btn-secondary waves-effect"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddDocuments
