import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  Label,
  Button,
  Input,
} from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

const WhatsappOtpLogin = () => {
  document.title = "WhatsApp OTP Login";

  const [apiStatus, setApiStatus] = useState({ inProgress: false });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const navigate = useNavigate();

  const sendOtp = async () => {
    if (!phoneNumber || phoneNumber.length !== 10) {
      setStatus("Please enter a valid 10-digit phone number.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/send_whatsapp_otp`,
        { phone_number: phoneNumber }
      );

      if (response?.data?.success) {
        setOtpSent(true);
        setStatus("OTP sent to your WhatsApp number.");
      } else {
        setStatus("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      setStatus("Error sending OTP. Please try again.");
      console.error("Send OTP error:", error);
    } finally {
      setLoading(false);
    }
  };

  const verifyOtp = async () => {
    if (!otp || otp.length !== 6) {
      setStatus("Please enter a valid 6-digit OTP.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/verify_whatsapp_otp`,
        { phone_number: phoneNumber, otp }
      );

      if (response?.data?.success) {
        const userData = response.data.data;
        localStorage.setItem("loginAt", new Date());
        localStorage.setItem("userData", JSON.stringify(userData));

        navigate("/dashboard");
      } else {
        setStatus("Invalid OTP. Please try again.");
      }
    } catch (error) {
      setStatus("Error verifying OTP. Please try again.");
      console.error("Verify OTP error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setStatus("");
  }, [phoneNumber, otp]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block"></div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 p-2">WhatsApp OTP Login</h5>
                  </div>
                </div>
                <CardBody className="p-4">
                  {status && (
                    <Alert color={otpSent ? "info" : "danger"} className="mt-3">
                      {status}
                    </Alert>
                  )}

                  {!otpSent ? (
                    <div className="mb-3">
                      <Label className="form-label">Enter Phone Number</Label>
                      <Input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        maxLength={10}
                        placeholder="Enter 10-digit WhatsApp number"
                        disabled={loading}
                      />
                      <Button
                        color="primary"
                        className="mt-3 w-100"
                        onClick={sendOtp}
                        disabled={loading}
                      >
                        {loading ? "Sending OTP..." : "Send OTP"}
                      </Button>
                    </div>
                  ) : (
                    <div className="mb-3">
                      <Label className="form-label">Enter OTP</Label>
                      <Input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        maxLength={6}
                        placeholder="Enter 6-digit OTP"
                        disabled={loading}
                      />
                      <Button
                        color="primary"
                        className="mt-3 w-100"
                        onClick={verifyOtp}
                        disabled={loading}
                      >
                        {loading ? "Verifying OTP..." : "Verify OTP"}
                      </Button>
                    </div>
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember it?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    Sign In here
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()} SOF. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WhatsappOtpLogin;
