import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const InvestmentAmountTable = () => {
  const [invoiceInformation, setInvoiceInformation] = useState({ data: [] })
  const [isLoading, setIsLoading] = useState(false)

  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const banksyncid = parseInt(JSON.parse(localStorage.getItem("banksyncid")))

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_banksynk_amount_list?bs_id=${id || banksyncid}`
      )
      const data = await response.json()
      setInvoiceInformation({ ...invoiceInformation, data: data?.data || [] })
      } catch (error) {
              console.error("Error fetching asset wallet data:", error)
              showMessage(error.message || "Failed to load data", "error", false)
            } finally {
              setIsLoading(false)
            }   

    }
    fetchData()
  }, [])

  

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
    ],
    rows: invoiceInformation?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,    
        amount: each?.bsa_amount || "",
        date: each?.bsa_date || "",
             
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
      <Col className="col-12">
          <Card>
            <CardBody>
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    noBottomColumns={true}
                  />
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default InvestmentAmountTable