import CustomerLevelList from "components/DataTables/CustomerLevelList"
import React, { useEffect } from "react"
import { Container } from "reactstrap"
import { userId, loginUserData } from "helpers/userId"
const CustomerLevelsList = () => {
  useEffect(() => {
    localStorage.removeItem("tab")
    localStorage.removeItem("qrId")
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">Customer Level</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Operation</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Customer Level</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <CustomerLevelList />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustomerLevelsList
