import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  Container,
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import "../../../../src//styles/errorfield.scss"
import { FileUpload } from "helpers/file_uploads/upload_handler"
import { loginUserData, userId } from "helpers/userId"
import { useHistory } from "react-router-dom"
const initialFormDetails = {
  dl_docName: "",
  dl_docType: "",
  dl_docSubtype: "",
  st_file: "",
  regionsList: [],
  SprintToolUsersList: [],
}

const AddSprintTool = () => {
  const [form, setForm] = useState({
    st_sprint_name: "",
    st_description: "",
    st_story_owner: "",
    st_story_status: "",
    st_priority: "",
    st_task_status: "",
    st_percentage_complete: "",
    st_due_date: "",
    st_closed_date: "",
    st_comment: "",
    st_file: "",
    SprintToolUsersList: [],
  })
  const [apiStatus, setApiStatus] = useState({ inProgress: false })
  const [errors, setErrors] = useState({})
  const [changed, setChanged] = useState(undefined)

  // -- Fetch relationships list start -- //
  async function fetchSprinttoolUserList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_sprint_tool_users_list`,
      )
      const data = await response.json()
      setForm({ ...form, SprintToolUsersList: data?.data || [] })

      return data?.data
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchSprinttoolUserList()
  }, [])

  // console.log("Documenrts FOrm", form)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  const [isUserValidated, setIsUserValidated] = useState(false)
  const [userKayVal, setUserKayVal] = useState("")
  const [displayImg, setDisplayImg] = useState({})
  const [docTypeCount, setDocTypeCount] = useState({})
  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }
  const tog_small = () => {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const tog_xlarge = () => {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }
  const handleUserKay = () => {
    if (userKayVal && userKayVal === loginUserData.user_key) {
      setIsUserValidated(false)
      tog_xlarge()
      tog_small()
    } else {
      setIsUserValidated(true)
    }
    setUserKayVal("")
  }

  const displayModel = (title, url) => {
    const fileType = /[^.]+$/.exec(url)
    const isImage = ["jpg", "jpeg", "png"].includes(fileType[0])

    let path =
      fileType[0] === "doc" || fileType[0] === "docx"
        ? `https://docs.google.com/gview?url=${url}&embedded=true`
        : url

    setDisplayImg({
      path: path,
      title: title,
      isImage: isImage,
      show: true,
      fileType: fileType,
    })

    setmodal_xlarge(true) // Directly open the document modal
  }

  // history for navigation
  const history = useNavigate()

  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const type = searchParams.get("type")

  // -- Fetch Document Vault list start -- //
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/single_sprint_tool?st_id=${id}`,
      )

      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`)
      }

      const data = await response.json()

      if (data?.success) {
        const acData = data?.data[0]
        const SprintToolUsersList = await fetchSprinttoolUserList()

        const selectedSprintToolStoryUser = SprintToolUsersList.find(
          Sowner => Sowner.stu_uid === acData.st_story_owner,
        )

        console.log(acData)

        setForm({
          ...form,
          st_sprint_name: acData.st_sprint_name,
          st_description: acData.st_description,
          st_story_owner: selectedSprintToolStoryUser
            ? selectedSprintToolStoryUser.stu_username
            : "",
          st_story_status: acData.st_story_status,
          st_priority: acData.st_priority,
          st_task_status: acData.st_task_status,
          st_percentage_complete: acData.st_percentage_complete,
          st_due_date: acData.st_due_date,
          st_closed_date: acData.st_closed_date,
          st_comment: acData.st_comment,
          st_file: acData.st_file,
          SprintToolUsersList,
        })
      } else {
        throw new Error("Data fetching was not successful")
      }
    } catch (err) {
      history("/sprint_list")
    }
  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [id])

  // -- Fetch Document Vault list end -- //

  // const handleForm = e => {
  //   let { name, value } = e.target

  //   setForm({ ...form, [name]: value })

  // }

  const handleForm = e => {
    const { name, value } = e.target
    setForm(prevForm => ({
      ...prevForm,
      [name]: value,
    }))
  }

  useEffect(() => {
    if (form.SprintToolUsersList.length > 0 && !form.st_story_owner) {
      setForm(prev => ({
        ...prev,
        st_story_owner: form.SprintToolUsersList[0].stu_uid, // Set a default if necessary
      }));
    }
  }, [form.SprintToolUsersList]);

  const handleImage = async e => {
    const { name, files } = e.target
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ]

    if (files && files.length > 0) {
      const file = files[0]

      if (!allowedTypes.includes(file.type)) {
        showMessage(
          "Only images (PNG, JPEG), PDFs, and Word documents are allowed.",
          "error",
        )
        return
      }

      try {
        const result = await FileUpload(files)
        setForm({ ...form, [name]: result.files?.[0]?.url })
      } catch (error) {
        showMessage("File upload failed:", "error")
      }
    }
  }

  const validateForm = () => {
    var isValid = true
    const newErrors = {}

    setErrors(newErrors)
    return isValid
  }

  const handleFormSubmit = async () => {
    validateForm()
    if (!!form.st_sprint_name) {
      try {
        setApiStatus({ ...apiStatus, inProgress: true })
        const url = id
          ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/update_sprint_tool`
          : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/add_sprint_tool`

        const loginUser =
          loginUserData.user_fname + " " + loginUserData.user_lname

        const data = {
          st_id: id || 0,
          st_sprint_name: form.st_sprint_name,
          st_description: form.st_description,
          st_story_owner: form.st_story_owner,
          st_story_status: form.st_story_status,
          st_priority: form.st_priority,
          st_task_status: form.st_task_status,
          st_percentage_complete: form.st_percentage_complete,
          st_due_date: form.st_due_date,
          st_closed_date: form.st_closed_date,
          st_comment: form.st_comment,
          st_file: form.st_file,
        }
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})

        const addOrUpdateDocuments = await response.json()

        if (addOrUpdateDocuments?.success === true) {
          showMessage(addOrUpdateDocuments.message, "success")
          setTimeout(() => {
            type == 1 ? history("/sprint_list") : history("/sprint_list")
          }, 3000)
        } else {
          showMessage(addOrUpdateDocuments?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (e) {
        showMessage(e, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    }
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">ADD SPRINT TOOL</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/sprint_list">Operation</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/sprint_list">Sprint Tool</Link>
              </li>
            </ol>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="st_sprint_name" className="form-label">
                      Sprint Name <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="st_sprint_name"
                      id="st_sprint_name"
                      value={form.st_sprint_name}
                      onChange={handleForm}
                    >
                      <option value="" defaultValue>
                        Select Sprint Names
                      </option>
                      <option value="Current">Current</option>
                      <option value="Backlog">Backlog</option>                      
                    </select>                    
                    {errors.st_sprint_name && (
                      <div className="invalid-input">
                        {errors.st_sprint_name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="st_description" className="form-label">
                      Description <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="st_description"
                      placeholder="Description"
                      required
                      onChange={handleForm}
                      value={form.st_description}
                    />
                    {errors.st_description && (
                      <div className="invalid-input">
                        {errors.st_description}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="st_story_owner" className="form-label">
                      Story Owner
                    </label>
                    <select
                      className="form-select"
                      name="st_story_owner"
                      id="st_story_owner"
                      value={form.st_story_owner || ""}
                      onChange={handleForm}
                    >
                      <option value="" defaultValue>
                        Select Story Owner
                      </option>
                      {form.SprintToolUsersList.map(each => (
                        <option value={each.stu_uid} key={each.stu_uid}>
                          {each.stu_username}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="st_story_status" className="form-label">
                      Story Status
                    </label>
                    <select
                      className="form-select"
                      name="st_story_status"
                      id="st_story_status"
                      value={form.st_story_status}
                      onChange={handleForm}
                    >
                      <option value="" defaultValue>
                        Select Status
                      </option>
                      <option value="To Do">To Do</option>
                      <option value="In Progress">In Progress</option>
                      <option value="On Hold">On Hold</option>
                      <option value="Deferred">Deferred</option>
                      <option value="Ready">Ready</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="st_priority" className="form-label">
                      Priority
                    </label>
                    <select
                      className="form-select"
                      name="st_priority"
                      id="st_priority"
                      value={form.st_priority}
                      onChange={handleForm}
                    >
                      <option value="" defaultValue>
                        Select Priority
                      </option>
                      <option value="Very High">Very High</option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="st_task_status" className="form-label">
                      Task Status
                    </label>
                    <select
                      className="form-select"
                      name="st_task_status"
                      id="st_task_status"
                      value={form.st_task_status}
                      onChange={handleForm}
                    >
                      <option value="" defaultValue>
                        Select Task Status
                      </option>
                      <option value="In Development">In Development</option>
                      <option value="Dev Done">Dev Done</option>
                      <option value="In QE">In QE</option>
                      <option value="QE Done">QE Done</option>
                      <option value="UAT Done">UAT Done</option>
                      <option value="All Done">All Done</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="st_task_owner" className="form-label">
                      Task Owner
                    </label>
                    <select
                      className="form-select"
                      name="st_task_owner"
                      id="st_task_owner"
                      value={form.st_task_owner || ""}
                      onChange={handleForm}
                    >
                      {form.SprintToolUsersList.length === 0 ? (
                        <option value="">Select Task Owner</option>
                      ) : (
                        <>
                          <option value="" defaultValue>
                            Select Task Owner
                          </option>
                          {form.SprintToolUsersList.map(each => (
                            <option
                              value={each.stu_uid}
                              key={each.stu_uid}
                            >
                              {each.stu_username}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label
                      htmlFor="st_percentage_complete"
                      className="form-label"
                    >
                      Percentage Complete
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="st_percentage_complete"
                      placeholder="Percentage Complete"
                      onChange={handleForm}
                      value={form.st_percentage_complete}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="st_due_date" className="form-label">
                      Due Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="st_due_date"
                      placeholder="Due Date"
                      required
                      onChange={handleForm}
                      value={form.st_due_date}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="st_closed_date" className="form-label">
                      Closed Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="st_closed_date"
                      placeholder="Closed Date"
                      required
                      onChange={handleForm}
                      value={form.st_closed_date}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="st_comment" className="form-label">
                      Comments
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="st_comment"
                      placeholder="Comments"
                      onChange={handleForm}
                      value={form.st_comment}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label
                      htmlFor="validationTooltipUsername"
                      className="form-label"
                    >
                      Screenshot
                    </label>
                    <div className="input-group">
                      <input
                        type="file"
                        name="st_file"
                        accept="image/*,.pdf,.doc,.docx"
                        className="upload"
                        id="st_file"
                        style={{ display: "none" }}
                        onChange={handleImage}
                      />
                      <label htmlFor="st_file">
                        <p className="btn btn-info">Upload Document </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* {id ? (
                  <Button
                    color="primary"
                    onClick={() => displayModel(form.dl_docName, form.dl_file)}
                  >
                    View document
                  </Button>
                ) : null}
                <Modal isOpen={modal_small} toggle={tog_small} size="sm">
                  <ModalHeader className="mt-0" toggle={tog_small}>
                    Please enter "User Key" to open file
                  </ModalHeader>
                  <ModalBody>
                    <p>
                      Note: Please find your "User Key" in personal information
                      section
                    </p>
                    <p>
                      <input
                        type="userKay"
                        className="form-control"
                        name="userKay"
                        id="userKay"
                        value={userKayVal}
                        onChange={e => setUserKayVal(e.target.value)}
                        onBlur={e => setIsUserValidated(false)}
                      />
                    </p>
                    <p>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleUserKay}
                      >
                        Verify User Key
                      </button>
                    </p>
                    {isUserValidated && (
                      <p className="alert alert-danger ">
                        Please enter valid "User Key" value.
                      </p>
                    )}
                  </ModalBody>
                </Modal>
                {displayImg.show && (
                  <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="xl">
                    <ModalHeader className="mt-0" toggle={tog_xlarge}>
                      {displayImg.title}
                    </ModalHeader>
                    <ModalBody>
                      {displayImg.isImage ? (
                        <img src={displayImg.path} width="100%"></img>
                      ) : (
                        <iFrame
                          src={displayImg.path}
                          width="100%"
                          height="650px"
                        ></iFrame>
                      )}
                    </ModalBody>
                  </Modal>
                )} */}

              {id ? (
                <Button
                  color="primary"
                  onClick={() =>
                    displayModel(form.st_sprint_name, form.st_file)
                  }
                >
                  View document
                </Button>
              ) : null}

              {/* Direct Modal to display document */}
              {displayImg.show && (
                <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="xl">
                  <ModalHeader className="mt-0" toggle={tog_xlarge}>
                    {displayImg.title}
                  </ModalHeader>
                  <ModalBody>
                    {displayImg.isImage ? (
                      <img src={displayImg.path} width="100%"></img>
                    ) : (
                      <iFrame
                        src={displayImg.path}
                        width="100%"
                        height="650px"
                      ></iFrame>
                    )}
                  </ModalBody>
                </Modal>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {apiStatus.inProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      disabled={true}
                    >
                      <Spinner color="light" size="sm">
                        Loading ...
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      onClick={handleFormSubmit}
                    >
                      {id ? "Save" : "Submit"}
                    </button>
                  )}
                  &nbsp; &nbsp;
                  <Link
                    to={type == 1 ? "/sprint_list" : "/sprint_list"}
                    className="btn btn-secondary waves-effect"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddSprintTool
