import React, { useEffect, useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const PositionsListTable = () => {
  const [documentvault, setDocumentVault] = useState({ data: [] })

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const user_id =
  //       JSON.parse(localStorage.getItem("userData")).user.user_id || 0
  //     const response = await fetch(
  //       `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_position_info`
  //     )
  //     const data = await response.json()
  //     setDocumentVault({ ...documentvault, data: data?.data || [] })
  //   }
  //   fetchData()
  // }, [])

  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController()
      try {
        const userData = JSON.parse(localStorage.getItem("userData"))
        const user_id = userData?.user?.user_id || 0
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_position_info`,
          { signal: abortController.signal },
        )

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()

        setDocumentVault(prevState => ({
          ...prevState,
          data: data?.data || [],
        }))
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted")
        } else {
          console.error("Error fetching data:", error)
        }
      }

      return () => abortController.abort()
    }

    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_position_info`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        pl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setDocumentVault({
          ...documentvault,
          data: documentvault?.data?.filter(each => each.pl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Job Title",
        field: "jobtitle",
        sort: "asc",
        width: 270,
      },
      {
        label: "Work Location",
        field: "worklocation",
        sort: "asc",
        width: 200,
      },
      {
        label: "Department",
        field: "department",
        sort: "asc",
        width: 100,
      },
      {
        label: "Type of Position",
        field: "positiontype",
        sort: "asc",
        width: 100,
      },
      {
        label: "Process Status",
        field: "processstautus",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: documentvault?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/add_positions?id=${each.pl_id}`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        jobtitle: each?.pl_job_title || "",
        worklocation: each?.pl_work_location || "",
        department: each?.pl_department || "",
        positiontype: each?.pl_typeof_position || "",
        processstautus: each?.pl_process_status || "",
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.pl_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each.pl_job_title}?`,
                  ) &&
                  handleRemoveRole(each.pl_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Position List</CardTitle>
              <div className="col-12 d-flex">
                <div>
                  <Link
                    to="/add_positions"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add Position
                  </Link>
                </div>
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default PositionsListTable
