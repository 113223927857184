import React, { useEffect, useState } from "react"
import sofLogoBanner from "../../../assets/images/Composition.png"
import html2canvas from "html2canvas"
import { showMessage } from "components/alertmessages/Alertmessages"
import { loginUserData, userId } from "helpers/userId"

const EmailTemplate = ({ id }) => {
  const [templateData, setTemplateData] = useState(null)
  const [emailArray, setEmailArray] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/single_email_temp?sd_id=${id}`,
        )
        const data = await response.json()
        if (data?.success) {
          setTemplateData(data?.data || {})
        }
      } catch (error) {
        console.error("Error fetching email template:", error)
      }
    }

    if (id) {
      fetchTemplateData()
    }
  }, [id])

  useEffect(() => {
    const fetchUsersByRole = async () => {
      if (!templateData?.[0]?.sd_role_id) return

      try {
        const selectedRole = templateData[0].sd_role_id
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_user_by_role?user_role=${selectedRole}`,
        )
        const data = await response.json()
        if (data?.success) {
          const emails = data?.data?.map(user => user.user_email)
          setEmailArray(emails)
        } else {
          console.error("Error fetching users:", data.message)
          setError(data.message)
        }
      } catch (error) {
        console.error("Error occurred during API call:", error.message || error)
        setError(error.message || "Something went wrong")
      }
    }

    fetchUsersByRole()
  }, [templateData])

  const handleSend = async () => {
    try {
      const emailContent = document.getElementById("email-template-content")
      if (!emailContent) {
        console.error("Email content not found!")
        return
      }
      const loginUser =
        loginUserData.user_fname + " " + loginUserData.user_lname
      const userId =loginUserData.user_id
      const canvas = await html2canvas(emailContent)
      const imageData = canvas.toDataURL("image/png")

      for (const email of emailArray) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/send-email-template`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: email,
                cc: templateData[0]?.sd_cc_email,
                subject: templateData[0]?.sd_subject || "No Subject",
                message: templateData[0]?.sd_message || "No Message",
                templateData: templateData[0],
                loginUser: loginUser,
                user_id:userId,
                userRole: loginUserData.user_role == 1 ? "ADMIN" : "Operator",
              }),
            },
          )

          if (response.ok) {
            const result = await response.json()
            showMessage(`Email sent successfully`, "success")
          } else {
            showMessage(`Failed to send email`, "error")
          }
        } catch (error) {
          console.error(`Error sending email to: ${email}`, error)
        }
      }

      const result = await response.json()
      if (result?.success) {
        alert("Email sent successfully!")
      } else {
        alert("Failed to send the email.")
      }
    } catch (error) {
      console.error("Error sending email:", error)
    }
  }

  if (!templateData) {
    return <p>Loading...</p>
  }

  const Footer = () => (
    <div style={{ padding: "30px", textAlign: "center", color: "#9199a1" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <a
          href="https://www.facebook.com/people/Secure-Our-Families/61560814131004/"
          target="_blank"
          rel="noreferrer"
        >
          <i
            className="fab fa-facebook"
            style={{ fontSize: "24px", marginRight: "10px", color: "#1877F2" }}
          ></i>
        </a>

        <a
          href="https://www.linkedin.com/in/secure-our-families-9a106b304/"
          target="_blank"
          rel="noreferrer"
        >
          <i
            className="fab fa-linkedin"
            style={{ fontSize: "24px", marginRight: "10px", color: "#0077B5" }}
          ></i>
        </a>
        <a
          href="https://www.youtube.com/channel/UCIjbe32ThY3CEzZCD4EZ-MQ"
          target="_blank"
          rel="noreferrer"
        >
          <i
            className="fab fa-youtube"
            style={{ fontSize: "24px", marginRight: "10px", color: "#FF0000" }}
          ></i>
        </a>
      </div>
      <br />
      <p style={{ fontSize: "12px", lineHeight: "15px" }}>
        You’re receiving this email because your SOF activity triggered this tip
        or reminder.
      </p>
      <div style={{ marginTop: "8px" }}>
        <a
          href="/"
          style={{
            color: "#9199a1",
            textDecoration: "underline",
            fontSize: "12px",
            marginRight: "10px",
          }}
        >
          Unsubscribe from emails like this
        </a>
        <a
          href="/"
          style={{
            color: "#9199a1",
            textDecoration: "underline",
            fontSize: "12px",
            marginRight: "10px",
          }}
        >
          Edit email settings
        </a>
        <a
          href="/"
          style={{
            color: "#9199a1",
            textDecoration: "underline",
            fontSize: "12px",
          }}
        >
          Contact us
        </a>
      </div>
    </div>
  )

  return (
    <div>
      <div
        style={{
          fontFamily: "HelveticaNeue, Helvetica, Arial, sans-serif",
          backgroundColor: "#f3f3f5",
        }}
      >
        <table
          align="center"
          style={{
            maxWidth: "680px",
            margin: "0 auto",
            backgroundColor: "#ffffff",
            width: "100%",
            border: "none",
          }}
        >
          <thead>
            <tr>
              <td>
                <Header />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="email-template-content">
                <HeroSection templateData={templateData} />
                <ContentSection templateData={templateData} />
                <Footer />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ padding: "20px 30px" }}>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={handleSend}
          style={{ padding: "10px 20px" }}
        >
          Send Email
        </button>
      </div>
    </div>
  )
}

const Header = () => (
  <div
    style={{
      display: "flex",
      background: "#f3f3f5",
      padding: "30px 30px 20px",
      alignItems: "center",
      paddingTop: "50px",
    }}
  ></div>
)

const HeroSection = ({ templateData }) => (
  <div
    style={{
      borderRadius: "5px 5px 0 0",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#2b2d6e",
    }}
  >
    <div style={{ display: "flex", padding: "7px 8px" }}>
      <img
        src={sofLogoBanner}
        alt="Banner"
        className="responsive-img"
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  </div>
)

const ContentSection = ({ templateData }) => (
  <>
    <div style={{ padding: "30px" }}>
      <p>Hi {templateData[0].sd_cc_email.split("@")[0]},</p>

      <p
        style={{
          fontSize: "15px",
          lineHeight: "21px",
          color: "#3c3f44",
          marginBottom: "20px",
        }}
      >
        {templateData[0].sd_message ||
          "This is a default message if no message is provided."}
      </p>

      <div
        style={{
          textAlign: "center",
          margin: "20px 0",
        }}
      >
        <img
          src={templateData[0].sd_image || "https://via.placeholder.com/100"}
          alt="Customer"
          style={{
            display: "block",
            margin: "0 auto",
            width: "350px",
            height: "150px",
            borderRadius: "5%",
          }}
        />
        <button
          type="button"
          className="btn btn-outline-success"
          style={{
            width: "150px",
            display: "inline-block",
            textDecoration: "none",
            padding: "10px 20px",
            borderRadius: "4px",
            fontSize: "15px",
            fontWeight: "bold",
            marginTop: "20px",
          }}
          onClick={() => {
            window.open(templateData[0].sd_button, "_blank")
          }}
        >
          {templateData[0].sd_button_name}
        </button>
      </div>

      <br />
      <p style={{ fontSize: "15px", lineHeight: "21px", color: "#3c3f44" }}>
        Yours truly,
        <br></br>
        <br></br>
        <div style={{ paddingLeft: "10px" }}>
          {loginUserData.user_fname} {loginUserData.user_lname}
          {loginUserData.user_role == 1 ? <h6>ADMIN</h6> : <h6>Operator</h6>}
        </div>
      </p>
    </div>
  </>
)

export default EmailTemplate
