import DocumentSearchTable from "components/DataTables/DocumentSearchTable"
import React, { useEffect, useState } from "react"
import { Form, Row, Col, Card, CardBody, Container, Button } from "reactstrap"

const DocumentWizardList = () => {
  useEffect(() => {
    localStorage.removeItem("tab")
    localStorage.removeItem("assetId")
  }, [])

  const initialFormDetails = {
    RegionList: [],
    documentName: "",
    country: "",
    region: "",
  }

  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })
  const [searchResults, setSearchResults] = useState([])

  async function fetchRegionsList(countryName, type) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`,
      )

      const data = await response.json()
      if (type !== 1) {
        setForm({
          ...form,
          RegionList: data?.data || [],
          region: data.data?.region || "",
          country: countryName,
        })
      }

      return data?.data || []
    } catch (error) {
    }
  }

  const handleForm = (e, action) => {
    const { name, value } = e?.target || {}
    switch (action) {
      case "documentsearch":
        if (name === "country") {
          fetchRegionsList(value)
        }
        setForm({
          ...form,
          [name]: value,
        })
        break
      default:
        break
    }
  }

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_documents`,
      )

      const getAllDocData = await response.json()

      const filteredData = Array.isArray(getAllDocData.data)
        ? getAllDocData.data.filter(doc => {
            const docNameMatches =
              !form.documentName ||
              (typeof doc.dl_docName === "string" &&
                doc.dl_docName.includes(form.documentName))
    
            if (docNameMatches) {
              if (doc.dl_active === "Active") {
                if (doc.dl_country === form.country) {
                  if (!form.region) {
                    return true
                  }
                  if (doc.dl_region === form.region) {
                    return true
                  }
                }
              }
            }
          })
        : []

      setSearchResults(filteredData)
    } catch (error) {
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">DOCUMENT SEARCH LIST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Services</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Document Search List</a>
              </li>
            </ol>
          </div>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="document_name" className="form-label">
                          Document Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="documentName"
                          value={form.documentName}
                          onChange={e => handleForm(e, "documentsearch")}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="country" className="form-label">
                          Country
                        </label>
                        <select
                          className="form-select"
                          name="country"
                          id="country"
                          value={form.country}
                          onChange={e => handleForm(e, "documentsearch")}
                        >
                          <option value="" selected="">
                            Select Country
                          </option>
                          <option value="USA">USA</option>
                          <option value="INDIA">INDIA</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="region" className="form-label">
                          Region
                        </label>
                        <select
                          className="form-select"
                          name="region"
                          id="region"
                          value={form.region}
                          onChange={e => handleForm(e, "documentsearch")}
                        >
                          {form.RegionList.length === 0 ? (
                            <option value="" selected="">
                              Select Region
                            </option>
                          ) : (
                            <>
                              <option value="" defaultValue>
                                Select Region
                              </option>
                              {form.RegionList.map(each => (
                                <option value={each.region} key={each.region}>
                                  {each.region}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3 d-flex align-items-end">
                      <Button color="primary" onClick={handleSearch}>
                        Search
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="card">
            <DocumentSearchTable data={searchResults} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DocumentWizardList
