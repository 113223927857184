import React, { useEffect, useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, { showMessage } from "components/alertmessages/Alertmessages"

// FileViewer component to handle file viewing in a new tab
const FileViewer = ({ dv_file }) => {
  const getFileExtension = (fileName) => {
    return fileName?.split('.').pop().toLowerCase();
  };

  const fileExtension = getFileExtension(dv_file);

  if (!dv_file) return <p>No file available</p>;

  return (
    <>
      {fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png" ? (
        // Button to open the image in a new tab
        <a href={dv_file} target="_blank" rel="noopener noreferrer">
          View Image
        </a>
      ) : fileExtension === "doc" || fileExtension === "docx" ? (
        // Button to open DOC/DOCX via Google Docs viewer in a new tab
        <a href={`https://docs.google.com/gview?url=${dv_file}&embedded=true`} target="_blank" rel="noopener noreferrer">
          View Document
        </a>
      ) : fileExtension === "pdf" ? (
        // Button to open PDF in a new tab
        <a href={`https://docs.google.com/gview?url=${dv_file}&embedded=true`} target="_blank" rel="noopener noreferrer">
          View PDF
        </a>
      ) : (
        // Fallback for unsupported formats
        <p>Unsupported file format</p>
      )}
    </>
  );
};

const DocumentVaultTable = () => {
  const [documentvault, setDocumentVault] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/document_vault?user_id=${user_id}`
      )
      const data = await response.json()
      setDocumentVault({ ...documentvault, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_document_vault`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        dv_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setDocumentVault({
          ...documentvault,
          data: documentvault?.data?.filter(each => each.dv_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Document Name",
        field: "DocumentName",
        sort: "asc",
        width: 270,
      },
      {
        label: "View",
        field: "view",
        sort: "asc",
        width: 200,
      },
      {
        label: "Download",
        field: "download",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "cdate",
        sort: "asc",
        width: 100,
      },      
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: documentvault?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit: (
          <Link
            to={`/add_document_vault?id=${each.dv_id}`}
            className="btn btn-outline-primary btn-sm edit"
            title="Edit"
          >
            <i className="fas fa-pencil-alt"></i>
          </Link>
        ) || "",
        DocumentName: each?.dv_name || "",
        // Use FileViewer for the "View" button that opens files in a new tab
        view: <FileViewer dv_file={each?.dv_file} />,
        download: <a href={each?.dv_file || ""} target="_blank">Download</a>,
        cdate: each?.dv_date || "",
        action: (
          <Col xl={3} lg={4} sm={6}>
            <i
              className="ion ion-md-trash"
              title="Delete"
              type="button"
              onClick={() =>
                each.dv_id &&
                window.confirm(
                  `Are you sure do you want to delete ${each.dv_name}?`
                ) &&
                handleRemoveRole(each.dv_id)
              }
            ></i>
          </Col>
        ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Document Vault List</CardTitle>
              <div className="col-12 d-flex">
                <div>
                  <Link
                    to="/add_document_vault"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add Document
                  </Link>&nbsp; &nbsp;
                </div>

                {/* <div>
                  <Link
                    to="/will-list"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                   Will List
                  </Link>
                </div>   */}

              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DocumentVaultTable
