import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import "../../styles/datatables.scss"
import { showMessage } from "components/alertmessages/Alertmessages"

const QRCodeList = () => {
  const [qrList, setQRList] = useState({ data: [] })
  const [customerList, setQRCustomerList] = useState({ data: [] })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // const fetchQRCodeList = async () => {
    //   try {
    //     setIsLoading(true)
    //     const response = await fetch(
    //       `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/qr_code_list`,
    //     )

    //     if (!response.ok) {
    //       throw new Error("Failed to fetch QR Code List data")
    //     }

    //     const result = await response.json()
    //     setQRList({ data: result || [] })
    //   } catch (error) {
    //     console.error("Error fetching QR Code List:", error)
    //     showMessage(
    //       error.message || "Failed to load QR Code List",
    //       "error",
    //       false,
    //     )
    //   } finally {
    //     setIsLoading(false)
    //   }
    // }

    const fetchQRCodeCustomerList = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get-qr-customer-info`,
        )

        if (!response.ok) {
          throw new Error("Failed to fetch QR Code Customer List data")
        }

        const result = await response.json()
        setQRCustomerList({ data: result || [] })
      } catch (error) {
        console.error("Error fetching QR Customer List:", error)
      } finally {
        setIsLoading(false)
      }
    }

    const fetchQRCodeList = async () => {
      try {
        setIsLoading(true)

        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/qr_code_list`,
        )

        if (!response.ok) {
          throw new Error("Failed to fetch QR Code List data")
        }

        const qrCodeList = await response.json()

        const qrRequestedResponse = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_qr_approval`,
        )

        if (!qrRequestedResponse.ok) {
          throw new Error("Failed to fetch QR Requested List data")
        }

        const qrRequestedList = await qrRequestedResponse.json()
        const updatedQRCodeList = qrCodeList.map(qrCodeItem => {
          const matchingRequestedItem = qrRequestedList.find(
            reqItem => reqItem.eqc_id === qrCodeItem.eqc_id,
          )
          if (
            matchingRequestedItem &&
            ["Requested", "Approved", "Denied"].includes(
              matchingRequestedItem.admin_status,
            )
          ) {
            return {
              ...qrCodeItem,
              admin_status: matchingRequestedItem.admin_status,
            }
          }

          return qrCodeItem
        })

        setQRList({ data: updatedQRCodeList || [] })
      } catch (error) {
        console.error("Error fetching and updating QR Code List:", error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchQRCodeCustomerList()
    fetchQRCodeList()
    //fetchRquestedCodeList()
  }, [])

  const handleDeleteQRCode = async eqc_id => {
    try {
      if (!window.confirm("Are you sure you want to delete this Request?"))
        return

      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/remove-qr-code-list?eqc_id=${eqc_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
      )

      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.error || "Failed to delete QR Code Request")
      }

      const result = await response.json()

      if (result.message) {
        setQRList(prevState => ({
          ...prevState,
          data: prevState.data.filter(item => item.eqc_id !== eqc_id),
        }))

        showMessage(
          result.message || "QR Code Request deleted successfully",
          "success",
        )
      } else {
        throw new Error("Deletion failed")
      }
    } catch (error) {
      console.error("Error deleting QR Code:", error)
      showMessage(error.message || "Something went wrong", "error", false)
    }
  }

  const tableData = {
    columns: [
      { label: "S. No.", field: "sno", sort: "asc" },
      { label: "Request First Name", field: "reqFName", sort: "asc" },
      { label: "Request Last Name", field: "reqLName", sort: "asc" },
      { label: "Customer First Name", field: "custFName", sort: "asc" },
      { label: "Customer Last Name", field: "custLName", sort: "asc" },
      { label: "Created At", field: "createdAt", sort: "asc" },
      { label: "Status", field: "status", sort: "asc" },
      { label: "Actions", field: "actions", sort: "asc" },
    ],
    rows: qrList?.data?.map((item, index) => {
      const matchedCustomer = customerList?.data?.find(
        cust => cust.eqc_id === item.eqc_id,
      )

      return {
        sno: index + 1,
        reqFName: item.eqc_fname || "N/A",
        reqLName: item.eqc_lname || "N/A",
        custFName: matchedCustomer?.eqrc_fname || "N/A",
        custLName: matchedCustomer?.eqrc_lname || "N/A",
        createdAt: item.eqc_dateofrq || "N/A",
        status: item.admin_status || "N/A",
        actions: (
          <div className="d-flex">
            <Link
              to={`/add_enable_qr_code?id=${item.eqc_id}&type=0`}
              className="btn btn-outline-primary btn-sm mx-2"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
            <button
              className="btn btn-outline-danger btn-sm"
              title="Delete"
              onClick={() => handleDeleteQRCode(item.eqc_id)}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        ),
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">QR Code List</CardTitle>
              <div className="col-12 d-flex mb-3">
                <Link
                  to="/add_enable_qr_code"
                  className="btn btn-primary"
                  type="button"
                >
                  Add New QR Code
                </Link>
              </div>
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <MDBDataTable
                  responsive
                  bordered
                  data={tableData}
                  noBottomColumns={true}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default QRCodeList
