import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import React, { Suspense, lazy, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import classnames from "classnames"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import {
  Spinner,
  Container,
  Form,
  NavItem,
  NavLink,
  Button,
  TabContent,
  TabPane,
} from "reactstrap"

import { FileUpload } from "helpers/file_uploads/upload_handler"

const initialFormDetails = {
  pl_job_title: "",
  pl_manager: "",
  pl_work_location: "",
  pl_department: "",
  pl_salary_range: "",
  pl_skills: "",
  pl_qualification: "",
  pl_jobdescription: "",
  pl_opening_date: "",
  pl_typeof_position: "",
  pl_voluntary: "",
  // pl_approval_status: "",
  pl_process_status: "",

  // Approval Information

  pai_approved_by: "",
  pai_approval_status: "",
  pai_approved_position: "",

  pei_emp_id: "",
  pei_emp_uid: "",
  pei_employee_name: "",
  pei_joining_date: "",
  pei_resume_doc: "",
  pei_offer_letter: "",
  pei_partnership_letter: "",

  errors: {
    pl_job_title: "",
    pl_manager: "",
    pl_work_location: "",
    pl_department: "",
  },
}

const AddPositions = () => {
  const [activeTab, setactiveTab] = useState(
    (localStorage.getItem("tab") &&
      parseInt(JSON.parse(localStorage.getItem("tab")))) ||
      1,
  )

  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  const relationsList = JSON.parse(localStorage.getItem("relationsList"))

  const [errors, setErrors] = useState({})
  const [changed, setChanged] = useState(undefined) // changed

  // history for navigation
  const history = useNavigate()

  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const type = searchParams.get("type")
  const posId = localStorage.getItem("posId")

  //  const posIdString = localStorage.getItem("posId");
  //  const posId = posIdString ? parseInt(posIdString) : undefined;

  async function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab)
        localStorage.setItem("tab", tab)
      }
    }
  }

  const validateForm = () => {
    var isValid = true
    const newErrors = {}

    // Validation logic for each input
    if (!form.user_fname.trim()) {
      newErrors.user_fname = "First name is required"
      isValid = false
    }

    if (!form.user_lname.trim()) {
      newErrors.user_lname = "Last name is required"
      isValid = false
    }

    setErrors(newErrors)
    return isValid
  }

  // useEffect(() => {
  //   if (activeTab) {
  //     async function setFormData(data) {
  //       if (data.success) {
  //         const decryptedData = data?.data[0]
  //         if (decryptedData) {
  //           const positionDetails = {
  //             ...decryptedData,
  //             pl_job_title: decryptedData.pl_job_title,
  //             pl_manager: decryptedData.pl_manager,
  //             pl_work_location: decryptedData.pl_work_location,
  //             pl_department: decryptedData.pl_department,
  //             pl_salary_range: decryptedData.pl_salary_range,
  //             pl_skills: decryptedData.pl_skills,
  //             pl_qualification: decryptedData.pl_qualification,
  //             pl_jobdescription: decryptedData.pl_jobdescription,
  //             pl_opening_date: decryptedData.pl_opening_date,
  //             pl_typeof_position: decryptedData.pl_typeof_position,
  //             pl_voluntary: decryptedData.pl_voluntary,
  //             // pl_approval_status: decryptedData.pl_approval_status,
  //             pl_process_status: decryptedData.pl_process_status,

  //             // Customer Information

  //             pai_approved_by: decryptedData.pai_approved_by,
  //             pai_approval_status: decryptedData.pai_approval_status,
  //             pai_approved_position: decryptedData.pai_approved_position,

  //           }

  //           setForm({
  //             ...form,
  //             ...positionDetails,
  //           })
  //         }
  //       }

  //       return true
  //     }
  //     if (posId && !id) {
  //       try {
  //         const fetchData = async () => {
  //           const response = await fetch(
  //             `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/single_position_info?pl_id=${posId}`
  //           )

  //           const data = await response.json()
  //           await setFormData(data)
  //         }

  //         fetchData()
  //       } catch (e) {}
  //     }

  //     if (id) {
  //       try {
  //         const fetchDataEditData = async () => {
  //           const response = await fetch(
  //             `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/single_position_info?pl_id=${id}`
  //           )

  //           const data = await response.json()
  //           setFormData(data)
  //         }

  //         fetchDataEditData()
  //       } catch (e) {}

  //     }
  //       if (id) {
  //         try {
  //           const fetchDataEditDataapproal = async () => {
  //             const response = await fetch(
  //               `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_position_aproval?pl_id=${id}`
  //             )

  //             const data = await response.json()
  //             setFormData(data)
  //           }

  //           fetchDataEditDataapproal()
  //         } catch (e) {}
  //     }

  //     if (id) {
  //         try {
  //           const fetchDataEditDataemp = async () => {
  //             const response = await fetch(
  //               `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_position_employee?pl_id=${id}`
  //             )

  //             const data = await response.json()
  //             setFormData(data)
  //           }

  //           fetchDataEditDataemp()
  //         } catch (e) {}
  //     }
  //   }
  // }, [activeTab])

  useEffect(() => {
    if (!activeTab) return

    const fetchData = async () => {
      try {
        let combinedData = {}

        // Fetch data for the create case (posId without id)
        if (posId && !id) {
          const response = await fetch(
            `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/single_position_info?pl_id=${posId}`,
          )
          const data = await response.json()
          if (data.success) {
            combinedData = { ...combinedData, ...data.data[0] }
          }
        }

        // Fetch data for the edit case (id)
        if (id) {
          const [editData, approvalData, employeeData] = await Promise.all([
            fetch(
              `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/single_position_info?pl_id=${id}`,
            ).then(res => res.json()),
            fetch(
              `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_position_aproval?pl_id=${id}`,
            ).then(res => res.json()),
            fetch(
              `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_position_employee?pl_id=${id}`,
            ).then(res => res.json()),
          ])

          // Merge data from all APIs
          if (editData.success)
            combinedData = { ...combinedData, ...editData.data[0] }
          if (approvalData.success)
            combinedData = { ...combinedData, ...approvalData.data[0] }
          if (employeeData.success)
            combinedData = { ...combinedData, ...employeeData.data[0] }
        }

        // Update form state once with consolidated data
        const positionDetails = {
          pl_job_title: combinedData.pl_job_title,
          pl_manager: combinedData.pl_manager,
          pl_work_location: combinedData.pl_work_location,
          pl_department: combinedData.pl_department,
          pl_salary_range: combinedData.pl_salary_range,
          pl_skills: combinedData.pl_skills,
          pl_qualification: combinedData.pl_qualification,
          pl_jobdescription: combinedData.pl_jobdescription,
          pl_opening_date: combinedData.pl_opening_date,
          pl_typeof_position: combinedData.pl_typeof_position,
          pl_voluntary: combinedData.pl_voluntary,
          pl_process_status: combinedData.pl_process_status,
          pai_approved_by: combinedData.pai_approved_by,
          pai_approval_status: combinedData.pai_approval_status,
          pai_approved_position: combinedData.pai_approved_position,

          pei_emp_uid: combinedData.pei_emp_uid || "",
          pei_employee_name: combinedData.pei_employee_name || "",
          pei_id: combinedData.pei_id || "",
          pei_joining_date: combinedData.pei_joining_date || "",
          pei_offer_letter: combinedData.pei_offer_letter || "",
          pei_partnership_letter: combinedData.pei_partnership_letter || "",
          pei_resume_doc: combinedData.pei_resume_doc || "",
        }

        setForm(prevForm => ({
          ...prevForm,
          ...positionDetails,
        }))
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    // const handleFormData = async data => {
    //   if (data.success) {
    //     const decryptedData = data?.data[0]
    //     if (decryptedData) {
    //       const positionDetails = {
    //         ...decryptedData,
    //         pl_job_title: decryptedData.pl_job_title,
    //         pl_manager: decryptedData.pl_manager,
    //         pl_work_location: decryptedData.pl_work_location,
    //         pl_department: decryptedData.pl_department,
    //         pl_salary_range: decryptedData.pl_salary_range,
    //         pl_skills: decryptedData.pl_skills,
    //         pl_qualification: decryptedData.pl_qualification,
    //         pl_jobdescription: decryptedData.pl_jobdescription,
    //         pl_opening_date: decryptedData.pl_opening_date,
    //         pl_typeof_position: decryptedData.pl_typeof_position,
    //         pl_voluntary: decryptedData.pl_voluntary,
    //         pl_process_status: decryptedData.pl_process_status,
    //         pai_approved_by: decryptedData.pai_approved_by,
    //         pai_approval_status: decryptedData.pai_approval_status,
    //         pai_approved_position: decryptedData.pai_approved_position,
    //       }

    //       setForm(prevForm => ({
    //         ...prevForm,
    //         ...positionDetails,
    //       }))
    //     }
    //   }
    // }

    fetchData()
  }, [activeTab, posId, id, setForm])

  const handleForm = async (e, action, key) => {
    let { name, value, type, checked } = e?.target || {}

    switch (action) {
      case "positionDetails":
        setForm({
          ...form,
          [name]: type == "checkbox" ? checked : value,
          errors: { ...form.errors, [name]: value?.trim() ? "" : "Required" },
        })

        break
      case "approvalInformation":
        setForm({
          ...form,
          [name]: value,
        })
        break
      case "employeeInformation":
        setForm({
          ...form,
          [name]: value,
        })
        break
      default:
        null
    }
  }

  // for image upload

  const handleUploadImage = async (e, action, key) => {
    const { name, files } = e.target || {}

    if (!files || files.length === 0) {
      showMessage("No file selected. Please select an image.", "error")
      return
    }

    const file = files[0]

    if (!file.type.startsWith("image/")) {
      showMessage("Invalid file type. Please upload an image.", "error")
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      setForm(prevForm => ({
        ...prevForm,
        listOfOwners: prevForm.listOfOwners.map((owner, idx) =>
          idx === key
            ? {
                ...owner,
                pol_image: reader.result,
              }
            : owner,
        ),
      }))
    }
    reader.readAsDataURL(file)

    try {
      const result = await FileUpload(files)

      setForm(prevForm => ({
        ...prevForm,
        listOfOwners: prevForm.listOfOwners.map((owner, idx) =>
          idx === key
            ? {
                ...owner,
                [name]: result.files?.[0]?.url || "",
              }
            : owner,
        ),
      }))
    } catch (error) {
      console.error("Error uploading image:", error)
      showMessage("Failed to upload the image. Please try again.", "error")
    }
  }

  const handleImage = async e => {
    const { name, files } = e.target
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ]

    if (files && files.length > 0) {
      const file = files[0]

      if (!allowedTypes.includes(file.type)) {
        showMessage(
          "Only images (PNG, JPEG), PDFs, and Word documents are allowed.",
          "error",
        )
        return
      }

      try {
        const result = await FileUpload(files)
        setForm({ ...form, [name]: result.files?.[0]?.url })
      } catch (error) {
        showMessage("File upload failed:", "error")
      }
    }
  }

  const handleFormSubmit = async () => {
    const isValid = true

    if (!isValid) {
      showMessage("Please fill in the required fields", "error", true)
      return
    }
    switch (activeTab) {
      case 1:
        try {
          const url = id
            ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_position_info`
            : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_position_info`

          let haserrors = false
          if (!form.pl_job_title) {
            setErrors(prev => {
              return {
                ...prev,
                pl_job_title: "Please enter name of the asset",
              }
            })
          }

          if (!form.pl_manager) {
            haserrors = true
            setErrors(prev => {
              return {
                ...prev,
                pl_manager: "Please select property type",
              }
            })
          }

          const data = {
            pl_id: id || posId,
            pl_job_title: form.pl_job_title,
            pl_manager: form.pl_manager,
            pl_work_location: form.pl_work_location,
            pl_department: form.pl_department,
            pl_salary_range: form.pl_salary_range,
            pl_skills: form.pl_skills,
            pl_qualification: form.pl_qualification,
            pl_jobdescription: form.pl_jobdescription,
            pl_opening_date: form.pl_opening_date,
            pl_typeof_position: form.pl_typeof_position,
            pl_voluntary: form.pl_voluntary,
            pl_process_status: form.pl_process_status,
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          setApiStatus({ ...apiStatus, inProgress: true })

          const response = await fetch(url, options, {})

          const addOrUpdateAssetInformation = await response.json()

          if (addOrUpdateAssetInformation?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            setactiveTab(2)
            localStorage.setItem("tab", 2)
            localStorage.setItem("posId", addOrUpdateAssetInformation.posId)
            showMessage(addOrUpdateAssetInformation.message, "success")
          } else {
            showMessage(addOrUpdateAssetInformation?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break
      case 2:
        if (id || posId) {
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/positions_approval_info`

            let haserrors = false

            if (!form.pai_approved_by) {
              haserrors = true
              setErrors(prev => {
                return {
                  ...prev,
                  pai_approved_by: "Please Select Type of Real Estate",
                }
              })
            }

            if (haserrors) {
              return
            }

            const data = {
              pl_id: id || posId,
              pai_approved_by: form.pai_approved_by,
              pai_approval_status: form.pai_approval_status,
              pai_approved_position: form.pai_approved_position,
            }

            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }

            const response = await fetch(url, options, {})

            const updateRealEstate = await response.json()

            if (updateRealEstate?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(3)
              localStorage.setItem("tab", 3)
              showMessage(updateRealEstate.message, "success")
            } else {
              showMessage(updateRealEstate?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
        }
        break
      case 3:
        if (id || posId) {
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/positions_employee_info`

            let haserrors = false

            if (!form.pei_employee_name) {
              haserrors = true
              setErrors(prev => {
                return {
                  ...prev,
                  pei_employee_name: "Please Select Type of Real Estate",
                }
              })
            }

            if (haserrors) {
              return
            }

            const data = {
              pl_id: id || posId,
              pei_emp_id: form.pei_emp_id,
              pei_emp_uid: form.pei_emp_uid,
              pei_employee_name: form.pei_employee_name,
              pei_joining_date: form.pei_joining_date,
              pei_resume_doc: form.pei_resume_doc,
              pei_offer_letter: form.pei_offer_letter,
              pei_partnership_letter: form.pei_partnership_letter,
            }

            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }

            const response = await fetch(url, options, {})

            const updateRealEstate = await response.json()

            if (updateRealEstate?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(3)
              localStorage.setItem("tab", 3)
              showMessage(updateRealEstate.message, "success")
              setTimeout(() => {
                type == 1
                  ? history("/positions_list")
                  : history("/positions_list")
              }, 3000)
            } else {
              showMessage(updateRealEstate?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
        }
        break

      default:
        break
    }

    if (!(id || posId) && activeTab != 1) {
      setactiveTab(1)
      localStorage.setItem("tab", 1)
    }
  }

  const handleFormSubmit_2ndone = async () => {
    const isValid = true

    if (!isValid) {
      showMessage("Please fill in the required fields", "error", true)
      return
    }

    switch (activeTab) {
      case 1:
        try {
          const url = id
            ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_position_info`
            : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_position_info`

          let haserrors = false
          if (!form.pl_job_title) {
            setErrors(prev => ({
              ...prev,
              pl_job_title: "Please enter name of the asset",
            }))
          }

          if (!form.pl_manager) {
            haserrors = true
            setErrors(prev => ({
              ...prev,
              pl_manager: "Please select property type",
            }))
          }

          if (haserrors) return

          const data = {
            pl_id: id || posId,
            pl_job_title: form.pl_job_title,
            pl_manager: form.pl_manager,
            pl_work_location: form.pl_work_location,
            pl_department: form.pl_department,
            pl_salary_range: form.pl_salary_range,
            pl_skills: form.pl_skills,
            pl_qualification: form.pl_qualification,
            pl_jobdescription: form.pl_jobdescription,
            pl_opening_date: form.pl_opening_date,
            pl_typeof_position: form.pl_typeof_position,
            pl_voluntary: form.pl_voluntary,
            // pl_approval_status: form.pl_approval_status,
            pl_process_status: form.pl_process_status,
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          setApiStatus({ ...apiStatus, inProgress: true })

          const response = await fetch(url, options)

          const addOrUpdateAssetInformation = await response.json()

          if (addOrUpdateAssetInformation?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            setactiveTab(2)
            localStorage.setItem("tab", 2)
            localStorage.setItem("posId", addOrUpdateAssetInformation.posId)
            showMessage(addOrUpdateAssetInformation.message, "success")
          } else {
            showMessage(addOrUpdateAssetInformation?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {
          setApiStatus({ ...apiStatus, inProgress: false })
          showMessage("An error occurred. Please try again.", "error")
        }
        break
      case 2:
        if (id || posId) {
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/positions_approval_info`

            let haserrors = false

            if (!form.pai_approved_by) {
              haserrors = true
              setErrors(prev => ({
                ...prev,
                pai_approved_by: "Please Select Type of Real Estate",
              }))
            }

            if (haserrors) {
              setApiStatus({ ...apiStatus, inProgress: false })
              return
            }

            const data = {
              pl_id: id || posId,
              pai_approved_by: form.pai_approved_by,
              pai_approval_status: form.pai_approval_status,
              pai_approved_position: form.pai_approved_position,
            }

            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }

            const response = await fetch(url, options)

            const updateRealEstate = await response.json()

            if (updateRealEstate?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(3)
              localStorage.setItem("tab", 3)
              showMessage(updateRealEstate.message, "success")
            } else {
              showMessage(updateRealEstate?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {
            setApiStatus({ ...apiStatus, inProgress: false })
            showMessage("An error occurred. Please try again.", "error")
          }
        }
        break
      case 3:
        if (id || posId) {
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/positions_employee_info`

            let haserrors = false

            if (!form.pei_employee_name) {
              haserrors = true
              setErrors(prev => ({
                ...prev,
                pei_employee_name: "Please Select Type of Real Estate",
              }))
            }

            if (haserrors) {
              setApiStatus({ ...apiStatus, inProgress: false })
              return
            }

            const data = {
              pl_id: id || posId,
              pei_emp_id: form.pei_emp_id,
              pei_emp_uid: form.pei_emp_uid,
              pei_employee_name: form.pei_employee_name,
              pei_joining_date: form.pei_joining_date,
              pei_resume_doc: form.pei_resume_doc,
              pei_offer_letter: form.pei_offer_letter,
              pei_partnership_letter: form.pei_partnership_letter,
            }

            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }

            const response = await fetch(url, options)

            const updateRealEstate = await response.json()

            if (updateRealEstate?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(3)
              localStorage.setItem("tab", 3)
              showMessage(updateRealEstate.message, "success")
              setTimeout(() => {
                type == 1
                  ? history("/positions_list")
                  : history("/positions_list")
              }, 3000)
            } else {
              showMessage(updateRealEstate?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {
            setApiStatus({ ...apiStatus, inProgress: false })
            showMessage("An error occurred. Please try again.", "error")
          }
        }
        break
      default:
        break
    }

    if (!(id || posId) && activeTab != 1) {
      setactiveTab(1)
      localStorage.setItem("tab", 1)
    }
  }

  // const handleFormSubmit = async () => {
  //   const isValid = true;

  //   if (!isValid) {
  //     showMessage("Please fill in the required fields", "error", true);
  //     return;
  //   }

  //   switch (activeTab) {
  //     case 1:
  //       try {
  //         const url = id
  //           ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_position_info`
  //           : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_position_info`;

  //         let hasErrors = false;

  //         if (!form.pl_job_title) {
  //           hasErrors = true;
  //           setErrors((prev) => ({
  //             ...prev,
  //             pl_job_title: "Please enter the name of the asset",
  //           }));
  //         }

  //         if (!form.pl_manager) {
  //           hasErrors = true;
  //           setErrors((prev) => ({
  //             ...prev,
  //             pl_manager: "Please select a manager",
  //           }));
  //         }

  //         if (hasErrors) {
  //           setApiStatus({ ...apiStatus, inProgress: false });
  //           return;
  //         }

  //         const data = {
  //           pl_id: id || posId,
  //           pl_job_title: form.pl_job_title,
  //           pl_manager: form.pl_manager,
  //           pl_work_location: form.pl_work_location,
  //           pl_department: form.pl_department,
  //           pl_salary_range: form.pl_salary_range,
  //           pl_skills: form.pl_skills,
  //           pl_qualification: form.pl_qualification,
  //           pl_jobdescription: form.pl_jobdescription,
  //           pl_opening_date: form.pl_opening_date,
  //           pl_typeof_position: form.pl_typeof_position,
  //           pl_voluntary: form.pl_voluntary,
  //           pl_process_status: form.pl_process_status,
  //         };

  //         const options = {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(data),
  //         };

  //         setApiStatus({ ...apiStatus, inProgress: true });

  //         const response = await fetch(url, options);
  //         const result = await response.json();

  //         if (result?.success) {
  //           setApiStatus({ ...apiStatus, inProgress: false });
  //           setactiveTab(2);
  //           localStorage.setItem("tab", 2);
  //           localStorage.setItem("posId", result.posId || id);
  //           showMessage(result.message, "success");
  //         } else {
  //           setApiStatus({ ...apiStatus, inProgress: false });
  //           showMessage(result?.message || "Error occurred", "error");
  //         }
  //       } catch (error) {
  //         setApiStatus({ ...apiStatus, inProgress: false });
  //         showMessage("An unexpected error occurred", "error");
  //       }
  //       break;

  //     case 2:
  //       if (id || posId) {
  //         try {
  //           const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/positions_approval_info`;

  //           let hasErrors = false;

  //           if (!form.pai_approved_by) {
  //             hasErrors = true;
  //             setErrors((prev) => ({
  //               ...prev,
  //               pai_approved_by: "Please select approval type",
  //             }));
  //           }

  //           if (hasErrors) {
  //             setApiStatus({ ...apiStatus, inProgress: false });
  //             return;
  //           }

  //           const data = {
  //             pl_id: id || posId,
  //             pai_approved_by: form.pai_approved_by,
  //             pai_approval_status: form.pai_approval_status,
  //             pai_approved_position: form.pai_approved_position,
  //           };

  //           const options = {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify(data),
  //           };

  //           setApiStatus({ ...apiStatus, inProgress: true });

  //           const response = await fetch(url, options);
  //           const result = await response.json();

  //           if (result?.success) {
  //             setApiStatus({ ...apiStatus, inProgress: false });
  //             setactiveTab(3);
  //             localStorage.setItem("tab", 3);
  //             showMessage(result.message, "success");
  //           } else {
  //             setApiStatus({ ...apiStatus, inProgress: false });
  //             showMessage(result?.message || "Error occurred", "error");
  //           }
  //         } catch (error) {
  //           setApiStatus({ ...apiStatus, inProgress: false });
  //           showMessage("An unexpected error occurred", "error");
  //         }
  //       }
  //       break;

  //     case 3:
  //       if (id || posId) {
  //         try {
  //           const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/positions_employee_info`;

  //           let hasErrors = false;

  //           if (!form.pei_employee_name) {
  //             hasErrors = true;
  //             setErrors((prev) => ({
  //               ...prev,
  //               pei_employee_name: "Please enter employee name",
  //             }));
  //           }

  //           if (hasErrors) {
  //             setApiStatus({ ...apiStatus, inProgress: false });
  //             return;
  //           }

  //           const data = {
  //             pl_id: id || posId,
  //             pei_emp_id: form.pei_emp_id,
  //             pei_emp_uid: form.pei_emp_uid,
  //             pei_employee_name: form.pei_employee_name,
  //             pei_joining_date: form.pei_joining_date,
  //             pei_resume_doc: form.pei_resume_doc,
  //             pei_offer_letter: form.pei_offer_letter,
  //             pei_partnership_letter: form.pei_partnership_letter,
  //           };

  //           const options = {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify(data),
  //           };

  //           setApiStatus({ ...apiStatus, inProgress: true });

  //           const response = await fetch(url, options);
  //           const result = await response.json();

  //           if (result?.success) {
  //             setApiStatus({ ...apiStatus, inProgress: false });
  //             setactiveTab(3);
  //             localStorage.setItem("tab", 3);
  //             showMessage(result.message, "success");

  //             setTimeout(() => {
  //               history("/positions_list");
  //             }, 3000);
  //           } else {
  //             setApiStatus({ ...apiStatus, inProgress: false });
  //             showMessage(result?.message || "Error occurred", "error");
  //           }
  //         } catch (error) {
  //           setApiStatus({ ...apiStatus, inProgress: false });
  //           showMessage("An unexpected error occurred", "error");
  //         }
  //       }
  //       break;

  //     default:
  //       break;
  //   }

  //   if (!(id || posId) && activeTab !== 1) {
  //     setactiveTab(1);
  //     localStorage.setItem("tab", 1);
  //   }
  // };

  const handleBlur = e => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value?.trim(),
      errors: { ...form.errors, [name]: value?.trim() ? "" : "Requied" },
    })
  }
  useEffect(() => {
    if (changed) {
      setErrors(prev => {
        const copy = JSON.parse(JSON.stringify(prev))
        delete copy[changed]
        return copy
      })
    }
  }, [changed]) // changed

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">Positions</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">HR Process</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/positions_list">Add Positions</Link>
              </li>
            </ol>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              if (id) {
                                setactiveTab(1)
                              } else {
                                activeTab > 1 && setactiveTab(1)
                              }
                              //activeTab > 1 && setactiveTab(1)
                              localStorage.setItem("tab", 1)
                            }}
                          >
                            <span className="number">1.</span> Position
                            Information
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              if (id) {
                                setactiveTab(2)
                              } else {
                                activeTab > 2 && setactiveTab(2)
                              }

                              localStorage.setItem("tab", 2)
                            }}
                          >
                            <span className="number">2.</span> Approval
                            Information
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              if (id) {
                                setactiveTab(3)
                              } else {
                                activeTab > 3 && setactiveTab(3)
                              }

                              localStorage.setItem("tab", 3)
                            }}
                          >
                            <span className="number">3.</span>
                            Employee Information
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <Form>
                            <h4 className="page-title-box">
                              Position Information
                            </h4>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pl_job_title"
                                    className="form-label"
                                  >
                                    Job Title{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.pl_job_title
                                        ? "invalid-inputfield form-control"
                                        : "pl_job_title form-control"
                                    }
                                    name="pl_job_title"
                                    id="eqc_fname_ljsd"
                                    placeholder="Job Title"
                                    required
                                    onChange={e => {
                                      setChanged("pl_job_title")
                                      handleForm(e, "positionDetails")
                                    }}
                                    value={form.pl_job_title}
                                    onBlur={handleBlur}
                                  />
                                  {errors.pl_job_title && (
                                    <div className="invalid-input">
                                      {errors.pl_job_title}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pl_manager"
                                    className="form-label"
                                  >
                                    Manager{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="pl_manager"
                                    id="pl_manager"
                                    placeholder="Manager"
                                    value={form.pl_manager}
                                    onChange={e =>
                                      handleForm(e, "positionDetails")
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pl_work_location"
                                    className="form-label"
                                  >
                                    Work Location{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="pl_work_location"
                                    id="pl_work_location"
                                    placeholder="Work Location"
                                    value={form.pl_work_location}
                                    onChange={e =>
                                      handleForm(e, "positionDetails")
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pl_department"
                                    className="form-label"
                                  >
                                    Department{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    id="pl_department"
                                    name="pl_department"
                                    required
                                    onChange={e => {
                                      setChanged("pl_department")
                                      handleForm(e, "positionDetails")
                                    }}
                                    value={form.pl_department}
                                  >
                                    <option value="" selected>
                                      Select Department
                                    </option>
                                    <option value="IT">IT</option>
                                    <option value="Operations">
                                      Operations
                                    </option>
                                    <option value="HR">HR</option>
                                    <option value="Sales">Sales</option>
                                  </select>
                                  {errors.pl_department && (
                                    <div className="invalid-input">
                                      {errors.pl_department}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip02"
                                    className="form-label"
                                  >
                                    Salary Range{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="pl_salary_range"
                                    id="validationTooltip02"
                                    placeholder="Salary Range"
                                    required
                                    onChange={e => {
                                      setChanged("pl_salary_range")
                                      handleForm(e, "positionDetails")
                                    }}
                                    value={form.pl_salary_range}
                                  />
                                  {errors.pl_salary_range && (
                                    <div className="invalid-input">
                                      {errors.pl_salary_range}
                                    </div>
                                  )}
                                  <span className="help-inline col-xs-12 col-sm-7"></span>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip03"
                                    className="form-label"
                                  >
                                    Skills{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="pl_skills"
                                    id="validationTooltip02"
                                    placeholder="Skills"
                                    onChange={e =>
                                      handleForm(e, "positionDetails")
                                    }
                                    value={form.pl_skills}
                                  />
                                  {errors.pl_skills && (
                                    <div className="invalid-input">
                                      {errors.pl_skills}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip02"
                                    className="form-label"
                                  >
                                    Qualification{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="pl_qualification"
                                    id="validationTooltip02"
                                    placeholder="Qualification"
                                    onChange={e =>
                                      handleForm(e, "positionDetails")
                                    }
                                    value={form.pl_qualification}
                                  />
                                  <span className="help-inline col-xs-12 col-sm-7"></span>
                                </div>
                              </div>
                              {id ? (
                                <div className="col-md-3">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Position No
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="position"
                                      id="validationTooltip02"
                                      placeholder="Position No"
                                      readOnly
                                      value={id}
                                    />
                                    <span className="help-inline col-xs-12 col-sm-7"></span>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-12">
                                {/*<div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pl_jobdescription"
                                    className="form-label"
                                  >
                                    Job Description
                                  </label>
                                  <textarea
                                    id="validationTooltip01"
                                    name="pl_jobdescription"
                                    rows="3"
                                    className="form-control"
                                    onChange={e =>
                                      handleForm(e, "positionDetails")
                                    }
                                    value={form.pl_jobdescription}
                                  ></textarea>
                                </div> */}
                                <div className="col-md-12">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="pl_jobdescription"
                                      className="form-label"
                                    >
                                      Job Description
                                    </label>
                                    <ReactQuill
                                      id="pl_jobdescription"
                                      name="pl_jobdescription"
                                      value={form.pl_jobdescription}
                                      onChange={content =>
                                        handleForm(
                                          {
                                            target: {
                                              name: "pl_jobdescription",
                                              value: content,
                                            },
                                          },
                                          "positionDetails",
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip03"
                                    className="form-label"
                                  >
                                    Opening Date{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="pl_opening_date"
                                    id="validationTooltip02"
                                    placeholder="date"
                                    onChange={e =>
                                      handleForm(e, "positionDetails")
                                    }
                                    value={form.pl_opening_date}
                                  />
                                  {errors.pl_opening_date && (
                                    <div className="invalid-input">
                                      {errors.pl_opening_date}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="eqc_relation"
                                    className="form-label"
                                  >
                                    Type of Position{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    id="pl_typeof_position"
                                    name="pl_typeof_position"
                                    required
                                    onChange={e => {
                                      setChanged("pl_typeof_position")
                                      handleForm(e, "positionDetails")
                                    }}
                                    value={form.pl_typeof_position}
                                  >
                                    <option value="" selected>
                                      Select Type of Position
                                    </option>
                                    <option value="Permanent - Full Time">
                                      Permanent - Full Time
                                    </option>
                                    <option value="Permanent - Part Time">
                                      Permanent - Part Time
                                    </option>
                                    <option value="Contractor - Full Time">
                                      Contractor - Full Time
                                    </option>
                                    <option value="Contractor - Part Time">
                                      Contractor - Part Time
                                    </option>
                                    <option value="Internship">
                                      Internship
                                    </option>
                                    <option value="Partner">Partner</option>
                                  </select>
                                  {errors.pl_typeof_position && (
                                    <div className="invalid-input">
                                      {errors.pl_typeof_position}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pl_voluntary"
                                    className="form-label"
                                  >
                                    Voluntary{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    id="pl_voluntary"
                                    name="pl_voluntary"
                                    required
                                    onChange={e => {
                                      setChanged("pl_voluntary")
                                      handleForm(e, "positionDetails")
                                    }}
                                    value={form.pl_voluntary}
                                  >
                                    <option value="" selected>
                                      Select Voluntary
                                    </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                  {errors.pl_voluntary && (
                                    <div className="invalid-input">
                                      {errors.pl_voluntary}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* <div className="col-md-3">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="pl_approval_status"
                                      className="form-label"
                                    >
                                      Approval Status{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <select
                                      className="form-select"
                                      id="pl_approval_status"
                                      name="pl_approval_status"
                                      required
                                      onChange={e => {
                                        setChanged("pl_approval_status")
                                        handleForm(e, "positionDetails")
                                      }}
                                      value={form.pl_approval_status}
                                    >
                                      <option value="" selected>
                                        Select Approval Status
                                      </option> 
                                      <option value="Approved">Approved</option> 
                                      <option value="Denied">Denied</option>
                                    </select>
                                    {errors.pl_approval_status && (
                                      <div className="invalid-input">
                                        {errors.pl_approval_status}
                                      </div>
                                    )}
                                  </div>
                                </div> */}
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pl_process_status"
                                    className="form-label"
                                  >
                                    Process Status{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    id="pl_process_status"
                                    name="pl_process_status"
                                    required
                                    onChange={e => {
                                      setChanged("pl_process_status")
                                      handleForm(e, "positionDetails")
                                    }}
                                    value={form.pl_process_status}
                                  >
                                    <option value="" selected>
                                      Select Process Status
                                    </option>
                                    <option value="Open">Open</option>
                                    <option value="In Process">
                                      In Process
                                    </option>
                                    <option value="Filled">Filled</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="Deferred">Deferred</option>
                                  </select>
                                  {errors.pl_process_status && (
                                    <div className="invalid-input">
                                      {errors.pl_process_status}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Form>
                            <h4 className="page-title-box">
                              Approval Information
                            </h4>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="pai_approved_by"
                                    className="form-label"
                                  >
                                    Approved By
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="pai_approved_by"
                                    id="pai_approved_by"
                                    placeholder="Approved By"
                                    onChange={e =>
                                      handleForm(e, "approvalInformation")
                                    }
                                    value={form.pai_approved_by}
                                  />
                                </div>
                              </div>
                              {/* <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="pai_approved_name"
                                      className="form-label"
                                    >
                                      Approver Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="pai_approved_name"
                                      id="pai_approved_name"
                                      placeholder="Approver Name"
                                      onChange={e => handleForm(e, "approvalInformation")}
                                      value={form.pai_approved_name}
                                    />
                                   
                                  </div>
                                </div>  */}
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pai_approval_status"
                                    className="form-label"
                                  >
                                    Approval Status{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    id="pai_approval_status"
                                    name="pai_approval_status"
                                    required
                                    onChange={e => {
                                      setChanged("pai_approval_status")
                                      handleForm(e, "positionDetails")
                                    }}
                                    value={form.pai_approval_status}
                                  >
                                    <option value="" selected>
                                      Select Approval Status
                                    </option>
                                    <option value="Approved">Approved</option>
                                    <option value="Denied">Denied</option>
                                  </select>
                                  {errors.pai_approval_status && (
                                    <div className="invalid-input">
                                      {errors.pai_approval_status}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="pai_approved_position"
                                    className="form-label"
                                  >
                                    Approver Position
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="pai_approved_position"
                                    id="pai_approved_position"
                                    onChange={e =>
                                      handleForm(e, "approvalInformation")
                                    }
                                    value={form.pai_approved_position}
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Form>
                            <h4 className="page-title-box">
                              Employee Information
                            </h4>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="pei_emp_id"
                                    className="form-label"
                                  >
                                    Employee Id
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="pei_emp_id"
                                    id="pei_emp_id"
                                    placeholder="Employee Id"
                                    onChange={e =>
                                      handleForm(e, "employeeInformation")
                                    }
                                    value={form.pei_emp_id}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="pei_emp_uid"
                                    className="form-label"
                                  >
                                    Employee User Id
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="pei_emp_uid"
                                    id="pei_emp_uid"
                                    placeholder="Employee User Id"
                                    onChange={e =>
                                      handleForm(e, "employeeInformation")
                                    }
                                    value={form.pei_emp_uid}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="pei_employee_name"
                                    className="form-label"
                                  >
                                    Employee Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="pei_employee_name"
                                    id="pei_employee_name"
                                    placeholder="Employee Name"
                                    onChange={e =>
                                      handleForm(e, "employeeInformation")
                                    }
                                    value={form.pei_employee_name}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="pei_joining_date"
                                    className="form-label"
                                  >
                                    Joining Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="pei_joining_date"
                                    id="pei_joining_date"
                                    onChange={e =>
                                      handleForm(e, "employeeInformation")
                                    }
                                    value={form.pei_joining_date}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltipUsername"
                                    className="form-label"
                                  >
                                    Resume Doc
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type="file"
                                      name="pei_resume_doc"
                                      accept="image/*,.pdf,.doc,.docx"
                                      className="upload"
                                      id="pei_resume_doc"
                                      style={{ display: "none" }}
                                      onChange={handleImage}
                                    />
                                    <label htmlFor="pei_resume_doc">
                                      <p className="btn btn-info">
                                        Upload Resume{" "}
                                      </p>
                                    </label>
                                  </div>

                                  {form.pei_resume_doc && (
                                    <Link
                                      to={form.pei_resume_doc}
                                      target="_blank"
                                      className="btn btn-info"
                                    >
                                      View
                                    </Link>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltipUsername"
                                    className="form-label"
                                  >
                                    Offer Letter
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type="file"
                                      name="pei_offer_letter"
                                      accept="image/*,.pdf,.doc,.docx"
                                      className="upload"
                                      id="pei_offer_letter"
                                      style={{ display: "none" }}
                                      onChange={handleImage}
                                    />
                                    <label htmlFor="pei_offer_letter">
                                      <p className="btn btn-info">
                                        Upload Offer Letter{" "}
                                      </p>
                                    </label>
                                  </div>
                                  {form.pei_offer_letter && (
                                    <Link
                                      to={form.pei_offer_letter}
                                      target="_blank"
                                      className="btn btn-info"
                                    >
                                      View
                                    </Link>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltipUsername"
                                    className="form-label"
                                  >
                                    Partnership Letter
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type="file"
                                      name="pei_partnership_letter"
                                      accept="image/*,.pdf,.doc,.docx"
                                      className="upload"
                                      id="pei_partnership_letter"
                                      style={{ display: "none" }}
                                      onChange={handleImage}
                                    />
                                    <label htmlFor="pei_partnership_letter">
                                      <p className="btn btn-info">
                                        Upload Partnership Letter{" "}
                                      </p>
                                    </label>
                                  </div>
                                  {form.pei_partnership_letter && (
                                    <Link
                                      to={form.pei_partnership_letter}
                                      target="_blank"
                                      className="btn btn-info"
                                    >
                                      View
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Form>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Button
                            type="button"
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                            onClick={() => {
                              // activeTab != handleFormSubmit()
                              toggleTab(activeTab - 1)
                            }}
                            disabled={activeTab === 1}
                          >
                            Previous
                          </Button>
                        </li>
                        <li
                          className={activeTab === 3 ? "next disabled" : "next"}
                        >
                          <Button
                            disabled={activeTab === 3}
                            type="button"
                            onClick={() => {
                              const isFormValid = handleFormSubmit()

                              if (isFormValid) {
                                toggleTab(activeTab + 1)
                              } else {
                                console.log("Form is invalid")
                              }
                            }}
                          >
                            Next
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {apiStatus.inProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      disabled={true}
                    >
                      <Spinner color="light" size="sm">
                        Loading ...
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      onClick={handleFormSubmit}
                    >
                      {posId || id ? "Save" : "Submit"}
                    </button>
                  )}
                  &nbsp; &nbsp;
                  <Link
                    to={"/positions_list"}
                    className="btn btn-secondary waves-effect"
                    onClick={() => {
                      localStorage.removeItem("posId")
                      localStorage.removeItem("tab")
                    }}
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddPositions
