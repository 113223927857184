import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import React, { Suspense, lazy, useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import classnames from "classnames"
  import "../../../../src//styles/errorfield.scss"
  import axios from "axios"
  import {
    Spinner,
    Container,
    Form,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane,
  } from "reactstrap"
  
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { userId, loginUserData } from "helpers/userId"
  import { dataDecription } from "helpers/cryptojs.secure"
  
  const ListofDocuments = lazy(
    () => import("components/Customers/EnableQRCode/ListofDocuments"),
  )
  
  // const initialFormDetails = {
  //   // Table 1: enable_qr_code_list
  //   user_id: "",
  //   eqc_fname: "",
  //   eqc_lname: "",
  //   eqc_relation: "",
  //   eqc_dateofrq: "",
  //   eqc_contactno: "",
  //   eqc_email: "",
  //   eqra_status: " ",
  //   verifyflag: 0,
  // }
  
  const CustomerLevels = () => {
    const [activeTab, setactiveTab] = useState(
      (localStorage.getItem("tab") &&
        parseInt(JSON.parse(localStorage.getItem("tab")))) ||
        1,
    )
    const [form, setForm] = useState({})
    const [adminStatus, setAdminStatus] = useState("Requested")
    const [verificationStatus, setVerificationStatus] = useState("")
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
    const [mismatchedDetails, setMismatchedDetails] = useState([])
    const [relations, setRelations] = useState([])
    const [errors, setErrors] = useState({})
    const [changed, setChanged] = useState(undefined)
    const [status, setStatus] = useState("")
    const history = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const type = searchParams.get("type")
    const eqc_id = parseInt(JSON.parse(localStorage.getItem("eqc_id")))
    const eqrc_id = parseInt(JSON.parse(localStorage.getItem("eqrc_id")))
    const eqrd_id = parseInt(JSON.parse(localStorage.getItem("eqrd_id")))
    const eqra_id = parseInt(JSON.parse(localStorage.getItem("eqra_id")))
    const [verifiedUserId, setVerifiedUserId] = useState(null)
    const userData = JSON.parse(localStorage.getItem("userData")) || ""
    var userId = userData.user.user_id
    let user_role = -1
    if (userData) {
      user_role =
        userData.user.user_role === 0 ? 0 : userData.user.user_role || -1
    }
  
    async function toggleTab(tab) {
      if (activeTab !== tab) {
        if (tab >= 1 && tab <= 4) {
          setactiveTab(tab)
          localStorage.setItem("tab", tab)
  
          if (activeTab == 6) {
            await fetchListOfDocuments()
          }
        }
      }
    }
  
    const fetchRelationsList = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/relations_list`,
        )
        const data = await response.json()
  
        if (data?.data) {
          localStorage.setItem("relationsList", JSON.stringify(data?.data))
          setRelations(data?.data)
        }
      } catch (error) {}
    }
  
    useEffect(() => {
      fetchRelationsList()
    }, [])
  
    async function fetchListOfDocuments() {
      try {
        const url = `${
          process.env.REACT_APP_DOMAIN_URL
        }api/v1/customer/services/qr-doc-info?eqc_id=${id || eqc_id}`
  
        const response = await fetch(url)
        const data = await response.json()
        const decryptedData = data?.data
        setForm({
          ...form,
          ListofDocuments: decryptedData || [],
        })
      } catch (err) {}
    }
    let timeoutId
    useEffect(() => {
      if (activeTab === 3) {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
          fetchListOfDocuments()
        }, 300) // Delay to debounce API calls
      }
      return () => clearTimeout(timeoutId) // Clean up on unmount
    }, [activeTab])
  
    const handleRemoveDocuments = async pdl_id => {
      try {
        if (pdl_id) {
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/qr-doc-info`
          const data = {
            pdl_id: pdl_id,
          }
          const options = {
            method: "delete",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
  
          const response = await fetch(url, options, {})
          const removeData = await response.json()
  
          if (removeData?.success === true) {
            showMessage(removeData.message, "success")
            setForm({
              ...form,
              ListofDocuments: form.ListofDocuments.filter(
                each => each.pdl_id !== pdl_id,
              ),
            })
          } else {
            showMessage(removeData.message, "error", false)
          }
        } else if (pdl_id >= 0) {
          setForm({
            ...form,
            ListofDocuments: form.ListofDocuments.filter(
              (each, index) => each.pdl_id != idx,
            ),
          })
        }
      } catch (error) {
        showMessage(removeData?.message || "Something went wrong", "error", false)
      }
    }
  
    {
      /* */
    }
  
    useEffect(() => {
      if (activeTab === 4) {
        form.verifyflag = 1
      }
      if (activeTab === 3) {
        setTimeout(() => {
          fetchListOfDocuments()
        }, [activeTab])
      }
    }, [activeTab])
  
    useEffect(() => {
      fetchRelationsList()
    }, [activeTab])
  
    useEffect(() => {
      if (!activeTab) {
        return
      }
  
      const formattedDateForInput = dateString => {
        if (!dateString) {
          return ""
        }
        const date = new Date(dateString)
        if (isNaN(date)) {
          return ""
        }
        return date.toISOString().split("T")[0]
      }
  
      const setFormData = data => {
        if (!data || !data.length) {
          return
        }
  
        const decryptedData = data.find(item => item) // Find valid data
        if (!decryptedData || Object.keys(decryptedData).length === 0) {
          return
        }
  
        let requestorDetails = {}
        if (activeTab === 1) {
          const formattedDate = formattedDateForInput(
            decryptedData.eqc_dateofrq || "",
          )
          if (!formattedDate) {
            return
          }
  
          requestorDetails = {
            eqc_contactno: decryptedData.eqc_contactno || "",
            eqc_dateofrq: formattedDate,
            eqc_email: decryptedData.eqc_email || "",
            eqc_fname: decryptedData.eqc_fname || "",
            eqc_lname: decryptedData.eqc_lname || "",
            eqc_id: decryptedData.eqc_id || null,
            eqc_relation: decryptedData.eqc_relation || "",
          }
        } else if (activeTab === 2) {
          requestorDetails = {
            eqrc_id: decryptedData.eqrc_id || null,
            eqrc_reasonforenable: decryptedData.eqrc_reasonforenable || "",
            eqrc_fname: decryptedData.eqrc_fname || "",
            eqrc_contact: decryptedData.eqrc_contact || "",
            eqrc_dob: formattedDateForInput(decryptedData.eqrc_dob || ""),
            eqrc_email: decryptedData.eqrc_email || "",
            eqrd_name: decryptedData.eqrd_name || "",
            eqrd_file: decryptedData.eqrd_file || null,
            eqrd_id: decryptedData.eqrd_id || null,
          }
        } else {
          return
        }
  
        setForm(prevForm => ({
          ...prevForm,
          ...requestorDetails,
        }))
      }
  
      const fetchData = async (url, options = {}) => {
        try {
          const response = await fetch(url, options)
          if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`)
          }
          const data = await response.json()
          setFormData(data)
        } catch (error) {}
      }
  
      const fetchTabData = async () => {
        const baseUrl = process.env.REACT_APP_DOMAIN_URL
        if (!activeTab) {
          const apiUrl = `${baseUrl}api/v1/customer/services/qr_code_list`
          await fetchData(apiUrl)
        }
  
        if (eqc_id || id) {
          if (activeTab === 1 || activeTab === 4) {
            const apiUrl = `${baseUrl}api/v1/customer/services/qr_code_list_by_id`
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ eqc_id: id || eqc_id}),
            }
            await fetchData(apiUrl, options)
            localStorage.setItem("tab", activeTab)
          }
        }
  
        // Fetch by eqrc_id
        if (eqc_id || id) {
          const apiUrl = `${baseUrl}api/v1/customer/services/get_qr_customer_info_by_id`
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ eqc_id: id || eqc_id }),
          }
          await fetchData(apiUrl, options)
        }
  
        if (eqc_id || id) {
          const apiUrl = `${baseUrl}api/v1/customer/services/get_qr_approval_By_id`
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ eqc_id: id || eqc_id }),
          }
          await fetchData(apiUrl, options)
        }
  
        if (eqc_id || id) {
          console.log(eqc_id, id)
          const apiUrl = `${baseUrl}api/v1/customer/services/get_qr_approval`
          const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
          await fetchData(apiUrl, options)
        }
      }
  
      fetchTabData()
    }, [activeTab, id, eqrc_id, eqrd_id])
  
    useEffect(() => {
      const fetchQRApprovalData = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_qr_approval_By_id`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ eqc_id: id || eqc_id }),
            },
          )
  
          const data = await response.json()
          if (response.ok && data.length > 0) {
            const qrData = data[0]
            setForm({
              eqra_status: qrData.eqra_status,
              eqra_req_date: qrData.eqra_req_date,
              eqra_req_by: qrData.eqra_req_by,
              eqra_notes: qrData.eqra_notes,
              admin_status: qrData.admin_status,
              admin_date: qrData.admin_date,
              admin_apriby: qrData.admin_apriby,
              admin_note: qrData.admin_note,
            })
          } else {
          }
        } catch (error) {}
      }
      fetchQRApprovalData()
    }, [])
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}
  
      switch (action) {
        case "requestorDetails":
          setForm({
            ...form,
            [name]: type == "checkbox" ? checked : value,
            errors: { ...form.errors, [name]: value?.trim() ? "" : "Required" },
          })
  
          break
        case "customerInformation":
          if (name === "pre_country") {
            fetchRegionsList(value)
          } else {
            setForm({
              ...form,
              [name]: value,
            })
          }
  
          break
        case "finacier":
          setForm({
            ...form,
            [name]: value,
          })
          break
        case "addListofDocuments":
          let latestNumberss = 2
          if (form.ListofDocuments.length) {
            let numberList = form.ListofDocuments.map(each => each.number)
  
            latestNumberss = Math.max(...numberList) + 1
          }
          form.ListofDocuments.push({
            number: form.ListofDocuments.length ? latestNumberss : 1,
          })
          setForm({ ...form, ListofDocuments: form.ListofDocuments })
          break
        case "removeListofDocuments":
          const modifiedListss = form.ListofDocuments.filter(
            (each, idx) => each.pdl_id !== key,
          )
          setForm({ ...form, ListofDocuments: modifiedListss })
          handleRemoveDocuments(key)
          break
        case "documentList":
          setForm({
            ...form,
            ListofDocuments: form.ListofDocuments.map((each, idx) => {
              if (idx === key) {
                return {
                  ...each,
                  [name]: value,
                }
              }
  
              return each
            }),
          })
          break
        default:
          null
      }
    }
  
    const handleImage = async (e, action, key) => {
      e.preventDefault()
      const { name, files } = e?.target || {}
  
      if (!files || files.length === 0) {
        alert("No file selected. Please select a file.")
        return
      }
  
      try {
        switch (action) {
          case "documentList": {
            const result = await FileUpload(files)
            const urlsList = result.files.map(each => each.url)
  
            setForm(prevForm => ({
              ...prevForm,
              ListofDocuments: prevForm.ListofDocuments.map((each, idx) =>
                idx === key
                  ? {
                      ...each,
                      [name]: urlsList.join(","),
                    }
                  : each,
              ),
            }))
            break
          }
  
          case "images": {
            const result = await FileUpload(files)
            const urlsList = result.files.map(each => ({
              pfl_name: each.url,
            }))
  
            setForm(prevForm => ({
              ...prevForm,
              imagesList: [...prevForm.imagesList, ...urlsList],
            }))
            break
          }
  
          default:
        }
      } catch (error) {
        showMessage("Failed to upload files. Please try again.", "error")
      }
    }
  
    const handleQRForm = e => {
      const { name, value } = e.target
      setForm(prevForm => ({
        ...prevForm,
        [name]: value,
      }))
    }
  
    const handleFormSubmit = async () => {
      const userData = JSON.parse(localStorage.getItem("userData")) || ""
      let userId = userData.user.user_id
      const isValid = true
  
      if (!isValid) {
        showMessage("Please fill in the required fields", "error", true)
        return
      }
      switch (activeTab) {
        case 1:
          try {
            const url = id
              ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update-qr-code-list`
              : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add-qr-code-list`
            let proShared = []
            let haserrors = false
            if (!form.eqc_fname) {
              setErrors(prev => {
                return {
                  ...prev,
                  eqc_fname: "Please enter name of the asset",
                }
              })
            }
  
            if (haserrors) {
              return
            }
            const data = {
              eqc_id: id,
              user_id: userId,
              eqc_fname: form.eqc_fname,
              eqc_lname: form.eqc_lname,
              eqc_relation: form.eqc_relation,
              eqc_dateofrq: form.eqc_dateofrq,
              eqc_contactno: form.eqc_contactno,
              eqc_email: form.eqc_email,
            }
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
  
            setApiStatus({ ...apiStatus, inProgress: true })
  
            const response = await fetch(url, options, {})
  
            const addOrUpdateInformation = await response.json()
  
            if (addOrUpdateInformation?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(2)
              localStorage.setItem("tab", 2)
              localStorage.setItem("eqc_id", addOrUpdateInformation.eqc_id)
              showMessage(addOrUpdateInformation.message, "success")
            } else {
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
          break
        case 2:
          try {
            const url = id
              ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update-qr-customer-info`
              : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add-qr-customer-info`
  
            let hasErrors = false
  
            if (!form.eqrc_fname) {
              setErrors(prev => ({
                ...prev,
                eqrc_fname: "Please enter first name.",
              }))
              hasErrors = true
            }
  
            if (!form.eqrc_lname) {
              setErrors(prev => ({
                ...prev,
                eqrc_lname: "Please enter last name.",
              }))
              hasErrors = true
            }
  
            if (!form.eqrc_contact) {
              setErrors(prev => ({
                ...prev,
                eqrc_contact: "Please enter contact number.",
              }))
              hasErrors = true
            }
  
            if (!form.eqrc_email) {
              setErrors(prev => ({
                ...prev,
                eqrc_email: "Please enter a valid email address.",
              }))
              hasErrors = true
            }
  
            if (hasErrors) return
  
            const data = {
              eqrc_id: form.eqrc_id || null,
              eqc_id: eqc_id,
              eqrc_reasonforenable: form.eqrc_reasonforenable,
              eqrc_fname: form.eqrc_fname,
              eqrc_lname: form.eqrc_lname,
              eqrc_dob: form.eqrc_dob,
              eqrc_email: form.eqrc_email,
              eqrc_contact: form.eqrc_contact,
            }
  
            const options = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            }
  
            setApiStatus({ ...apiStatus, inProgress: true })
            const response = await fetch(url, options)
            const result = await response.json()
  
            if (result.success) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(3)
              localStorage.setItem("tab", 3)
              if (result.eqrc_id) {
                localStorage.setItem("eqrc_id", result.eqrc_id)
              }
              showMessage(result.message, "success")
            } else {
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {
            showMessage("Something went wrong. Please try again.", "error")
            setApiStatus({ ...apiStatus, inProgress: false })
          }
          break
        case 3:
          try {
            const url = id
              ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update-qr-doc-info`
              : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add-qr-doc-info`
  
            let hasErrors = false
  
            if (!form.eqc_id) {
              setErrors(prev => ({
                ...prev,
                eqc_id: "Please enter a valid eqc_id.",
              }))
              hasErrors = true
            }
  
            if (!form.ListofDocuments || form.ListofDocuments.length === 0) {
              setErrors(prev => ({
                ...prev,
                eqrd_name: "Please enter a valid document name.",
              }))
              hasErrors = true
            }
  
            form.ListofDocuments.forEach((document, index) => {
              if (!document.eqrd_file) {
                setErrors(prev => ({
                  ...prev,
                  eqrd_file: `Please upload a valid file for document ${
                    index + 1
                  }.`,
                }))
                hasErrors = true
              }
            })
  
            if (hasErrors) return
  
            // Prepare data to be sent in API request
            const data = {
              eqrd_id: form.eqrd_id || null,
              eqc_id: eqc_id,
              documentList: form.ListofDocuments.map(doc => ({
                eqrd_name: doc.eqrd_name,
                eqrd_file: doc.eqrd_file,
              })),
            }
  
            // Set API request options
            const options = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            }
  
            setApiStatus(prev => ({ ...prev, inProgress: true }))
  
            const response = await fetch(url, options)
            const result = await response.json()
  
            if (result.success) {
              setApiStatus(prev => ({ ...prev, inProgress: false }))
              showMessage(result.message, "success")
  
              if (result.eqrd_id) {
                localStorage.setItem("eqrd_id", result.eqrd_id)
              }
  
              setActiveTab(3)
              localStorage.setItem("tab", 3)
            } else {
              setApiStatus(prev => ({ ...prev, inProgress: false }))
            }
          } catch (error) {
            showMessage("Something went wrong. Please try again.", "error")
            setApiStatus(prev => ({ ...prev, inProgress: false }))
          }
          break
        case 4:
          try {
            const url =
              id || eqc_id
                ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add-qr-approval`
                : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add-qr-approval`
  
            let hasErrors = false
  
            if (!form.eqc_id) {
              setErrors(prev => ({
                ...prev,
                eqc_id: "Please enter a valid eqc_id.",
              }))
              hasErrors = true
            }
  
            if (!form.eqra_status) {
              setErrors(prev => ({
                ...prev,
                eqra_status: "Please enter a valid status.",
              }))
              hasErrors = true
            }
  
            if (!form.eqra_req_date) {
              setErrors(prev => ({
                ...prev,
                eqra_req_date: "Please upload a valid date.",
              }))
              hasErrors = true
            }
  
            if (hasErrors) return
  
            const data = {
              user_id: userData.user.user_id,
              eqra_id: form.eqra_id || null,
              eqc_id: eqc_id,
              eqra_status: form.eqra_status,
              eqra_req_date: form.eqra_req_date,
              eqra_req_by: form.eqra_req_by,
              eqra_notes: form.eqra_notes,
              admin_status: form.admin_status,
              admin_date: form.admin_date,
              admin_apriby: form.admin_apriby,
              admin_note: form.admin_note,
            }
  
            const options = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            }
  
            setApiStatus(prev => ({ ...prev, inProgress: true }))
  
            const response = await fetch(url, options)
            const result = await response.json()
  
            if (result.success) {
              setApiStatus(prev => ({ ...prev, inProgress: false }))
              showMessage(result.message, "success")
  
              if (result.eqra_id) {
                localStorage.setItem("eqra_id", result.eqra_id)
              }
  
              setActiveTab(3)
              localStorage.setItem("tab", 4)
            } else {
              setApiStatus(prev => ({ ...prev, inProgress: false }))
            }
          } catch (error) {
            showMessage("Something went wrong. Please try again.", "error")
            setApiStatus(prev => ({ ...prev, inProgress: false }))
          }
          break
  
        default:
          break
      }
  
      if (!(id || eqc_id) && activeTab != 1) {
        localStorage.setItem("eqc_id", id)
        setactiveTab(1)
        localStorage.setItem("tab", 1)
      }
    }
  
    const handleBlur = e => {
      const { name, value } = e.target
      setForm({
        ...form,
        [name]: value?.trim(),
        errors: { ...form.errors, [name]: value?.trim() ? "" : "Requied" },
      })
    }
    useEffect(() => {
      if (changed) {
        setErrors(prev => {
          const copy = JSON.parse(JSON.stringify(prev))
          delete copy[changed]
          return copy
        })
      }
    }, [changed])
  
    const handleApproval = async () => {
      form.verifyflag = 1
      try {
        const userData = JSON.parse(localStorage.getItem("userData")) || ""
        let userId = userData.user.user_id
  
        if (userData) {
          user_role =
            userData.user.user_role === 0 ? 0 : userData.user.user_role || -1
        }
  
        if (!id) {
          showMessage("Something Wrong , Please Try Again", "error")
          return
        }
  
        try {
          await axios.post(
            `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_status`,
            {
              eqc_id: id,
              status: "Approved",
            },
          )
          setAdminStatus("Approved")
          setStatus("Approved")
          showMessage("Status Approved", "success")
        } catch (error) {
          showMessage("Error updating status", "error")
        }
  
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_all_accountinfo`,
        )
        const result = await response.json()
  
        if (!result || !result.success) {
          showMessage("Failed to fetch account data", "eror")
          return
        }
  
        const accountInfoArray = result.data
  
        const data = {
          eqc_id: id,
          user_id: userId,
          eqc_fname: form.eqc_fname,
          eqc_lname: form.eqc_lname,
          eqc_relation: form.eqc_relation,
          eqc_dateofrq: form.eqc_dateofrq,
          eqc_contactno: form.eqc_contactno,
          eqc_email: form.eqc_email,
        }
  
        const matchingAccount = accountInfoArray.find(account => {
          return (
            account.ac_fname === data.eqc_fname &&
            account.ac_lname === data.eqc_lname &&
            account.ac_phone === data.eqc_contactno &&
            account.ac_email === data.eqc_email
          )
        })
  
        if (matchingAccount) {
          const matchedUserId = matchingAccount.ac_uid
          form.verifyflag = 1
          const response = await axios.get(
            `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_qrcode_by_id?user_id=${matchedUserId}`,
          )
          showMessage("Request Approved", "success")
          showMessage("Login QR code sent to Requester Mail", "success")
          const qrCodeFile = response.data.data.qr_codefile
          await sendQRCodeEmail(data.eqc_email, qrCodeFile)
  
          return matchedUserId
        } else {
          form.verifyflag = 3
          showMessage("User Not Verified", "error")
          return null
        }
      } catch (error) {
        return null
      }
    }
  
    const handleDeny = async () => {
      if ((form.verifyflag = 1)) {
        showMessage("Request Denied", "success")
        setAdminStatus("Denied")
      }
    }
  
    const handleSeekApproval = async () => {
      if ((form.verifyflag = 1)) {
        setAdminStatus("Requested")
        showMessage("Request Send TO ADMIN", "success")
      }
    }
  
    const sendQRCodeEmail = async (email, qrCodeFilePath) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/send-qr-code`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, qrCodeFilePath }),
          },
        )
  
        const result = await response.json()
  
        if (result.success) {
          showMessage(result.message, "success")
        }
  
        if (!result.success) {
        }
      } catch (error) {}
    }
  
    const handleVerify = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData")) || ""
        let userId = userData.user.user_id
        let user_role = -1
        if (userData) {
          user_role =
            userData.user.user_role === 0 ? 0 : userData.user.user_role || -1
        }
  
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_all_accountinfo`,
        )
        const result = await response.json()
  
        if (!result || !result.success) {
          return
        }
  
        const accountInfoArray = result.data
  
        const data = {
          eqc_id: id,
          user_id: userId,
          eqc_fname: form.eqc_fname,
          eqc_lname: form.eqc_lname,
          eqc_relation: form.eqc_relation,
          eqc_dateofrq: form.eqc_dateofrq,
          eqc_contactno: form.eqc_contactno,
          eqc_email: form.eqc_email,
        }
        const isVerified = accountInfoArray.some(account => {
          const mismatches = []
  
          if (account.ac_fname !== data.eqc_fname) {
            mismatches.push("First Name (ac_fname, eqc_fname)")
          }
          if (account.ac_lname !== data.eqc_lname) {
            mismatches.push("Last Name (ac_lname, eqc_lname)")
          }
          if (account.ac_phone !== data.eqc_contactno) {
            mismatches.push("Phone Number (ac_phone, eqc_contactno)")
          }
          if (account.ac_email !== data.eqc_email) {
            mismatches.push("Email (ac_email, eqc_email)")
          }
  
          if (mismatches.length > 0) {
          }
          if (mismatches.length > 0) {
            setMismatchedDetails(prev => [...prev, { account, mismatches }])
          }
          return (
            account.ac_fname === data.eqc_fname &&
            account.ac_lname === data.eqc_lname &&
            account.ac_phone === data.eqc_contactno &&
            account.ac_email === data.eqc_email
          )
        })
  
        if (isVerified) {
          form.verifyflag = 1
          setVerificationStatus("verified")
          showMessage("User Verified", "success")
        } else {
          form.verifyflag = 3
          setVerificationStatus("not verified")
          showMessage("User Not Verified", "error")
        }
      } catch (error) {
        showMessage("An error occurred while verifying user", "error")
      }
    }
  
    const handleQRApprovalSubmit = async e => {
      e.preventDefault()
  
      let eqrc_status = verificationStatus
      let admin_status = adminStatus
  
      if (form.verifyflag === 1) {
        eqrc_status = verificationStatus
        admin_status = adminStatus
      }
  
      const data = {
        user_id: userData.user.user_id,
        eqc_id: parseInt(id) || eqc_id,
        eqra_status: eqrc_status || "N/A",
        eqra_req_date: form.eqc_dateofrq || "N/A",
        eqra_req_by: form.eqra_req_by || "N/A",
        eqra_notes: form.eqra_notes || "N/A",
        admin_status: admin_status || "N/A",
        admin_date: form.admin_date || "N/A",
        admin_apriby: form.admin_apriby || "N/A",
        admin_note: form.admin_note || "N/A",
      }
  
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add-qr-approval`
  
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
  
      setApiStatus({ ...apiStatus, inProgress: true })
  
      try {
        const response = await fetch(url, options)
  
        const addOrUpdateInformation = await response.json()
  
        if (addOrUpdateInformation?.success === true) {
          setApiStatus({ ...apiStatus, inProgress: false })
          localStorage.setItem("eqra_id", addOrUpdateInformation.eqra_id)
          showMessage(addOrUpdateInformation.message, "success")
        } else {
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (error) {
        showMessage("Failed to create record.", "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    }
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">ENABLE RQ CODE</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Operations</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/asset-wallet">Enable QR Code</Link>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                if (id) {
                                  setactiveTab(1)
                                } else {
                                  activeTab > 1 && setactiveTab(1)
                                }
                                //activeTab > 1 && setactiveTab(1)
                                localStorage.setItem("tab", 1)
                              }}
                            >
                              <span className="number">1.</span> Requestor Details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                if (id) {
                                  setactiveTab(2)
                                } else {
                                  activeTab > 2 && setactiveTab(2)
                                }
  
                                localStorage.setItem("tab", 2)
                              }}
                            >
                              <span className="number">2.</span> Customer
                              Information
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                if (id) {
                                  setactiveTab(3)
                                } else {
                                  activeTab > 3 && setactiveTab(3)
                                }
  
                                localStorage.setItem("tab", 3)
                              }}
                            >
                              <span className="number">3.</span>
                              Document Information
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 4 })}
                              onClick={async () => {
                                if (id) {
                                  setactiveTab(4)
                                } else {
                                  activeTab > 4 && setactiveTab(4)
                                }
  
                                localStorage.setItem("tab", 4)
                                await fetchListOfDocuments()
                              }}
                            >
                              <span className="number">4.</span>
                              Approval Information
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          {/**4,5,6 dynamic forms and tables */}
                          <TabPane tabId={1}>
                            <Form>
                              <h4 className="page-title-box">
                                Requestor Details
                              </h4>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="eqc_fname"
                                      className="form-label"
                                    >
                                      First Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        errors.eqc_fname
                                          ? "invalid-inputfield form-control"
                                          : "eqc_fname form-control"
                                      }
                                      name="eqc_fname"
                                      id="eqc_fname_ljsd"
                                      placeholder="First Name"
                                      required
                                      onChange={e => {
                                        setChanged("eqc_fname")
                                        handleForm(e, "requestorDetails")
                                      }}
                                      value={form.eqc_fname}
                                      onBlur={handleBlur}
                                    />
                                    {errors.eqc_fname && (
                                      <div className="invalid-input">
                                        {errors.eqc_fname}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="eqc_lname"
                                      className="form-label"
                                    >
                                      Last Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="eqc_lname"
                                      id="eqc_lname"
                                      placeholder="Last Name"
                                      value={form.eqc_lname}
                                      onChange={e =>
                                        handleForm(e, "requestorDetails")
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="eqc_relation"
                                      className="form-label"
                                    >
                                      Relation{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <select
                                      className="form-select"
                                      id="eqc_relation"
                                      name="eqc_relation"
                                      required
                                      onChange={e => {
                                        setChanged("eqc_relation")
                                        handleForm(e, "requestorDetails")
                                      }}
                                      value={form.eqc_relation || ""}
                                    >
                                      <option value="" disabled>
                                        Select Relation
                                      </option>
                                      {relations && relations.length > 0 ? (
                                        relations.map(relation => (
                                          <option
                                            key={relation.url_id}
                                            value={relation.url_name}
                                          >
                                            {relation.url_name}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="" disabled>
                                          No Relations Available
                                        </option>
                                      )}
                                    </select>
                                    {errors.eqc_relation && (
                                      <div className="invalid-input">
                                        {errors.eqc_relation}
                                      </div>
                                    )}
                                  </div>
                                </div>
  
                                <div className="col-md-3">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="eqc_dateofrq"
                                      className="form-label"
                                    >
                                      Date of Request{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="eqc_dateofrq"
                                      id="eqc_dateofrq"
                                      required
                                      onChange={e => {
                                        setChanged("eqc_dateofrq")
                                        handleForm(e, "requestorDetails")
                                      }}
                                      value={form.eqc_dateofrq || ""}
                                      max={new Date().toISOString().split("T")[0]}
                                    />
                                    {errors.eqc_dateofrq && (
                                      <div className="invalid-input">
                                        {errors.eqc_dateofrq}
                                      </div>
                                    )}
                                  </div>
                                </div>
  
                                <div className="col-md-3">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip03"
                                      className="form-label"
                                    >
                                      Contact Phone number{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      name="eqc_contactno"
                                      id="validationTooltip02"
                                      placeholder="Phone Number"
                                      onChange={e =>
                                        handleForm(e, "requestorDetails")
                                      }
                                      value={form.eqc_contactno}
                                    />
                                    {errors.eqc_contactno && (
                                      <div className="invalid-input">
                                        {errors.eqc_contactno}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Contact Email ID{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      name="eqc_email"
                                      id="validationTooltip02"
                                      placeholder="Email"
                                      onChange={e =>
                                        handleForm(e, "requestorDetails")
                                      }
                                      value={form.eqc_email}
                                    />
                                    <span className="help-inline col-xs-12 col-sm-7"></span>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </TabPane>
                          <TabPane tabId={2}>
                            <Form>
                              <h4 className="page-title-box">
                                Customer Information
                              </h4>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="eqrc_reasonforenable"
                                      className="form-label"
                                    >
                                      Reason for enable{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <select
                                      className="form-select"
                                      id="eqrc_reasonforenable"
                                      name="eqrc_reasonforenable"
                                      required
                                      onChange={e => {
                                        setChanged("eqrc_reasonforenable")
                                        handleForm(e, "customerInformation")
                                      }}
                                      value={form.eqrc_reasonforenable}
                                    >
                                      <option value="" disabled>
                                        Select Reason
                                      </option>
                                      <option value="Died">Died</option>
                                      <option value="Incapacitated">
                                        Incapacitated
                                      </option>
                                    </select>
                                    {errors.eqrc_reasonforenable && (
                                      <div className="invalid-input">
                                        {errors.eqrc_reasonforenable}
                                      </div>
                                    )}
                                  </div>
                                </div>
  
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="eqrc_fname"
                                      className="form-label"
                                    >
                                      First Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="eqrc_fname"
                                      id="eqrc_fname"
                                      placeholder="First Name"
                                      onChange={e =>
                                        handleForm(e, "customerInformation")
                                      }
                                      value={form.eqrc_fname}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="eqrc_lname"
                                      className="form-label"
                                    >
                                      Last Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="eqrc_lname"
                                      id="eqrc_lname"
                                      placeholder="Last Name"
                                      onChange={e =>
                                        handleForm(e, "customerInformation")
                                      }
                                      value={form.eqrc_lname}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="eqrc_dob"
                                      className="form-label"
                                    >
                                      Date of Birth
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="eqrc_dob"
                                      id="eqrc_dob"
                                      onChange={e =>
                                        handleForm(e, "customerInformation")
                                      }
                                      value={form.eqrc_dob}
                                      max={new Date().toISOString().split("T")[0]}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="eqrc_email"
                                      className="form-label"
                                    >
                                      Email Id
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="eqrc_email"
                                      id="eqrc_email"
                                      placeholder="Email"
                                      onChange={e =>
                                        handleForm(e, "customerInformation")
                                      }
                                      value={form.eqrc_email}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="eqrc_contact"
                                      className="form-label"
                                    >
                                      Phone number
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="eqrc_contact"
                                      id="eqrc_contact"
                                      placeholder="Phone Number"
                                      onChange={e =>
                                        handleForm(e, "customerInformation")
                                      }
                                      value={form.eqrc_contact}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </TabPane>
                          <TabPane tabId={3}>
                            <Suspense
                              fallback={
                                <div className="text-center m-4">Loading...</div>
                              }
                            >
                              <h4 className="page-title-box">
                                List of Documents
                              </h4>
                              {form?.ListofDocuments?.map((each, key) => (
                                <React.Fragment key={each.pdl_id || key}>
                                  <div
                                    className="d-flex bg-secondary my-4"
                                    style={{ height: "26px" }}
                                  >
                                    <p className="px-2">
                                      {each.number || key + 1}
                                    </p>
                                  </div>
                                  <Form className="mb-4">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="mb-3 position-relative">
                                          <label
                                            htmlFor={`eqrd_name_${key}`}
                                            className="form-label"
                                          >
                                            Name of Document
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="eqrd_name"
                                            id={`eqrd_name_${key}`}
                                            placeholder="Name of Document"
                                            onChange={e =>
                                              handleForm(e, "documentList", key)
                                            }
                                            value={each.eqrd_name || ""}
                                          />
                                        </div>
                                      </div>
  
                                      <div className="col-md-3">
                                        <div className="mb-3 position-relative">
                                          <label
                                            htmlFor={`validationTooltip03_${key}`}
                                            className="form-label"
                                          >
                                            Upload Document
                                          </label>
                                          <input
                                            type="file"
                                            className="form-control"
                                            name="eqrd_file"
                                            id={`validationTooltip03_${key}`}
                                            onChange={e =>
                                              handleImage(e, "documentList", key)
                                            }
                                          />
                                        </div>
                                      </div>
  
                                      <div className="col-md-2">
                                        <div className="mb-3 position-relative">
                                          <Button
                                            color="danger"
                                            className="text-center"
                                            style={{ margin: "29px 0 0 0" }}
                                            onClick={() =>
                                              handleForm(
                                                "",
                                                "removeListofDocuments",
                                                each.pdl_id,
                                              )
                                            }
                                          >
                                            X
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </Form>
                                </React.Fragment>
                              ))}
                              <Button
                                color="primary"
                                onClick={() =>
                                  handleForm("", "addListofDocuments")
                                }
                              >
                                Add
                              </Button>
                            </Suspense>
                          </TabPane>
  
                          <TabPane tabId={4}>
                            <Form>
                              <h4 className="page-title-box">
                                Approval Information
                              </h4>
                              {
                                <div className="">
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      handleVerify()
                                    }}
                                  >
                                    Verify User
                                  </Button>
                                </div>
                              }
                              <hr></hr>
                              {form.verifyflag === 1 && (
                                <div>
                                  {/* Operator Fields */}
                                  <label className="form-label">Operator:</label>
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <label
                                          htmlFor="eqc_dateofrq"
                                          className="form-label"
                                        >
                                          Approval Date of Request
                                        </label>
                                        <input
                                          type="date"
                                          className="form-control"
                                          name="eqc_dateofrq"
                                          id="eqc_dateofrq"
                                          value={form.eqc_dateofrq}
                                          onChange={e =>
                                            handleForm(e, "customerInformation")
                                          }
                                          max={
                                            new Date().toISOString().split("T")[0]
                                          }
                                          readOnly={user_role === 1} // Admin sees Operator fields as readonly
                                        />
                                      </div>
                                    </div>
  
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <label
                                          htmlFor="eqra_req_by"
                                          className="form-label"
                                        >
                                          Approval Requested by
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="eqra_req_by"
                                          id="eqra_req_by"
                                          placeholder="Requester Name"
                                          value={form.eqra_req_by}
                                          onChange={e =>
                                            handleForm(e, "customerInformation")
                                          }
                                          readOnly={user_role === 1} // Admin sees Operator fields as readonly
                                        />
                                      </div>
                                    </div>
  
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <label
                                          htmlFor="eqra_notes"
                                          className="form-label"
                                        >
                                          Approval Request Notes
                                        </label>
                                        <textarea
                                          className="form-control"
                                          name="eqra_notes"
                                          id="eqra_notes"
                                          placeholder="Make Notes"
                                          value={form.eqra_notes}
                                          onChange={e =>
                                            handleForm(e, "customerInformation")
                                          }
                                          readOnly={user_role === 1} // Admin sees Operator fields as readonly
                                        />
                                      </div>
                                    </div>
                                  </div>
  
                                  {/* Admin Fields */}
                                  <label className="form-label">Admin:</label>
                                  {
                                    <div className="">
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          handleSeekApproval()
                                        }}
                                      >
                                        Seek Approval
                                      </Button>
                                    </div>
                                  }
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <label
                                          htmlFor="admin_date"
                                          className="form-label"
                                        >
                                          Approval Date
                                        </label>
                                        <input
                                          type="date"
                                          className="form-control"
                                          name="admin_date"
                                          id="admin_date"
                                          value={form.admin_date || ""}
                                          onChange={e =>
                                            handleForm(e, "customerInformation")
                                          }
                                          max={
                                            new Date().toISOString().split("T")[0]
                                          }
                                          readOnly={user_role === 2} // Operator sees Admin fields as readonly
                                        />
                                      </div>
                                    </div>
  
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <label
                                          htmlFor="admin_apriby"
                                          className="form-label"
                                        >
                                          Approval by
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="admin_apriby"
                                          id="admin_apriby"
                                          placeholder="Admin Name"
                                          value={form.admin_apriby}
                                          onChange={e =>
                                            handleForm(e, "customerInformation")
                                          }
                                          readOnly={user_role === 2} // Operator sees Admin fields as readonly
                                        />
                                      </div>
                                    </div>
  
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <label
                                          htmlFor="admin_note"
                                          className="form-label"
                                        >
                                          Approval Notes
                                        </label>
                                        <textarea
                                          className="form-control"
                                          name="admin_note"
                                          id="admin_note"
                                          placeholder="Admin Notes"
                                          value={form.admin_note}
                                          onChange={e =>
                                            handleForm(e, "customerInformation")
                                          }
                                          readOnly={user_role === 2} // Operator sees Admin fields as readonly
                                        />
                                      </div>
                                    </div>
                                  </div>
  
                                  <div className="text-center">
                                    <button
                                      onClick={handleQRApprovalSubmit}
                                      className="btn btn-primary"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Form>
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Button
                              type="button"
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                              onClick={() => {
                                // activeTab != handleFormSubmit()
                                toggleTab(activeTab - 1)
                              }}
                              disabled={activeTab === 1}
                            >
                              Previous
                            </Button>
                          </li>
                          <li
                            className={activeTab === 4 ? "next disabled" : "next"}
                          >
                            <Button
                              disabled={activeTab === 4}
                              type="button"
                              onClick={() => {
                                const isFormValid = handleFormSubmit()
  
                                if (isFormValid) {
                                  toggleTab(activeTab + 1)
                                } else {
                                }
                              }}
                            >
                              Next
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        {eqc_id || id ? "Save" : "Submit"}
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Link
                      to={"/enable_qr_code_list"}
                      className="btn btn-secondary waves-effect"
                      onClick={() => {
                        localStorage.removeItem("eqc_id")
                        localStorage.removeItem("tab")
                      }}
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default CustomerLevels
  