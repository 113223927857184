import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
  import React, { useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import {
    Container,
    Spinner,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
  } from "reactstrap"
  import "../../../../src//styles/errorfield.scss"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { loginUserData, userId } from "helpers/userId"
  import EmailTemplate from "./EmailTemplate"
  
  
  
  const EmailIndex = () => {
    
  
    const history = useNavigate()
  
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const type = searchParams.get("type")
  
    
 
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/single_email_temp?sd_id=${id}`,
        )
        const data = await response.json()
  
        if (data?.success) {
          const acData = data?.data[0]
  
          setForm({
            ...form,
            sd_tem_name: acData.sd_tem_name,            
          })
        }
      } catch (err) {
        history("/email_list")
      }
    }
  
    useEffect(() => {
      if (id) {
        fetchData()
      }
    }, [])
  
    // -- Fetch Document Vault list end -- //
  
    
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">Email Template</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/email_list">Operation</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/email_list">Email Template</Link>
                </li>
              </ol>
            </div>

           <div className="card">
                <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                     
                    <div class="bg-primary card">
                        <div class="card-body">
                            <div class="text-center text-white py-4">                                
                                <h1>Template 1</h1>                                
                                <a class="text-white" href="/add_email?temp=General">General</a>                                   
                            </div>
                        </div>
                    </div>

                    </div>
                </div>
                </div>
            </div>
           
          </Container>
         
        </div>
      </React.Fragment>
    )
  }
  
  export default EmailIndex
  