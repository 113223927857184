import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Container } from "reactstrap"
import "../../../../src//styles/errorfield.scss"
import "./MakeDocument.module.css"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
const initialFormDetails = {
  dv_name: "",
  dv_comment: "",
  dv_type: "",
  dv_sub_type: "",
  dv_file: "",
}

const MakeDocumentSearch = () => {
  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  const [errors, setErrors] = useState({})

  // history for navigation
  const history = useNavigate()

  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const type = searchParams.get("type")
  const [assetImage, setAssetImage] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_make_document?doc_id=${id}`
      )
      const data = await response.json()
      setAssetImage({ ...assetImage, data: data?.data || [] })
    }
    fetchData()
  }, [])

  // -- Fetch Document Vault list start -- //
  const fetchDatarep = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_document_rep_details?doc_id=${id}`
      )
      const data = await response.json()

      if (data?.success) {
        const acData = data?.data

        setForm({
          ...form,
          short_description: acData.short_description,
          doc_id: acData.doc_id,
          doc_name: acData.doc_name,
          instruction: acData.instruction,
        })
        console.log(form)
      }
    } catch (err) {
      history("/document_search_list")
    }
  }
  useEffect(() => {
    if (id) {
      fetchDatarep()
    }
  }, [])

  const rows = assetImage?.data?.map((each, index) => ({
    id: each.id || index,
    step_no: each.step_no,
    step_name: each.step_name,
    icon: each.icon,
    step_description: each.step_description,
    instruction: each.instruction,
  }))

  // -- Fetch Document Vault list end -- //

  const handleForm = e => {
    let { name, value } = e.target

    if (name === "user_country") {
      // fetchRegionsList(value)
    } else {
      setForm({ ...form, [name]: value })
    }
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">MAKE DOCUMENT</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/document_vault_list">Services</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/document_vault_list">Make Document</Link>
              </li>
            </ol>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h2>{form.doc_name}</h2>
                  <p>{form.short_description}</p>

                  <div className="col-md-2">
                    <Link
                      to={`/make_document?doc_id=${form.doc_id}`}
                      className="btn btn-primary waves-effect waves-light me-1"
                    >
                      Make Document
                    </Link>
                  </div>
                </div>

                <div className="col-md-4">
                  <img
                    id="output_image"
                    className="rounded card-img img-thumbnail border border-2 p-0 d-inline-block"
                    alt="Your Image"
                    width="150"
                    height="300"
                    src={"draftImage.png" || "default-profile.png"}
                  />
                </div>
              </div>

              <table>
                <tbody>
                  <tr>
                    <div className="createSteps">
                      <p><h5><span>{form.instruction}</span></h5></p>
                    </div>

                    {rows && rows.length > 0 ? (
                      rows.map(row => (
                        <td style={{ textAlign: "center", padding: "10px" }}>
                          <table>
                            <tr>
                              <td>
                                <img
                                  src={row.icon || "downloaddoc.png"}
                                  alt={`Icon`}
                                  className="icon"
                                  width="50"
                                  height="50"
                                />
                              </td>
                              <td className="step-container">
                                <h3 className="step-title">
                                  {row.step_no}. {row.step_name}
                                </h3>
                                <p className="step-description">
                                  {row.step_description}
                                </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <style>
            {`

          .createSteps{
              padding-top:20%;
          }

          .step-container {
            text-align: center;
            padding: 10px;
            font-family: Arial, sans-serif;
            vertical-align: top;
          }

          .instruction {
            font-size: 1.1rem; /* Slightly larger font size for readability */
            font-weight: 500;
            color: #333;
            margin-bottom: 12px; /* Adjust margin for spacing */
          }

          .step-title {
            font-size: 1rem;
            color: #000;
            margin: 5px 0;
          }

          .step-description {
            font-size: 0.9rem;
            color: #666;
            line-height: 1;
            margin-top: 8px;
            text-align: center;
            padding: 10px;
            font-family: Arial, sans-serif;
            vertical-align: top;
          }
 

        .col-md-6 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          height: 50vh;
          margin-right:10;
          margin: 10 auto;
          padding: 0;
        }

        .col-md-6 h2 {
          font-size: 28px;
          font-weight: 700;
          color: #333;
          margin-bottom: 15px;
          padding-left: 50px;
          margin-top:25%;
        }

        .col-md-6 p {
          font-size: 16px;
          line-height: 1.6;
          color: #555;
          margin: 0;
          padding-left: 50px;
        }

        .col-md-2 {
          display:flex;
          width: 100%;
          margin-top: 5%;
          margin-left:6%;
        }

        .col-md-4 {
          margin-left: 13%;
          margin-top: 80px;
        }

        .icon {
             margin-left:25%;
        }

           `}
          </style>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MakeDocumentSearch
