import React, { useEffect, useState, useRef } from "react"
import { useLocation } from "react-router-dom"
import { Button, Container } from "reactstrap"
import "./MakeDocument.module.css"
import { Link } from "react-router-dom"
import { loginUserData, userId } from "helpers/userId"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { saveAs } from "file-saver"
import TenantsInCommonAgreement from "./TenantsInCommonAgreement"

const initialFormDetails = {
  doc_name: "",
  doc_type: "",
  doc_subtype: "",
  country: "",
  region: "",
  short_description: "",
  instruction: "",
  upload_file: "",

  ListOfDocumentStep: [],
  ListOfDocumentQuestion: [],
  RegionList: [],
}

const MakeDocument = () => {
  const [questionsData, setQuestionsData] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [textResponses, setTextResponses] = useState([])
  const [dropdownResponses, setDropdownResponses] = useState([])
  const [textareaResponses, setTextareaResponses] = useState([])
  const [partnerCount, setPartnerCount] = useState(0)
  const [tempTextResponse, setTempTextResponse] = useState([])
  const [textBoxResponse, setTextBoxResponse] = useState([])
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  const [propertyAddresse, setPropertyAddresse] = useState([])
  const [partnersInfo, setPartnersInfo] = useState([])
  const [error, setError] = useState(null)
  const [mergedData, setMergedData] = useState([])
  const [filteredPartners, setFilteredPartners] = useState([])
  const [filteredPropertyAdd, setfilteredPropertyAdd] = useState([])
  const [form, setForm] = useState(initialFormDetails)
  const [textBoxFiled, setTextBoxFiled] = useState([])
  const [docform, setDocumentForm] = useState(initialFormDetails)
  const [formData, setFormData] = useState({
    tcg_address: "",
    tcg_fullname: "",
    tcg_persant: "",
    pro_address: "",
  })
  const [file, setFile] = useState(null)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const doc_id = searchParams.get("doc_id")
  const contentRef = useRef(id)
  const fileData = new FormData()

  useEffect(() => {
    const fetchQuestionsData = async () => {
      const response = await fetch(
        `${
          process.env.REACT_APP_DOMAIN_URL
        }api/v1/customer/services/document_question_list?doc_id=${doc_id || id}`
      )
      const data = await response.json()
      setQuestionsData(data?.data || [])
    }

    const fetchData = async () => {
      try {
        const response1 = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/getAllDocSearchTcgProAddresses?user_id=${userId}`
        )
        const propertyData = await response1.json()
        setPropertyAddresse(propertyData?.data || [])

        const response2 = await fetch(
          `${
            process.env.REACT_APP_DOMAIN_URL
          }api/v1/customer/services/get-all-doc-tcg?doc_id=${doc_id || id}`
        )
        const PartnerData = await response2.json()
        setPartnersInfo(PartnerData?.data || [])
      } catch (err) {
        setError(err.message)
      }
    }

    fetchQuestionsData()
    fetchData()
  }, [id])

  const fetchDocumentData = async () => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_DOMAIN_URL
        }api/v1/customer/services/get_document_rep_details?doc_id=${
          doc_id || id
        }`
      )
      const data = await response.json()

      if (data?.success) {
        const acData = data?.data
        localStorage.setItem("doc_name", acData.doc_name)
        setDocumentForm({
          ...docform,
          short_description: acData.short_description,
          doc_id: acData.doc_id || id,
          doc_name: acData.doc_name,
          instruction: acData.instruction,
        })
      }
    } catch (err) {}
  }
  useEffect(() => {
    if (id) {
      fetchDocumentData()
    }
  }, [])

  const handleInputChange = (field, value, index) => {
    setTempTextResponse(prev => {
      const updated = [...prev]
      if (!updated[index]) updated[index] = {}
      updated[index][field] = value

      localStorage.setItem("tempTextResponse", JSON.stringify(updated))

      return updated
    })

    if (field === "partnerCount") {
      setPartnerCount(value)
    }
  }

  useEffect(() => {
    if (partnersInfo && propertyAddresse) {
      const validPartnersArray = Array.isArray(partnersInfo)
        ? partnersInfo.filter(item => typeof item === "object" && item !== null)
        : Object.values(partnersInfo).filter(
            item => typeof item === "object" && item !== null
          )

      const propertyAddressesArray = Array.isArray(propertyAddresse)
        ? propertyAddresse
        : Object.values(propertyAddresse)

      // const filteredPartners = validPartnersArray.filter(partner =>
      //   propertyAddressesArray.some(
      //     pro =>
      //       pro.user_id === partner.user_id && pro.doc_id === partner.doc_id
      //   )
      // )
      // setFilteredPartners(filteredPartners)
      // setFormData({
      //   tcg_address: filteredPartners.tcg_address,
      //   tcg_fullname: filteredPartners.tcg_fullname,
      //   tcg_persant: filteredPartners.tcg_persant,
      // })
      const filteredPartners = validPartnersArray.filter(
  partner => partner.doc_id == id || doc_id
);

if (filteredPartners.length > 0) {
  const matchedPartner = filteredPartners[0]; 
  setFilteredPartners(filteredPartners);
  setFormData({
    tcg_address: matchedPartner.tcg_address,
    tcg_fullname: matchedPartner.tcg_fullname,
    tcg_persant: matchedPartner.tcg_persant,
  });
} else {
  // If no match, clear form data or set defaults
  setFilteredPartners([]);
  setFormData({
    tcg_address: '',
    tcg_fullname: '',
    tcg_persant: '',
  });
}

    }
  }, [partnersInfo, propertyAddresse])

  useEffect(() => {
    if (filteredPartners) {
      const validPartnersArray = Array.isArray(filteredPartners)
        ? filteredPartners.filter(
            item => typeof item === "object" && item !== null
          )
        : Object.values(filteredPartners).filter(
            item => typeof item === "object" && item !== null
          )

      const propertyAddressesArray = Array.isArray(propertyAddresse)
        ? propertyAddresse
        : Object.values(propertyAddresse)

      const cleanedPropertyAddressesArray =
        propertyAddressesArray.length === 2 &&
        Array.isArray(propertyAddressesArray[1])
          ? propertyAddressesArray[1]
          : propertyAddressesArray

      const filteredProperty = validPartnersArray
        .map(partnerGroup => {
          if (Array.isArray(partnerGroup)) {
            return partnerGroup.map(partner => {
              const matchingProperty = cleanedPropertyAddressesArray.find(
                pro => pro.tcg_id === partner.tcg_id
              )
              return matchingProperty
                ? {
                    pro_address: matchingProperty.pro_address,
                  }
                : null
            })
          } else {
            return []
          }
        })
        .flat()
        .filter(item => item !== null)

      setfilteredPropertyAdd(filteredProperty)

      if (filteredProperty.length > 0) {
        setFormData({
          pro_address: filteredProperty[0].pro_address,
        })
      }
    }
  }, [filteredPartners])

  useEffect(() => {
    const savedResponse = localStorage.getItem("tempTextResponse")
    if (savedResponse) {
      setTempTextResponse(JSON.parse(savedResponse))
    }
  }, [])

  const handleTextSubmit = async () => {
    const responsesToSubmit = Array.isArray(
      tempTextResponse[currentQuestionIndex]
    )
      ? tempTextResponse[currentQuestionIndex]
      : [tempTextResponse[currentQuestionIndex]]

    try {
      for (const currentResponses of tempTextResponse) {
        const url = id
          ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_doc-search-tcg`
          : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/doc-search-tcg`

        const { tcg_address, tcg_fullname, tcg_persant } = currentResponses

        const doc_id_to_use = id || doc_id
        const user_id = userId

        const payload = {
          doc_id: doc_id_to_use,
          user_id,
          tcg_address,
          tcg_fullname,
          tcg_persant,
        }

        setApiStatus(prevStatus => ({ ...prevStatus, inProgress: true }))

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })

        const get_tcg_id = await response.json()

        if (get_tcg_id?.success) {
          localStorage.setItem("tcg_id", get_tcg_id.tcg_id)
          showMessage(get_tcg_id.message, "success")
        } else {
          showMessage(get_tcg_id?.message || "Error occurred", "error", false)
        }
      }

      // Reset tempTextResponse after processing
      setTempTextResponse([])
    } catch (error) {
      console.error("Error occurred:", error)
      showMessage("An error occurred. Please try again.", "error", false)
    } finally {
      // Reset API status
      setApiStatus(prevStatus => ({ ...prevStatus, inProgress: false }))
    }
  }
  const handleTextareaSubmit = async response => {
    if (!response) {
      return
    }

    setTextareaResponses(prev => {
      const updated = [...prev]
      updated[currentQuestionIndex] = { pro_address: response }
      return updated
    })

    const pro_address = response
    const doc_id = id
    const tcg_id = localStorage.getItem("tcg_id")
    const user_id = userId

    const payload = {
      pro_address,
      doc_id,
      tcg_id,
      user_id,
    }

    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/addDocSearchTcgProAddress`

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }

      setApiStatus({ ...apiStatus, inProgress: true })
      const response = await fetch(url, options)
      const get_dpa_id = await response.json()

      if (get_dpa_id?.success === true) {
        localStorage.setItem("dpa_id", get_dpa_id.dpa_id)
        showMessage(get_dpa_id.message, "success")
      } else {
        showMessage(get_dpa_id?.message, "error", false)
      }
    } catch (error) {}
  }

  const handleTextBoxSubmit = async response => {
    if (!response) {
      return
    }
    const text_box = textBoxResponse
    const doc_id = doc_id || id
    const tcg_id = localStorage.getItem("tcg_id")
    const user_id = userId

    const payload = {
      text_box,
      doc_id,
      tcg_id,
      user_id,
    }

    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/addDocSearchTcgTextBox`

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }

      setApiStatus({ ...apiStatus, inProgress: true })
      const response = await fetch(url, options)
      const get_dtb_id = await response.json()
      if (get_dtb_id) {
        localStorage.setItem("dtb_id", get_dtb_id.dtb_id)
        showMessage(get_dtb_id.message, "success")
      } else {
        showMessage(get_dtb_id?.message, "error", false)
      }
    } catch (error) {}
  }

  // const renderInputs = question => {
  //   const { question_place_holder } = question
  //   if (question_place_holder === "text&") {
  //     return (
  //       <div>
  //         <label>Select Number of Partners</label>
  //         <select
  //           style={{
  //             margin: "7px",
  //             padding: "0.5rem",
  //             border: "1px solid #ccc",
  //             borderRadius: "4px",
  //             backgroundColor: "#fff",
  //           }}
  //           value={partnerCount}
  //           onChange={e =>
  //             handleInputChange("partnerCount", e.target.value, "")
  //           }
  //         >
  //           <option>Select</option>
  //           {[...Array(10)].map((_, i) => (
  //             <option key={i} value={i + 1}>
  //               {i + 1}
  //             </option>
  //           ))}
  //         </select>
  //         {[
  //           ...Array(
  //             parseInt(tempTextResponse?.partnerCount || partnerCount) || 0
  //           ),
  //         ].map((_, i) => (
  //           <div
  //             key={i}
  //             style={{
  //               display: "flex",
  //               flexWrap: "wrap",
  //               gap: "1rem",
  //               marginBottom: "1rem",
  //               alignItems: "center",
  //             }}
  //           >
  //             <div
  //               style={{
  //                 flex: "0 0 auto",
  //                 minWidth: "40px",
  //                 textAlign: "center",
  //               }}
  //             >
  //               <strong>{i + 1}.</strong>
  //             </div>
  //             <div style={{ flex: "1 1 20%", minWidth: "150px" }}>
  //               <label htmlFor="partnerName" className="form-label">
  //                 Enter Partner Name
  //               </label>
  //               <input
  //                 type="text"
  //                 placeholder="Enter Name"
  //                 style={{
  //                   width: "100%",
  //                   padding: "0.5rem",
  //                   border: "1px solid #ccc",
  //                   borderRadius: "4px",
  //                 }}
  //                 onChange={e =>
  //                   handleInputChange("tcg_fullname", e.target.value, i)
  //                 }
  //               />
  //             </div>
  //             <div style={{ flex: "1 1 30%", minWidth: "200px" }}>
  //               <label htmlFor="partnerAddress" className="form-label">
  //                 Enter Partner Address
  //               </label>
  //               <input
  //                 type="text"
  //                 placeholder="Enter Address"
  //                 style={{
  //                   width: "100%",
  //                   padding: "0.5rem",
  //                   border: "1px solid #ccc",
  //                   borderRadius: "4px",
  //                 }}
  //                 onChange={e =>
  //                   handleInputChange("tcg_address", e.target.value, i)
  //                 }
  //               />
  //             </div>
  //             <div style={{ flex: "1 1 20%", minWidth: "150px" }}>
  //               <label htmlFor="ownership" className="form-label">
  //                 Enter % of Ownership
  //               </label>
  //               <input
  //                 type="number"
  //                 placeholder="% of Ownership"
  //                 style={{
  //                   width: "100%",
  //                   padding: "0.5rem",
  //                   border: "1px solid #ccc",
  //                   borderRadius: "4px",
  //                 }}
  //                 onChange={e =>
  //                   handleInputChange("tcg_persant", e.target.value, i)
  //                 }
  //               />
  //             </div>
  //           </div>
  //         ))}
  //         <br></br>
  //         <div>
  //           <Button
  //             className="me-2"
  //             color="secondary"
  //             onClick={handlePrevious}
  //             disabled={currentQuestionIndex === 0}
  //           >
  //             Previous
  //           </Button>
  //           {/* <Button className="me-2" color="success" onClick={handleTextSubmit}>
  //             Save
  //           </Button> */}
  //           <Button
  //             color="primary"
  //             onClick={() => {
  //               handleTextSubmit() // Trigger the save functionality
  //               handleNext() // Trigger the next functionality
  //             }}
  //           >
  //             Next
  //           </Button>
  //         </div>
  //       </div>
  //     )
  //   } else if (question_place_holder === "text_box&") {
  //     return (
  //       <div>
  //         <input
  //           type="text"
  //           style={{
  //             width: "50%",
  //             padding: "0.5rem",
  //             border: "1px solid #ccc",
  //             borderRadius: "4px",
  //           }}
  //           placeholder="Enter details"
  //           onChange={e => setTextBoxResponse(e.target.value)}
  //         />
  //         <br></br>
  //         <div>
  //           <Button
  //             color="secondary"
  //             className="me-2"
  //             onClick={handlePrevious}
  //             disabled={currentQuestionIndex === 0}
  //           >
  //             Previous
  //           </Button>
  //           {/* <Button
  //           color="success"
  //           className="me-2"
  //           onClick={() => handleTextBoxSubmit(tempTextResponse)}
  //         >
  //           Save
  //         </Button> */}
  //           <Button
  //             color="primary"
  //             className="me-2"
  //             onClick={() => {
  //               handleTextBoxSubmit(tempTextResponse) // Trigger the save functionality
  //               handleNext() // Trigger the next functionality
  //             }}
  //           >
  //             Next
  //           </Button>
  //         </div>
  //       </div>
  //     )
  //   } else if (question_place_holder === "text_area&") {
  //     return (
  //       <div>
  //         <textarea
  //           style={{
  //             width: "50%",
  //             padding: "0.5rem",
  //             border: "1px solid #ccc",
  //             borderRadius: "4px",
  //           }}
  //           placeholder="Enter details"
  //           onChange={e => setTempTextResponse(e.target.value)}
  //         ></textarea>
  //         <br></br>
  //         <div>
  //           <Button
  //             color="secondary"
  //             className="me-2"
  //             onClick={handlePrevious}
  //             disabled={currentQuestionIndex === 0}
  //           >
  //             Previous
  //           </Button>
  //           {/* <Button
  //           color="success"
  //           className="me-2"
  //           onClick={() => handleTextareaSubmit(tempTextResponse)}
  //         >
  //           Save
  //         </Button> */}
  //           <Button
  //             color="primary"
  //             className="me-2"
  //             onClick={() => {
  //               handleTextareaSubmit(tempTextResponse)
  //               handleNext() // Trigger the save functionality
  //             }}
  //           >
  //             Next
  //           </Button>
  //         </div>
  //       </div>
  //     )
  //   }
  //   return null
  // }

  const renderInputs = question => {
    const { question_place_holder } = question
    if (question_place_holder === "text&") {
      return (
        <div>
          {doc_id ? (
            <>
              <label>Select Number of Partners</label>
              <select
                style={{
                  margin: "7px",
                  padding: "0.5rem",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                }}
                value={partnerCount}
                onChange={e =>
                  handleInputChange("partnerCount", e.target.value, "")
                }
              >
                <option>Select</option>
                {[...Array(10)].map((_, i) => (
                  <option key={i} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
              {[...Array(parseInt(partnerCount) || 0)].map((_, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "1rem",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      flex: "0 0 auto",
                      minWidth: "40px",
                      textAlign: "center",
                    }}
                  >
                    <strong>{i + 1}.</strong>
                  </div>
                  <div style={{ flex: "1 1 20%", minWidth: "150px" }}>
                    <label htmlFor="partnerName" className="form-label">
                      Enter Partner Name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      style={{
                        width: "100%",
                        padding: "0.5rem",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                      onChange={e =>
                        handleInputChange("tcg_fullname", e.target.value, i)
                      }
                    />
                  </div>
                  <div style={{ flex: "1 1 30%", minWidth: "200px" }}>
                    <label htmlFor="partnerAddress" className="form-label">
                      Enter Partner Address
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Address"
                      style={{
                        width: "100%",
                        padding: "0.5rem",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                      onChange={e =>
                        handleInputChange("tcg_address", e.target.value, i)
                      }
                    />
                  </div>
                  <div style={{ flex: "1 1 20%", minWidth: "150px" }}>
                    <label htmlFor="ownership" className="form-label">
                      Enter % of Ownership
                    </label>
                    <input
                      type="number"
                      placeholder="% of Ownership"
                      style={{
                        width: "100%",
                        padding: "0.5rem",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                      onChange={e =>
                        handleInputChange("tcg_persant", e.target.value, i)
                      }
                    />
                  </div>
                </div>
              ))}
            </>
          ) : (
            // Editable or non-editable input fields based on presence of `id` or `doc_id`
            filteredPartners.map((partner, i) => (
              <div
                key={partner.id || i}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "1rem",
                  marginBottom: "1rem",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    flex: "0 0 auto",
                    minWidth: "40px",
                    textAlign: "center",
                  }}
                >
                  <strong>{i + 1}.</strong>
                </div>
                <div style={{ flex: "1 1 20%", minWidth: "150px" }}>
                  <label htmlFor="partnerName" className="form-label">
                    Enter Partner Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    value={partner.tcg_fullname || ""}
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                    readOnly={false}
                    onChange={e =>
                      handleInputChange("tcg_fullname", e.target.value, i)
                    }
                  />
                </div>
                <div style={{ flex: "1 1 30%", minWidth: "200px" }}>
                  <label htmlFor="partnerAddress" className="form-label">
                    Enter Partner Address
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Address"
                    value={partner.tcg_address || ""}
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                    readOnly={false}
                    onChange={e =>
                      handleInputChange("tcg_address", e.target.value, i)
                    }
                  />
                </div>
                <div style={{ flex: "1 1 20%", minWidth: "150px" }}>
                  <label htmlFor="ownership" className="form-label">
                    Enter % of Ownership
                  </label>
                  <input
                    type="number"
                    placeholder="% of Ownership"
                    value={partner.tcg_persant || ""}
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                    readOnly={false}
                    onChange={e =>
                      handleInputChange("tcg_persant", e.target.value, i)
                    }
                  />
                </div>
              </div>
            ))
          )}
          <br />
          <div>
            <Button
              className="me-2"
              color="secondary"
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
            >
              Previous
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleTextSubmit() // Trigger the save functionality
                handleNext() // Trigger the next functionality
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )
    } else if (question_place_holder === "text_box&") {
      return (
        <div>
          <input
            type="text"
            style={{
              width: "50%",
              padding: "0.5rem",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
            placeholder="Enter details"
            onChange={e => setTextBoxResponse(e.target.value)}
          />
          <br></br>
          <div>
            <Button
              color="secondary"
              className="me-2"
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
            >
              Previous
            </Button>
            {/* <Button
            color="success"
            className="me-2"
            onClick={() => handleTextBoxSubmit(tempTextResponse)}
          >
            Save
          </Button> */}
            <Button
              color="primary"
              className="me-2"
              onClick={() => {
                handleTextBoxSubmit(tempTextResponse) // Trigger the save functionality
                handleNext() // Trigger the next functionality
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )
    } else if (question_place_holder === "text_area&") {
      return (
        <div>
          <textarea
            style={{
              width: "50%",
              padding: "0.5rem",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
            placeholder="Enter details"
            onChange={e => setTempTextResponse(e.target.value)}
          ></textarea>
          <br></br>
          <div>
            <Button
              color="secondary"
              className="me-2"
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
            >
              Previous
            </Button>
            {/* <Button
            color="success"
            className="me-2"
            onClick={() => handleTextareaSubmit(tempTextResponse)}
          >
            Save
          </Button> */}
            <Button
              color="primary"
              className="me-2"
              onClick={() => {
                handleTextareaSubmit(tempTextResponse)
                handleNext() // Trigger the save functionality
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )
    }
    return null
  }

  const handleNext = () => {
    if (currentQuestionIndex < questionsData.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1)
    }
  }

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1)
    }
  }

  var dpa_id = localStorage.getItem("dpa_id")
  var tcg_id = localStorage.getItem("tcg_id")
  var dtb_id = localStorage.getItem("dtb_id")
  var doc_name = localStorage.getItem("doc_name")

  // const handleSubmit = async () => {
  //   if (contentRef.current) {
  //     const content = contentRef.current.innerHTML
  //     const blob = new Blob(["\ufeff" + content], {
  //       type: "application/msword",
  //     })

  //     saveAs(blob, `${doc_name}_Document.doc`)

  //     if (userId) {
  //       try {
  //         setApiStatus({ ...apiStatus, inProgress: true })
  //         //const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_document_valut`

  //         const formData = new FormData()
  //         formData.append("user_id", userId)
  //         formData.append("dv_name", doc_name || "")
  //         formData.append("dv_comment", "")
  //         formData.append("dv_type", "")
  //         formData.append("dv_sub_type", "")
  //         formData.append("dv_file", blob, `${doc_name}_Document.doc`)
  //         formData.append("dv_date", new Date().toISOString())

  //         for (const pair of formData.entries()) {
  //         }

  //         const content = "<h1>Document Content</h1>"
  //         const blob = new Blob(["\ufeff" + content], {
  //           type: "application/msword",
  //         })
  //         formData.append("dv_file", blob, `${doc_name}_Document.doc`)
  //         // const options = {
  //         //   method: "POST",
  //         //   body: formData, // Send FormData
  //         // }

  //         const response = await fetch(
  //           `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_document_valut`,
  //           {
  //             method: "POST",
  //             body: formData,
  //           }
  //         );

  //         const addOrUpdateDocumentVault = await response.json()
  //         if (addOrUpdateDocumentVault?.success === true) {
  //           showMessage(addOrUpdateDocumentVault.message, "success")
  //         } else {
  //           showMessage(addOrUpdateDocumentVault?.message, "error", false)
  //           setApiStatus({ ...apiStatus, inProgress: false })
  //         }
  //       } catch (e) {
  //         showMessage(e, "error", false)
  //         setApiStatus({ ...apiStatus, inProgress: false })
  //       }
  //     }
  //   }
  // }

  const handleSubmit = async () => {
    if (contentRef.current) {
      const content = contentRef.current.innerHTML
      const blob = new Blob(["\ufeff" + content], {
        type: "application/msword",
      })

      saveAs(blob, `${doc_name}_Document.doc`)

      if (userId) {
        try {
          setApiStatus({ ...apiStatus, inProgress: true })

          const formData = new FormData()
          formData.append("user_id", userId.toString())
          formData.append("dv_name", doc_name || "")
          formData.append("dv_comment", "")
          formData.append("dv_type", "")
          formData.append("dv_sub_type", "")
          formData.append("dv_file", blob, `${doc_name}_Document.doc`)
          formData.append("dv_date", new Date().toISOString())

          for (const pair of formData.entries()) {
            console.log(`${pair[0]}:`, pair[1])
          }

          const response = await fetch(
            `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_make_document_valut`,
            {
              method: "POST",
              body: formData,
            }
          )
          const addOrUpdateDocumentVault = await response.json()
          if (addOrUpdateDocumentVault?.success === true) {
            showMessage(addOrUpdateDocumentVault.message, "success")
          } else {
            showMessage(addOrUpdateDocumentVault?.message, "error", false)
          }
        } catch (e) {
          console.error("Error:", e)
          showMessage(e.message || "An error occurred", "error", false)
        } finally {
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      }
    }
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">MAKE DOCUMENT</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/document_vault_list">Services</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/document_vault_list">Make Document</Link>
              </li>
            </ol>
          </div>
          <div className="card">
            <div className="card-body">
              {questionsData.length > 0 ? (
                <>
                  <div>
                    <h5>{questionsData[currentQuestionIndex]?.question}</h5>
                    <p>{questionsData[currentQuestionIndex]?.information}</p>
                    {renderInputs(questionsData[currentQuestionIndex])}
                  </div>
                </>
              ) : (
                <p>Loading questions...</p>
              )}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              {questionsData.length > 0 ? (
                <>
                  <div
                    className="navigation-buttons"
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      gap: "1rem",
                      marginTop: "1rem",
                    }}
                  >
                    {currentQuestionIndex <
                    questionsData.length - 1 ? null : dpa_id ||
                      dtb_id ||
                      tcg_id ? (
                      <div>
                        <TenantsInCommonAgreement ref={contentRef} />
                        <br></br>
                        <Button type="button" onClick={handleSubmit}>
                          Download Agreement
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : (
                <p>Loading questions...</p>
              )}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MakeDocument
