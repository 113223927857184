import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next"
import { userId } from "helpers/userId"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

export const notificationPages = {
  "Asset Request": "/notifications/assets_request",
  "Request Service": "/notifications/services_request",
  "Family Request": "/notifications/family_requests",
  "Friend Request": "/notifications/friends_requests",
  "EnableQr Request": "/notifications/qr_requests",
}

export const fetchNotificationsCount = async () => {
  try {
    const responseData = await fetch(
      `${process.env.REACT_APP_DOMAIN_URL}api/v1/notifications/get_notifications_count?user_id=${userId}`
    )

    const result = await responseData.json()

    const notificationsData = await fetch(
      `${process.env.REACT_APP_DOMAIN_URL}api/v1/notifications/get_notifications?user_id=${userId}`
    )

    const resultData = await notificationsData.json()

    return { result, resultData }
  } catch (e) {
    showMessage(e, "error", false)
    return false
  }
}

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const location = useLocation();

  const pathname = location.pathname;

  const [form, setForm] = useState({
    notificationCount: 0,
    notificationsList: [],
  })

  useEffect(() => {
    const fetchData = async () => {
      const { result, resultData } = await fetchNotificationsCount()
      if (resultData && result) {
        setForm({
          ...form,
          notificationCount: result?.data || 0,
          notificationsList: resultData?.data || [],
        })
      }
    }

    fetchData()
  }, [props, pathname])

  return (
    <React.Fragment>
      <AlertMessage />
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="mdi mdi-bell-outline"></i>
          <span className="badge bg-danger rounded-pill">
            {form.notificationCount}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">
                  {" "}
                  {props.t("Notifications")} ({form.notificationCount})
                </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
          {form.notificationsList.map((each, index) => (
              <Link
                to={notificationPages[each.notify_type]}
                className="text-reset notification-item"
                key={each.id || index}  
              >
                <div className="d-flex">
                  <div className="avatar-xs me-3">
                    <span
                      className={`avatar-title ${
                        each.notify_type === "Family Request"
                          ? "bg-success"
                          : each.notify_type === "Friend Request"
                          ? "bg-warning"
                          : each.notify_type === "Asset Request"
                          ? "bg-info"
                          : each.notify_type === "Request Service"
                          ? "bg-primary"
                          : each.notify_type === "EnableQr Request"
                          ? "bg-danger"
                          : ""
                      } rounded-circle font-size-16`}
                    >
                      {each.notify_type === "Family Request"
                        ? "FR"
                        : each.notify_type === "Friend Request"
                        ? "FR"
                        : each.notify_type === "Asset Request"
                        ? "AR"
                        : each.notify_type === "Request Service"
                        ? "RS"
                        : each.notify_type === "EnableQr Request"
                        ? "QR"
                        : ""}


                    </span>
                  </div>
                  <div className="flex-1">
                    <h6 className="mt-0 mb-1">{each.notify_type || ""}</h6>
                    
                  </div>
                </div>
              </Link>
            ))}

            
            {/* <Link to="#" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-warning rounded-circle font-size-16">
                    <i className="mdi mdi-message-text-outline"></i>
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">New Message received</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      You have {form.notificationCount} unread messages
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="#" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-info rounded-circle font-size-16">
                    F
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">Your item is shipped</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      It is a long established fact that a reader will
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="#" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    <i className="mdi mdi-cart-outline"></i>
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">Your order is placed</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      Dummy text of the printing and typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="#" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-danger rounded-circle font-size-16">
                    <i className="mdi mdi-message-text-outline"></i>
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">New Message received</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      You have {form.notificationCount} unread messages
                    </p>
                  </div>
                </div>
              </div>
            </Link> */}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/notifications"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
