import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react"
import { daysLeft } from "pages/Utility/date-helper"

//i18n
import { withTranslation } from "react-i18next"

const Dashboard = props => {
  const [menu, setMenu] = useState(false)
  const [summary, setSummary] = useState()
  const [banksummary, setBankSummary] = useState([])
  const [filteredSummary, setFilteredSummary] = useState([])

  const [currencyFilter, setCurrencyFilter] = useState("")
  const toggle = () => {
    setMenu(!menu)
  }

  const userData = JSON.parse(localStorage.getItem("userData")) || ""
  let user_role = -1
  let user_id = 0

  if (userData) {
    user_role =
      userData.user.user_role === 0 ? 0 : userData.user.user_role || -1
    user_id = userData.user.user_id
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_customer-asset-summary?user_id=${user_id}`,
      )

      const data = await response.json()
      setSummary(data.data)
      // console.log(data.data)
    }

    const fetchBankSyncData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/getBankSyncSummary?user_id=${user_id}`,
        )

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const data = await response.json()
        setBankSummary(data.data)
      } catch (error) {
        console.error("Error fetching bank sync data:", error)
        setBankSummary([])
      }
    }

    const fetchPersonalInfo = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData")).user
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/get_personal_info?user_id=${userData.user_id}`,
        )

        const data = await response.json()

        if (data?.success) {
          const personalInfo = data.data
          userData.user = personalInfo
          localStorage.setItem("userData", JSON.stringify(userData))
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchPersonalInfo()
    fetchData()
    fetchBankSyncData()
  }, [])

  // useEffect(() => {
  //   const currencySums = banksummary.reduce(
  //     (acc, item) => {
  //       if (item.bs_currency === "INR") {
  //         acc.INR += parseFloat(item.bs_amount) || 0
  //       } else if (item.bs_currency === "USD") {
  //         acc.USD += parseFloat(item.bs_amount) || 0
  //       }
  //       return acc
  //     },
  //     { INR: 0, USD: 0 },
  //   )

  //   const summaryData = [
  //     { currency: "INR", amount: currencySums.INR  },
  //     { currency: "USD", amount: currencySums.USD },
  //   ]
  //   setFilteredSummary(summaryData)
  // }, [banksummary])

  useEffect(() => {
    const groupedData = {}

    banksummary.forEach(item => {
      const { bs_currency, bs_account_type, bs_amount } = item

      const key = `${bs_currency}-${bs_account_type}`

      if (!groupedData[key]) {
        groupedData[key] = {
          currency: bs_currency,
          accountType: bs_account_type,
          totalAmount: 0,
        }
      }

      groupedData[key].totalAmount += parseFloat(bs_amount) || 0
    })
    const summaryData = Object.values(groupedData)

    setFilteredSummary(summaryData)
  }, [banksummary])

  document.title = "Dashboard | Sof - React Admin & Dashboard Template"
  let hidepay = true
  const today = new Date()
  const enddate = summary?.memberInfo?.End_Date

  if (enddate) {
    const oneDayInMs = 24 * 60 * 60 * 1000
    const diffInMs = new Date(enddate) - today
    const indays = Math.floor(diffInMs / oneDayInMs)
    if (indays < 7) {
      hidepay = false
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">Welcome</div>

          {user_role === 0 && (
            <>
              <Row>
                <div className="col-xl-3 col-md-6">
                  <div className="card" style={{ height: "240px" }}>
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-truncate font-size-14 mb-2">
                            Total Asset Value
                          </p>
                          <h4 className="mb-2"></h4>
                        </div>
                        <div className="avatar-bm">
                          <a
                            href="/asset-wallet"
                            className="btn btn-danger waves-light"
                          >
                            Details
                          </a>
                        </div>
                      </div>
                      <div className="">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Country</th>
                              <th>Amount</th>
                              <th>Currency</th>
                            </tr>
                          </thead>
                          <tbody>
                            {summary?.assets?.map((asset, index) => {
                              return (
                                <tr key={asset.id || asset.country || index}>
                                  <td>{asset.country}</td>
                                  <td>{asset.totalAmt}</td>
                                  <td>{asset.currrency}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card" style={{ height: "240px" }}>
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-truncate font-size-14 mb-2">
                            Bank Sync
                          </p>
                          <h4 className="mb-2"></h4>
                        </div>
                        <div className="avatar-bm">
                          <a
                            href="/investment_list"
                            className="btn btn-danger waves-light"
                          >
                            Details
                          </a>
                        </div>
                      </div>
                      <div className="">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Amount</th>
                              <th>Currency</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSummary.map((item, index) => (
                              <tr key={index}>
                                <td style={{ fontSize: "12px" }}>
                                  {item.accountType}
                                </td>
                                <td>{item.totalAmount.toFixed(2)}</td>
                                <td>{item.currency}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card" style={{ height: "240px" }}>
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-truncate font-size-14 mb-2">
                            Total Insurance
                          </p>
                          <h4 className="mb-2"></h4>
                        </div>
                        <div className="avatar-bm">
                          <a
                            href="/life-insurance"
                            className="btn btn-danger waves-light"
                          >
                            Details
                          </a>
                        </div>
                      </div>
                      <div className="">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Amount</th>
                              <th>Currency</th>
                            </tr>
                          </thead>
                          <tbody>
                            {summary?.policies?.map((policy, index) => {
                              return (
                                <tr
                                  key={
                                    policy.id || policy.insured_name || index
                                  }
                                >
                                  <td>{policy.insured_name}</td>
                                  <td>{policy.face_amount}</td>
                                  <td>{policy.billing_currency}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card" style={{ height: "240px" }}>
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-truncate font-size-14 mb-2">
                            Membership
                          </p>
                        </div>
                        {hidepay === false && (
                          <div className="avatar-sm">
                            <a
                              href="/membership"
                              className="btn btn-danger waves-light"
                            >
                              Pay
                            </a>
                          </div>
                        )}
                      </div>
                      <div className="">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Valid To</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{summary?.memberInfo?.usmsg}</td>
                              <td>{summary?.memberInfo?.End_Date}</td>
                            </tr>
                          </tbody>
                        </table>
                        {`Your membership expires in ${daysLeft(
                          summary?.memberInfo?.End_Date,
                        )} day${
                          daysLeft(summary?.memberInfo?.End_Date) > 1 ? "s" : ""
                        }`}
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
